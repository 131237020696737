import { MenuItem, TextField } from "@mui/material";
import { textTransform } from "@mui/system";
import React from "react";

function CustomTextField({
  value = "",
  label = "",
  style = { width: "100%", textTransform: "capitalize" },
  onChange = () => {},
  options = [],
  selectValue = "title",
  ...props
}) {
  return (
    <>
      <p style={{ marginBottom: "10px", textTransform: "capitalize" }}>
        <b>{label}</b>
      </p>
      <TextField
        size="small"
        style={style}
        select={options.length > 0 ? true : false}
        value={value === "" ? (options.length > 0 ? "0" : "") : `${value}`}
        onChange={onChange}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            style={{ textTransform: "capitalize" }}
            key={option.value}
            value={option.title}
          >
            {option[selectValue]}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

export default CustomTextField;
