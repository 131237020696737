import React from "react";
import styles from "./ThankYouPage.module.css";
import middle from '../../assets/componentAssest/ackMiddle.png'
function ThankYouPage() {
  return (
    <div>
      <div className={styles.BGimage}></div>
     
      <div className={styles.middleImg}>
      <h1>Thank You!</h1>
        <img src={middle}/>
      </div>
    </div>
  );
}

export default ThankYouPage;
