import { Button } from "@mui/material";
import React from "react";
import downloadicon from "../../assets/survery/downloadicon.svg";

function DownloadIconButton({
  size = "medium",
  onClick = () => {},
  variant = "contained",
  children = "Download",
  ...props
}) {
  return (
    <Button onClick={onClick} size={size} variant={variant}>
      <img src={downloadicon} style={{ marginRight: "10px" }} /> {children}
    </Button>
  );
}

export default DownloadIconButton;
