import React, { useContext, useEffect, useState } from "react";
import ControlledButton from "../../../../Components/ControlledButton/ControlledButton";
import add from "../../../../assets/dashboard/add.svg";
import deleteIcon from "../../../../assets/dashboard/delete.svg";
import ControlledSwitches from "../../../../Components/ControlledFields/ControlledSwitches";
import InputText from "../../../../Components/InputText/InputText";
import useInput from "../../../../hooks/useInput";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import { generateRatingScale } from "../utility";

function RatingType({ data, ...props }) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const [checked, setChecked] = useState(false);
  const placeholder = useInput(data.placeholder, "");
  const start = useInput(data.rating_scale_start, "");
  const end = useInput(data.rating_scale_end, "");
  const step = useInput(data.scale_step, "");
  const left = useInput(data.left_label, "");
  const right = useInput(data.right_label, "");
  const center = useInput(data.central_label, "");
  useEffect(() => {
    placeholder.setExternalValue(data.placeholder);
    start.setExternalValue(data.rating_scale_start);
    end.setExternalValue(data.rating_scale_end);
    step.setExternalValue(data.scale_step);
    left.setExternalValue(data.left_label);
    right.setExternalValue(data.right_label);
    center.setExternalValue(data.central_label);
    setChecked(data.is_mandatory);
  }, [data]);
  useEffect(() => {
    let quesListing = [...surveyQuestions?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      quesListing[index].placeholder = placeholder.value;
      quesListing[index].rating_scale_start = start.value;
      quesListing[index].is_mandatory = checked;
      quesListing[index].rating_scale_end = end.value;
      quesListing[index].scale_step = step.value;
      quesListing[index].left_label = left.value;
      quesListing[index].right_label = right.value;
      quesListing[index].central_label = center.value;
      const arr = generateRatingScale(start.value, end.value, step.value);
      quesListing[index].choices = arr.map((itm, idx) => {
        return {
          title: itm,
          help_text: itm,
          display: true,
          index: idx + 1,
        };
      });
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  }, [
    placeholder.value,
    start.value,
    end.value,
    step.value,
    left.value,
    right.value,
    center.value,
    checked,
  ]);
  const handleClick = (_type) => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      if (_type === "duplicate") {
        let newIndex = { ...quesListing[index] };
        quesListing.push({ ...newIndex, index: quesListing.length + 1 });
      } else {
        quesListing.splice(index, 1);
      }
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  return (
    <div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ControlledButton
            variant="outlined"
            style={{
              borderRadius: "15px",
            }}
            onClick={() => handleClick("duplicate")}
          >
            <img src={add} style={{ marginRight: "10px" }} />{" "}
            <span>
              <b>Duplicate</b>
            </span>
          </ControlledButton>
          <ControlledButton
            variant="outlined"
            style={{
              borderRadius: "15px",
            }}
            onClick={() => handleClick("delete")}
          >
            <img src={deleteIcon} style={{ marginRight: "10px" }} />{" "}
            <span style={{ color: "grey" }}>
              <b>Delete</b>
            </span>
          </ControlledButton>
        </div>
        <div>
          <p>
            <b>Label</b>
          </p>
          <InputText {...placeholder} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>
            <b>Required</b>
          </p>
          <ControlledSwitches checked={checked} setChecked={setChecked} />
        </div>{" "}
        <div>
          <p>
            <b>Scale Start</b>
          </p>
          <InputText {...start} />
        </div>
        <div>
          <p>
            <b>Scale End</b>
          </p>
          <InputText {...end} />
        </div>
        <div>
          <p>
            <b>Scale Step</b>
          </p>
          <InputText {...step} />
        </div>
        <div>
          <p>
            <b>Left Label</b>
          </p>
          <InputText {...left} />
        </div>
        <div>
          <p>
            <b>Center Label</b>
          </p>
          <InputText {...center} />
        </div>
        <div>
          <p>
            <b>Right Label</b>
          </p>
          <InputText {...right} />
        </div>
      </div>
    </div>
  );
}

export default RatingType;
