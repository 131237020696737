import React, { useEffect, useState } from "react";
import CustomTable from "../../Components/CustomTable/CustomTable";
import AddContact from "../../Components/AddContact/AddContact";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import UpdateOrganization from "../../Components/UpdateOrganization/UpdateOrganization";
import styles from "./OrgManagement.module.css";

import Header from "../../Components/Header/Header";
import AddCatlog from "../../Components/AddCatlog/AddCatlog";
import download from "../../assets/componentAssest/download.png";
import catlog from "../../assets/componentAssest/catlog.png";

import PageHeader from "../../Components/PageHeader/PageHeader";
import DrawerTable from "../../Components/DrawerTable/DrawerTable";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import dashBoard from "../../assets/componentAssest/dashBoard.png";
import { DeleteApi, getApi, PostApi } from "../../Utility/network";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import { CircularProgress } from "@mui/material";
import deleteIcon from "../../assets/componentAssest/deleteIcon.png";
function OrgManagement(props) {
  const navigate = useNavigate();
  const [createOrg, setCreateOrg] = useState(false);
  const [orgData, setOrgData] = useState(null);
  const [data, setData] = useState([]);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [update, setUpdate] = useState();
  const [selectDataId, setSelectDatatId] = useState();
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({});
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [dropDown, setDropDown] = useState({
    verify: [
      { id: 1, name: "Verified" },
      { id: 2, name: "Not Verified" },
      { id: 3, name: "All" },
    ],
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [selected, setSelected] = useState({
    selectedVerify: "All",

    startDate: "",
    endDate: "",
    selectedDays: 4,
  });
  const [apiData, setApiData] = useState();
  const handleClick = () => {
    setCreateOrg(true);
  };
  const handleClickClose = () => {
    setCreateOrg(false);
    setOrgId("");
    setUpdate();
  };

  const getContactData = async (finalFilters) => {
    // setLoading(true);

    try {
      const getResponse = await getApi(`profile/organisation/`, finalFilters);
      setPaginationData(getResponse);
      const modifyArray = getResponse?.data.map((item) => ({
        ...item,
        title: <p className={styles.head}>{item?.title}</p>,
        CreatedOn: <p>{formatDate(item?.created)}</p>,
        Createdby: (
          <p>
            {item?.created_by?.first_name} {item?.created_by?.last_name}
          </p>
        ),
      }));

      setApiData(modifyArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  // const CreateOrgnization = async () => {

  //   try {
  //     const getResponse = await getApi(`profile/organisation/`);
  //     console.log(getResponse, "contactfilter");
  //     setApiData(getResponse.data);
  //   } catch (error) {
  //     console.error("Error fetching contacts:", error);
  //   }
  // };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const popOpen = () => {
    setdeleteData(true);
  };
  const formatDateFilter = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;

      if (selected.selectedVerify === "Verified") {
        finalFilters.verified = true;
      } else if (selected.selectedVerify === "Not Verified") {
        finalFilters.verified = false;
      }

      if (selected.startDate && selected.endDate) {
        finalFilters.start_date = formatDateFilter(selected.startDate);
        finalFilters.end_date = formatDateFilter(selected.endDate);
      }

      getContactData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selected]);

  useEffect(() => {
    // getOrgData();
  }, []);
  useEffect(() => {
    if (orgData) {
      setData([
        {
          id: 1,
          name: "NO OF SURVEYS",
          value: orgData.total_surveys,
        },
        {
          id: 2,
          name: "NO OF SOLUTIONS",
          value: orgData.total_recommended_solutions,
        },
        {
          id: 3,
          name: "NO OF RESPONDENTS",
          value: orgData.total_survey_submissions,
        },
      ]);
    }
  }, [orgData]);
  const handleEdit = (data) => {
    setOrgId(data.id);
    handleClick();
    setUpdate(data);
  };
  const handleView = (id, data) => {
    navigate(`/organization-management/${id}`);
  };
  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
    // getDelete(id);
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`profile/organisation/${id}/`);
      getContactData();
    } catch (error) {
      console.error(error);
    }
  };
  const getDeletebulk = async () => {
    const ids = { organisation_ids: selectDataId };
    try {
      const getResponse = await PostApi(
        `/profile/organisation/bulk_delete/`,
        ids
      );
      // console.log(getResponse?.data?.data, "dataresponse");
      getContactData();
    } catch (error) {
      console.error(error);
    }
  };

  const getPdf = async () => {
    const ids = { org_ids: selectDataId };
    try {
      const getResponse = await PostApi(
        `dashboard/admin/generate_organization_pdf/`,
        ids
      );
      window.open(getResponse?.data?.pdf_url);
    } catch (error) {
      console.error(error);
    }
  };

  // };
  const handlepopUpclose = () => {
    setdeleteData(false);
  };
  const handleNav = (data) => {
    navigate(`/organization-management/${data?.id}`);
  };
  return (
    <div>
      {apiData?.length > 0 && loading == false ? (
        <div>
          <PageHeader
            heading={"Organization Management"}
            buttonName={"Download"}
            addInfo={"Create New Organization"}
            buttonIcon={download}
            dropDown={dropDown}
            selected={selected}
            setSelected={setSelected}
            handleClick={handleClick}
            getPdf={getPdf}
            selectDataId={selectDataId}
            buttonIcon1={deleteIcon}
            buttonName1={"Delete"}
            popOpen={popOpen}
            org={true}
          />
          <Header data={data} />
          <CustomTable
            tableHeads={tableHeads}
            tableBody={apiData}
            ThreeActions={true}
            check={true}
            headerCheck={true}
            handleEdit={handleEdit}
            handleView={handleView}
            handleDelete={handleDelete}
            setSelectDatatId={setSelectDatatId}
            setSelectedIds={setSelectedIds}
            videoData={handleNav}
            selectedIds={selectedIds}
            loading={loading}
            max_pages={max_pages}
            setPageState={setPageState}
          />
          {/* <CustomPagination max_pages={max_pages} setPageState={setPageState} /> */}
          {deletedata && (
            <ConfirmDelete
              open={deletedata}
              deleteId={deleteId}
              getDelete={getDelete}
              selectDataId={selectDataId}
              handleBulkDelete={getDeletebulk}
              handlepopUpclose={handlepopUpclose}
            />
          )}

          {/* <div style={{ marginTop: "20px" }}>
            <CustomTable
              tableHeads={tableHeads1}
              tableBody={tableBody1}
              // tableVisible={false}
              ThreeActions={true}
            />
          </div> */}
          {/* <div style={{ marginTop: "20px" }}>
            <DrawerTable
              tableHeads={tableHeads2}
              tableBodys={tableBody2}
              ThreeActions={true}
              // chartData={data}
            />
          </div> */}
        </div>
      ) : data?.length == 0 && loading === false ? (
        <EmptyPage
          heading={"Organization Management"}
          desc={`Lets start the journey, Add your first company here`}
          buttonName={"Create New Organization"}
          pageImage={dashBoard}
          handleClick={handleClick}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}
      {createOrg && (
        <UpdateOrganization
          orgId={orgId}
          handleClickClose={handleClickClose}
          open={createOrg}
          updData={update}
          getContactData={getContactData}
        />
      )}
      {/* <AddContact />
      <ConfirmDelete /> */}
    </div>
  );
}

export default OrgManagement;

const tableHeads = [
  {
    id: 1,
    label: "Organization Name",
    key: "title",
    showIcon: true,
    edit: "true",
  },
  {
    id: 2,
    label: "Location ",
    key: "city",
  },
  {
    id: 3,
    label: "Users assigned",
    key: "users_assigned_count",
  },
  {
    id: 4,
    label: "Created By",
    key: "Createdby",
  },
  {
    id: 5,
    label: "Created On ",
    key: "CreatedOn",
  },
];
const tableBody = [
  {
    id: 1,
    name: (
      <div>
        <p className={styles.head}> IC Money Investments</p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    Location: "Shiloh, Hawaii",
    user: "5",
    Createdby: "Phoenix Baker",
    CreatedOn: " August 2, 2013",
  },
  {
    id: 2,
    name: (
      <div>
        <p className={styles.head}>Ultra Ventures</p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    Location: "Syracuse, Connecticut",
    user: "5",
    Createdby: "Lana Steiner",
    CreatedOn: " August 2, 2013",
  },
  {
    id: 3,
    name: (
      <div>
        <p className={styles.head}> Bling Bling Fund</p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    Location: "Manchester, Kentucky",
    user: "5",
    Createdby: "Candice Wu",
    CreatedOn: " August 2, 2013",
  },
  {
    id: 4,
    name: (
      <div>
        <p className={styles.head}> IC Money Investments</p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    Location: "Shiloh, Hawaii",
    user: "5",
    Createdby: "Phoenix Baker",
    CreatedOn: " August 2, 2013",
  },
];

const tableHeads1 = [
  {
    id: 1,
    key: "SolutionName",
    label: "Solution Name",
    showIcon: true,
  },
  {
    id: 2,
    key: "SolutionProvider",
    label: "Solution Provider",
    showIcon: true,
  },
  {
    id: 3,
    key: "SolutionType",
    label: "Solution Type",
  },
  {
    id: 4,
    key: "StartDate",
    label: "Start Date",
  },
  {
    id: 5,
    key: "EndDate",
    label: "End Date",
  },
  {
    id: 6,
    key: "Status",
    label: "Status",
  },
];
const tableBody1 = [
  {
    id: 1,
    SolutionName: <p className={styles.head}>Wealth Creation Workshop</p>,
    SolutionProvider: "Sama",
    SolutionType: "L&D",
    StartDate: "Feb 29, 2012",
    EndDate: "Feb 29, 2012",
    Status: "Active",
  },
  {
    id: 2,
    SolutionName: <p className={styles.head}>Impostor Syndrome Workshop</p>,
    SolutionProvider: "Womoneysta",
    SolutionType: "Consulting",
    StartDate: "Feb 29, 2012",
    EndDate: "Feb 29, 2012",
    Status: "Paused",
  },
];
const tableHeads2 = [
  { id: 1, label: "Survey Name", key: "SurveyName" },
  { id: 2, label: "Total Respondents", key: "TotalRespondents" },
  { id: 3, label: "Completion Rate", key: "CompletionRate" },
  { id: 4, label: "Open Rate", key: "OpenRate" },
  { id: 4, label: "Turnover Rate", key: "TurnoverRate" },
  { id: 4, label: "Status", key: "Status" },
];

const tableBody2 = [
  {
    id: 1,
    SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
    TotalRespondents: 541,
    CompletionRate: "60%",
    OpenRate: "40%",
    TurnoverRate: "80%",
    Status: "Ongoing",
    allData: [
      {
        id: 1,
        chartName: "Scorecard",
        chartData: [
          {
            id: 1,
            color: "#F67D7D",
            value: 29,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 2,
            color: "#FBD26A",
            value: 55,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 3,
            color: "#92F9C9",
            value: 88,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 4,
            color: "#FBD26A",
            value: 55,
            name: "Recognition Score",
            size: "100px",
          },
        ],
      },
      {
        id: 2,
        chartName: "Organizational Culture & Environment",
        chartData: [
          {
            id: 1,
            color: "#F67D7D",
            value: 29,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 2,
            color: "#FBD26A",
            value: 55,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 3,
            color: "#92F9C9",
            value: 88,
            name: "Recognition Score",
            size: "100px",
          },
        ],
      },
      {
        id: 3,
        chartName: "Organizational Culture & Environment",
        chartData: [
          {
            id: 1,
            color: "#F67D7D",
            value: 29,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 2,
            color: "#FBD26A",
            value: 55,
            name: "Recognition Score",
            size: "100px",
          },
          {
            id: 3,
            color: "#92F9C9",
            value: 88,
            name: "Recognition Score",
            size: "100px",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
    TotalRespondents: 541,
    CompletionRate: "60%",
    OpenRate: "40%",
    TurnoverRate: "80%",
    Status: "Ongoing",
  },
];
