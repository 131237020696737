import React, { useEffect, useRef, useState } from "react";
import styles from "./UploadImage.module.css";
import uploadImage from "../../assets/survery/uploadImage.svg";
import { WHITE } from "../../Utility/constants";

function UploadImage({
  getImage = () => {},
  img = "",
  selectedImage,
  setSelectedImage = () => {},
  ...props
}) {
  // const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  useEffect(() => {
    setImage(img);
  }, [img]);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      getImage(event.target.files[0]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setSelectedImage(URL.createObjectURL(event.dataTransfer.files[0]));
      getImage(event.target.files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current.click();
    setImage("");
  };
  return (
    <div
      className={styles.imageUploadContainer}
      style={{
        background: WHITE,
        border: `2px dashed #ccc`,
      }}
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <div className={styles.uploadArea}>
        {!image ? (
          selectedImage ? (
            <img src={selectedImage} alt="Uploaded Preview" />
          ) : (
            <div>
              <img src={uploadImage} alt="" />
              <p>Drag & Drop an image here, or click to select one</p>
            </div>
          )
        ) : (
          <img src={img} style={{ width: "auto", height: "auto" }} />
        )}
      </div>
    </div>
  );
}

export default UploadImage;
