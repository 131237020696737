import React, { useContext, useEffect, useId, useRef, useState } from "react";
import styles from "./AddUser.module.css";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import addEmptyImg from "../../assets/componentAssest/addEmptyImg.png";
import createOrg from "../../assets/componentAssest/createOrg.png";
import deleteIcon from "../../assets/componentAssest/delete.png";
import edit from "../../assets/componentAssest/edit.png";
import SearchIcon from "@mui/icons-material/Search";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import axios from "axios";
import { baseURL, getApi, PatchApi, PostApi } from "../../Utility/network";
import yes from "../../assets/componentAssest/yes.png";
import no from "../../assets/componentAssest/no.png";
import CustomSerachDropDown from "../CustomSerachDropDown/CustomSerachDropDown";
import { assignNestedKeys } from "@mui/system/cssVars/cssVarsParser";
function AddUser({
  handleClickClose,
  open,
  getProfileDataprev = () => {},
  getImage = () => {},
  img = "",
  userId,
  handleApprove = () => {},
  handleOpenReject = () => {},

  getTableData,
}) {
  const { token } = useContext(AppContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadeImgFile, setUploadImgFile] = useState("");
  const [addError, setAddError] = useState({});
  const [error, setError] = useState(false);
  const [companyError, setCompanyError] = useState("");
  // const [companyError, setCompanyError] = useState("");

  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });
  const [data, setData] = useState();
  const [dataField, setDataField] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    designation: "",
    location: "",
    company: "",
    companyName: "",
    prevCompany: "",
    // poc: false,
  });
  console.log(dataField, "formdata");

  const [company, setCompany] = useState();
  const [dropDown, setDropDown] = useState({});
  const [locationData, setLocationData] = useState();
  const [designation, setDesignation] = useState();
  const fieldStyle = { width: "100%" };
  // const [useProfileId,setUseProfileId]=useState()
  // const useProfileId = dropDown?.profile?.id;
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
    setAddError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  console.log(typeof uploadeImgFile === "object", "uploadedImgFile");

  // const handleSwitch = (e) => {
  //   const { checked } = e.target; // 'checked' gives the boolean state
  //   setDataField({
  //     ...dataField,
  //     poc: checked, // Directly set to true/false
  //   });
  // };

  const handleValidation = async () => {
    let errors = {};
    let formIsValid = true;

    if (!dataField?.first_name) {
      formIsValid = false;
      errors["first_name"] = "First name required";
    }

    // const websiteRegex =
    //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

    // if (!dataField?.website) {
    //   formIsValid = false;
    //   errors["website"] = "Organization Website required";
    // } else if (!websiteRegex.test(dataField?.website)) {
    //   formIsValid = false;
    //   errors["website"] = "enter valid website";
    // }

    if (!dataField?.last_name) {
      formIsValid = false;
      errors["last_name"] = "Last name required";
    }

    if (!dataField?.email) {
      formIsValid = false;
      errors["email"] = "email id required";
    }

    if (!dataField?.designation) {
      formIsValid = false;
      errors["designation"] = "Designation required";
    }
    if (!dataField?.location) {
      formIsValid = false;
      errors["location"] = "Location required";
    }
    if (!dataField?.mobile) {
      formIsValid = false;
      errors["mobile"] = "mobile required";
    }
    if (!dataField?.company) {
      formIsValid = false;
      setCompanyError("Company name required");
    } else {
      setCompanyError("");
    }
    const fileType = /\.?(jpg|jpeg|png|webp)$/i;

    if (!uploadeImgFile) {
      formIsValid = false;
      errors["image"] = "User logo is required";
    } else if (
      !fileType.test(
        userId
          ? typeof uploadeImgFile === "object" && uploadeImgFile.name
            ? uploadeImgFile.name
            : uploadeImgFile
          : typeof uploadeImgFile === "object" && uploadeImgFile.name
          ? uploadeImgFile.name
          : uploadeImgFile
      )
    ) {
      formIsValid = false;
      errors["image"] = "Enter a valid image format (jpg, jpeg, png, webp)";
    } else if (uploadeImgFile.size > 2 * 1024 * 1024) {
      formIsValid = false;
      errors["image"] = "Image  max size should be 2MB";
    }
    const isDimensionValid = await validateImageDimensions(
      useId
        ? typeof uploadeImgFile === "object"
          ? uploadeImgFile
          : ""
        : uploadeImgFile,
      formIsValid,
      errors
    );
    if (!isDimensionValid) {
      formIsValid = false;
      errors["image"] = "Image must be 500px by 500px";
    }

    setAddError(errors);
    return formIsValid;
  };

  const validateImageDimensions = (file) => {
    if (!file) {
      return Promise.resolve(true); // No file, skip dimension validation
    }

    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
        img.onload = function () {
          if (!(img.width <= 500 && img.height <= 500)) {
            resolve(false); // Invalid dimensions
          } else {
            resolve(true); // Valid dimensions
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };
  const CreateData = async () => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }
    const data = {
      first_name: dataField?.first_name,
      last_name: dataField?.last_name,
      email: dataField?.email,
      username: dataField?.email,
      phone: dataField?.mobile,
      // create_poc: dataField?.poc,
      profile: {
        designation_id: dataField?.designation,
        location_id: dataField?.location,
        organisation_id: dataField?.company,
      },
    };
    try {
      const getResponse = await PostApi(`/auth/user/`, data);

      setDropDown(getResponse?.data);
      if (getResponse?.data?.profile?.id) {
        postImage(getResponse?.data?.profile?.id);
      }
      clearState();
      handleClickClose();
      getTableData();
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const getLocations = async () => {
    try {
      const getResponse = await getApi(`/profile/location/`);

      setLocationData(getResponse.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    if (data) {
      setDataField({
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        mobile: data?.phone,
        designation: data?.profile?.designation?.id,
        location: data?.profile?.location?.id,
        company: data?.profile?.organisation?.id,
        companyName: data?.profile?.organisation?.title,
        prevCompany: data?.profile?.organisation_name,
        // create_poc: data?.poc,
      });
      setUploadImgFile(data?.profile?.avatar);
    }
  }, [data]);

  const getDataEdit = async (id) => {
    try {
      const getResponse = await getApi(`auth/user/${id}`);
      setData(getResponse?.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const getDesignation = async () => {
    try {
      const getResponse = await getApi(`profile/designation/`);
      setDesignation(getResponse?.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    getLocations();
    getDesignation();
    // getDataEdit();
    // getUser();
  }, []);
  useEffect(() => {
    if (userId) {
      getDataEdit(userId);
    }
  }, [userId]);
  useEffect(() => {
    if (selectedImage) {
      getImageFile(userId);
    }
  }, [selectedImage]);
  const getImageFile = async (id) => {
    const formData = new FormData();

    formData.append("avatar", uploadeImgFile);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .patch(`${baseURL}/profile/user_profile/${id}/`, formData, axiosConfig)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const clearState = () => {
    setDataField({
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      designation: "",
      location: "",
      company: "",
      // poc: false,
    });
    setUploadImgFile("");
  };
  useEffect(() => {
    setImage(img);
  }, [img]);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      getImage(event.target.files[0]);
      setUploadImgFile(event.target.files[0]);
    }
    setAddError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };
  const handleClick = () => {
    fileInputRef.current.click();
    setImage("");
  };
  const handleValues = (e, value) => {
    console.log(value, "check");

    if (!value) {
      setError(true);
    } else {
      setError(false);

      setDataField((prevState) => ({
        ...prevState,
        company: value,
      }));
    }
  };
  const handleValuesCity = (e, value) => {
    console.log(value, "check");

    if (!value) {
      setError(true);
    } else {
      setError(false);

      setDataField((prevState) => ({
        ...prevState,
        location: value,
      }));
    }
  };

  const postImage = (id) => {
    if (!selectedImage) {
      return;
    }
    if (id) {
      const formData = new FormData();
      formData.append("avatar", uploadeImgFile);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token.access}`,
        },
      };
      axios
        .patch(`${baseURL}/profile/user_profile/${id}/`, formData, axiosConfig)
        .then((response) => {
          setImageUrl({ id: response.data.id, image: response.data.image });
          getTableData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const UplodData = async (id) => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }
    const data = {
      first_name: dataField?.first_name,
      last_name: dataField?.last_name,
      email: dataField?.email,
      username: dataField?.email,
      phone: dataField?.mobile,
      // create_poc: dataField?.poc,
      profile: {
        designation_id: dataField?.designation,
        location_id: dataField?.location,
        organisation_id: dataField?.company,
      },
    };

    try {
      const getResponse = await PatchApi(`auth/user/${id}/`, data);
      setDropDown(getResponse?.data);
      if (getResponse?.data?.profile?.id) {
        postImage(getResponse?.data?.profile?.id);
      }
      handleClickClose();
      clearState();
      getTableData();
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <Dialog
      onClose={handleClickClose}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={styles.con}>
        <div className={styles.head}>
          <h3 className={styles.heading}>
            {userId ? "Update User" : "Add New User"}
          </h3>
          <div onClick={handleClickClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.switch}>
          <div className={styles.profile}>
            <div className={styles.img} onClick={handleClick}>
              {userId ? (
                userId && selectedImage ? (
                  <img src={selectedImage} />
                ) : selectedImage == null && uploadeImgFile == null ? (
                  <img src={createOrg} />
                ) : (
                  <img src={uploadeImgFile} />
                )
              ) : uploadeImgFile ? (
                <img src={selectedImage} onClick={handleClick} />
              ) : (
                <img src={createOrg} />
              )}
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleImageChange(e, "userFile")}
              />
            </div>
            <div className={styles.profileInfo}>
              {userId && (
                <div className={styles.editIcon}>
                  <div className={styles.edit}>
                    <img
                      src={edit}
                      onClick={() => {
                        handleClick();
                      }}
                    />
                  </div>

                  {/* <div className={styles.delete}>
                    <img src={deleteIcon} />
                  </div> */}
                </div>
              )}
              <p className={styles.info}>
                Image Size should be max - 2 mb
                <br />
                Image Dimensions - 500px by 500px
                <br />
                File Type - Jpg, Png, WebP
              </p>
              {addError.image && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {addError.image}
                </p>
              )}
              {/* {(selectedImage && addError.image)&& (
              <p style={{ color: "green", fontSize: "12px" }}>
                Upload image successfully
              </p>
            )} */}
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              // flexDirection: "column",
            }}
          >
            <p className={styles.switch}>Add POC</p>
            <Switch
              {...label}
              size="small"
              value={dataField?.poc || false}
              name="poc"
              checked={dataField?.poc || false}
              onChange={(e) => handleSwitch(e)}
            />
          </div> */}
        </div>
        <div className={styles.fieldbox}>
          <div className={styles.fieldCon}>
            <p className={styles.name}>First Name*</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="first_name"
              value={dataField?.first_name}
              error={!!addError.first_name}
              helperText={addError.first_name}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Last Name*</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="last_name"
              value={dataField?.last_name}
              error={!!addError.last_name}
              helperText={addError.last_name}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Email*</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="email"
              value={dataField?.email}
              error={!!addError.email}
              helperText={addError.email}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Mobile* </p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="mobile"
              value={dataField?.mobile}
              error={!!addError.mobile}
              helperText={addError.mobile}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </div>

          <div className={styles.fieldCon}>
            <p className={styles.name}> Designation*</p>
            <Select
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="designation"
              value={dataField?.designation}
              error={!!addError.designation}
              helperText={addError.designation}
            >
              {designation?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Location*</p>
            {/* <Select
              value={dataField?.location}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="location"
              error={!!addError.location}
              helperText={addError.location}
              // error={!!addError.industry}
            >
              {locationData?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select> */}
            <CustomSerachDropDown
              handleValues={handleValuesCity}
              helperText={addError?.location}
              error={!!addError.location}
              // label="Search Block"
              apiHitPoint="/profile/location/"
              variant="filled"
              // userId={userId}
              dataField={dataField}
              FieldName={"Location"}
            />
          </div>
          {userId && (
            <div className={styles.fieldCon}>
              <p className={styles.name}>
                Organisation Name (As added by user while registering)
              </p>
              <TextField
                style={{ width: "100%", backgroundColor: "#E1E6EF" }}
                size="small"
                value={dataField?.prevCompany}
                disabled={true}
              />
            </div>
          )}
          <div className={styles.fieldCon}>
            <p className={styles.name}>
              {" "}
              {userId ? "Company" : "Select Company*"}
            </p>
            <CustomSerachDropDown
              handleValues={handleValues}
              helperText={companyError}
              error={error}
              // label="Search Block"
              apiHitPoint="/profile/organisation/"
              variant="filled"
              // userId={userId}
              dataField={dataField}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.texts}>
            <Button
              variant="contained"
              onClick={() => {
                userId ? UplodData(userId) : CreateData();
              }}
            >
              {userId ? "Update" : " Add User"}
            </Button>
            <Button variant="outlined" onClick={handleClickClose}>
              Close
            </Button>
          </div>
          {userId && (
            <div className={styles.ApproveReject}>
              <div
                className={styles.approve}
                onClick={() => handleApprove(1, userId)}
              >
                <div>
                  <img src={yes} />
                </div>
                <span>Approve</span>
              </div>
              <div
                className={styles.reject}
                onClick={() => handleOpenReject(2, userId)}
              >
                <div>
                  <img src={no} />
                </div>
                <span>Reject</span>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    </Dialog>
  );
}

export default AddUser;
