import React from "react";
import styles from "../CreateSurvey.module.css";
import TextType from "./TextType";
import ImageType from "./ImageType";
import RatingType from "./RatingType";
import MultiChoiceType from "./MultiChoiceType";
import BannerType from "./BannerType";
import ConditionalType from "./ConditionalType";
import IntroductionType from "./IntroductionType";

function SurveyBoard({ data, ...props }) {
  return (
    <React.Fragment>
      {/* {[2, 3, 7, 8].includes(data?.question_type) && <TextType data={data} />} */}
      {data?.question_type === 2 && <TextType data={data} />}
      {data?.question_type === 3 && <TextType data={data} />}
      {data?.question_type === 7 && <TextType data={data} />}
      {data?.question_type === 8 && <TextType data={data} />}
      {data?.question_type === 1 && <BannerType data={data} />}
      {data?.question_type === 10 && <ImageType data={data} />}
      {data?.question_type === 9 && <RatingType data={data} />}
      {data?.question_type === 4 && <MultiChoiceType data={data} />}
      {data?.question_type === 5 && <MultiChoiceType data={data} />}
      {data?.question_type === 6 && <MultiChoiceType data={data} />}
      {data?.question_type === 13 && <BannerType data={data} />}
      {data?.question_type === 11 && <ConditionalType data={data} />}
      {data?.question_type === 15 && <IntroductionType data={data} />}
      {data?.question_type === 14 && <MultiChoiceType data={data} />}
    </React.Fragment>
  );
}

export default SurveyBoard;
