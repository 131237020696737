import MiniDrawer from "../Components/DrawerComponent/DrawerComponent";
import Dashboard from "../Pages/Dashboard";
import UserManagement from "../Pages/UserManagement";
import OrgManagement from "../Pages/OrgManagement";
import SolutionHubManagement from "../Pages/SolutionHubManagement";
import DataManagement from "../Pages/DataManagement";
import NOTFoundPage from "../Pages/NotFoundPage";
import Onboarding from "../Onboarding/Onboarding";
import { Navigate } from "react-router-dom";
import SurveyManagement from "../Pages/SurveyManagement/SurveyManagement";
import SurveyManagementForm from "../Pages/SurveyManagement/SurveyManagementForm/SurveyManagementForm";
import RoleBasedRouter from "../AppRouter/RoleBasedRouter/RoleBasedRouter";
import SurveySummary from "../Pages/SurveySummary/SurveySummary";
import PublishSurvey from "../Pages/SurveyManagement/PublishSurvey/PublishSurvey";
import PublishSurveyHelpus from "../Components/PublishSurveyHelpus/PublishSurveyHelpus";
import OrgManagementDetails from "../Pages/OrgManagement/OrgManagementDetails/OrgManagementDetails";
import OrgManagementTable from "../Components/OrgManagementTable/OrgManagementTable";
import SoluctionDetails from "../Components/SoluctionDetails/SoluctionDetails";
// import Settings from "../Components/Settings/Settings";
import Helps from "../Components/Helps/Helps";
import Settings from "../Pages/Settings/Settings";
import ThankYouPage from "../Components/ThankYouPage/ThankYouPage";

export const routerRoutes = [
  {
    path: "/login",
    element: (
      <RoleBasedRouter>
        <Onboarding />
      </RoleBasedRouter>
    ),
  },
  {
    path: "/survey/:surveyId",
    element: <PublishSurveyHelpus />,
  },
  {
    path: "/",
    element: (
      <RoleBasedRouter>
        <MiniDrawer />
      </RoleBasedRouter>
    ),
    errorElement: <NOTFoundPage />,
    children: [
      { index: true, element: <Navigate to="/dashboard" replace /> },
      {
        path: "dashboard",
        element: (
          <RoleBasedRouter>
            <Dashboard />
          </RoleBasedRouter>
        ),
      },
      {
        path: "organization-management",
        element: (
          <RoleBasedRouter>
            <OrgManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "organization-management/:id",
        element: (
          <RoleBasedRouter>
            <OrgManagementDetails />
          </RoleBasedRouter>
        ),
      },
      {
        path: "organization-management/:id/details",
        element: (
          <RoleBasedRouter>
            <OrgManagementTable />
          </RoleBasedRouter>
        ),
      },
      {
        path: "user-management",
        element: (
          <RoleBasedRouter>
            <UserManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "solution-hub-management",
        element: (
          <RoleBasedRouter>
            <SolutionHubManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "solution-hub-management/:id",
        element: (
          <RoleBasedRouter>
            <SoluctionDetails />
          </RoleBasedRouter>
        ),
      },
      {
        path: "survey-management",
        element: (
          <RoleBasedRouter>
            <SurveyManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "data-management",
        element: (
          <RoleBasedRouter>
            <DataManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "survey-management/:surveyId",
        element: (
          <RoleBasedRouter>
            <SurveyManagementForm />
          </RoleBasedRouter>
        ),
      },
      {
        path: "publish-survey/:surveyId",
        element: (
          <RoleBasedRouter>
            <PublishSurvey />
          </RoleBasedRouter>
        ),
      },
      {
        path: "publish-summary/:surveyId",
        element: (
          <RoleBasedRouter>
            <SurveySummary />
          </RoleBasedRouter>
        ),
      },
      {
        path: "settings",
        element: (
          <RoleBasedRouter>
            <Settings />
          </RoleBasedRouter>
        ),
      },
      {
        path: "/survey/acknowledgement",
        element: <ThankYouPage />,
      },

      // {
      //   path: "help",
      //   element: (
      //     <RoleBasedRouter>
      //       <Helps />
      //     </RoleBasedRouter>
      //   ),
      // },
    ],
  },
];
