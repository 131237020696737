import React, { useState } from "react";
import CreateSurveyPop from "../../Components/CreateSurveyPop/CreateSurveyPop";
import CustomTable from "../../Components/CustomTable/CustomTable";
import styles from "./DataManagement.module.css";
import catlog from "../../assets/componentAssest/catlog.png";
import PageHeader from "../../Components/PageHeader/PageHeader";
import download from "../../assets/componentAssest/download.png";
import DrawerTable from "../../Components/DrawerTable/DrawerTable";
import PieChart from "../../Components/PieChart/PieChart";

function Index(props) {
  const [selectDataId, setSelectDatatId] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  return (
    <div>
      {/* <PageHeader
        heading={"Survey Management"}
        buttonIcon={download}
        buttonName={"Download"}
        addInfo={"Build New Survey"}
      /> */}
      {/* <CustomTable
        tableBody={tableBody}
        tableHeads={tableHeads}
        headerCheck={true}
        surveyIcons={true}
        setSelectDatatId={setSelectDatatId}
        handlepopUpclose={handlepopUpclose}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      /> */}
      {/* <DrawerTable
        tableHeads={tableHeads}
        tableBodys={tableBody}
        // chartData={data}
      /> */}
      {/* <div style={{ marginTop: "20px" }}>
        <CreateSurveyPop />
      </div> */}
      <h1> Under Construction</h1>
    </div>
  );
}

export default Index;
const tableHeads = [
  { id: 1, label: "Survey Name", key: "SurveyName" },
  { id: 2, label: "Company Name", key: "CompanyName" },
  { id: 3, label: "Created by", key: "Createdby" },
  { id: 4, label: "Status", key: "Status" },
];

const tableBody = [
  {
    id: 1,
    SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
    CompanyName: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          <img src={catlog} alt="Company Logo" />
        </p>
        <div>
          <p className={styles.head}>Burger Ltd.</p>
          <p className={styles.desc}>catalogapp.io</p>
        </div>
      </div>
    ),
    Createdby: "Olivia Rhye",
    Status: "Ongoing",
    
  
  },
  {
    id: 1,
    SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
    CompanyName: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          <img src={catlog} alt="Company Logo" />
        </p>
        <div>
          <p className={styles.head}>Burger Ltd.</p>
          <p className={styles.desc}>catalogapp.io</p>
        </div>
      </div>
    ),
    Createdby: "Olivia Rhye",
    Status: "Ongoing",
  },
];

//   {
//     id: 1,
//     chartName: "Scorecard",
//     chartData: [
//       {
//         id: 1,
//         color: "#F67D7D",
//         value: 29,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 2,
//         color: "#FBD26A",
//         value: 55,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 3,
//         color: "#92F9C9",
//         value: 88,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 4,
//         color: "#FBD26A",
//         value: 55,
//         name: "Recognition Score",
//         size: "100px",
//       },
//     ],
//   },
//   {
//     id: 2,
//     chartName: "Organizational Culture & Environment",
//     chartData: [
//       {
//         id: 1,
//         color: "#F67D7D",
//         value: 29,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 2,
//         color: "#FBD26A",
//         value: 55,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 3,
//         color: "#92F9C9",
//         value: 88,
//         name: "Recognition Score",
//         size: "100px",
//       },
//       {
//         id: 4,
//         color: "#FBD26A",
//         value: 55,
//         name: "Recognition Score",
//         size: "100px",
//       },
//     ],
//   },
// ];
