import React, { useEffect, useState } from "react";
import SurveyManage from "../../Components/SurveyManage/SurveyManage";
import ConformationPopUp from "../../Components/ConformationPopUp/ConformationPopUp";
import checkIcon from "../../assets/componentAssest/checkIcon.png";
import CustomTable from "../../Components/CustomTable/CustomTable";
import BuildSurvey from "../../Components/BuildSurvey/BuildSurvey";
import HorizontalChart from "../../Components/HorizontalChart/HorizontalChart";
import { width } from "@mui/system";
import styles from "./Dashboard.module.css";
import down from "../../assets/componentAssest/down.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import profileTable from "../../assets/componentAssest/profileTable.png";
import UpdateOrganization from "../../Components/UpdateOrganization/UpdateOrganization";
import Header from "../../Components/Header/Header";
import crossIcon from "../../assets/componentAssest/crossIcon.png";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import PageHeader from "../../Components/PageHeader/PageHeader";
import { getApi, PostApi } from "../../Utility/network";
import { useNavigate } from "react-router-dom";
import { or } from "ajv/dist/compile/codegen";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import dummy from "../../assets/componentAssest/dummy.svg";
function Dashboard(props) {
  const style = { width: "55%" };
  const style1 = { width: "43%" };
  const [data, setData] = useState([]);
  const [pageState, setPageState] = useState(1);

  const [paginationData, setPaginationData] = useState({});
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [confirm, setConfirm] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const [soluctionChart, setSoluctionChart] = useState();
  const [survey, setSurvey] = useState();
  const [orgnization, setOrgnization] = useState();
  const [user, setUser] = useState();
  const [approve, setApprove] = useState();
  const [approveId, setApproveId] = useState();
  const [rejectId, setRejectId] = useState();
  const [top, setTop] = useState();
  const [org, setOrg] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dropDown, setDropDown] = useState({
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [selected, setSelected] = useState({
    startDate: "",
    endDate: "",
    selectedDays: 4,
  });
  const [dropDown1, setDropDown1] = useState({
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [selectedDrop, setSelectedDrop] = useState({
    startDate: "",
    endDate: "",
    selectedDays: 4,
  });
  const [dropDown2, setDropDown2] = useState({
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [dropDownCompany, setDropDownCompany] = useState({
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [selectedDropCompany, setSelectedDropCompany] = useState({
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 5)), // 5 years ago
    endDate: new Date(),
    selectedDays: 4, // "All" option
  });
  const [selectedDrop1, setSelectedDrop1] = useState({
    startDate: "",
    endDate: "",
    selectedDays: 4,
  });
  const handleClose = () => {
    setConfirm(false);
  };
  const handleOpen = (key, id) => {
    setConfirm(true);
    setPopUp(key);
    setApproveId(id);
  };
  const handleOpenReject = (key, id) => {
    setRejectId(id);
    setConfirm(true);
    setPopUp(key);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const getSoluction = async () => {
    setLoading(true);

    try {
      const getResponse = await getApi(`survey/recommended_solutions/`);
      const formattedData = getResponse.data?.data?.map((item, index) => ({
        surveyName: (
          <div>
            <p className={styles.head}>{item.title}</p>{" "}
          </div>
        ),
        companyName: (
          <div>
            <p className={styles.desc}>Implemented by</p>
            <p className={styles.head}>{item?.provider?.title}</p>
          </div>
        ),
      }));
      setSoluctionChart(formattedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const getTopCards = async (filter) => {
    try {
      const getResponse = await getApi(
        `/dashboard/admin/top_level_stats/`,
        filter
      );
      setTop(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getSurvey = async () => {
    setLoading(true);
    try {
      const getResponse = await getApi(`survey/`);
      const formattedData = getResponse.data?.data?.map((item, index) => ({
        surveyName: (
          <div>
            <p className={styles.head}>{item.title}</p>{" "}
          </div>
        ),
        companyName: (
          <div>
            <p className={styles.desc}>Conducted by</p>
            <p className={styles.head}>{item.organisation?.title}</p>{" "}
          </div>
        ),
      }));
      setSurvey(formattedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getOrgnization = async (filter) => {
    setLoading(true);

    try {
      const getResponse = await getApi(`profile/organisation/`, filter);
      const formattedData = getResponse?.data?.map((item, index) => ({
        name: (
          <div>
            <p className={styles.head}>{item.title}</p>
          </div>
        ),
        date: <p> {formatDate(item?.created)}</p>,
        location: <p>{item?.city}</p>,
      }));
      setOrgnization(formattedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(orgnization, "organisation");
  const getUserList = async (filter) => {
    setLoading(true);
    try {
      const getResponse = await getApi(`dashboard/admin/user/`, filter);
      setPaginationData(getResponse?.data);
      const modifyArray = getResponse?.data?.data.map((item) => ({
        ...item,
        Company: (
          <p className={styles.imgdesc}>{item?.profile?.organisation?.title}</p>
        ),
        UserName: (
          <div className={styles.imgCon}>
            <p className={styles.img}>
              <img
                src={item?.profile?.avatar ? item?.profile?.avatar : dummy}
              />
            </p>
            <p className={styles.imgdesc}>
              {item?.first_name} {item?.last_name}
            </p>
          </div>
        ),
        Designation: <p>{item?.profile?.designation?.title}</p>,
        PhoneNumber: <p>{item?.phone}</p>,
        is_btn: <p>{item?.approval_status}</p>,
        Email: <p>{item?.email}</p>,
      }));
      setUser(modifyArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getAprrove = async (id) => {
    try {
      const getResponse = await PostApi(`dashboard/admin/user/${id}/approve/`);
      getUserList();
      // setApprove(modifyArray);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getReject = async (id) => {
    try {
      const getResponse = await PostApi(`dashboard/admin/user/${id}/reject/`);
      getUserList();
      // setApprove(modifyArray);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getTopOrgnization = async (filter) => {
    try {
      const getResponse = await getApi(
        `/dashboard/admin/top_5_organization/`,
        filter
      );
      setOrg(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSurvey();
    getSoluction();
    // getOrgnization();
    // getUserList();
  }, []);
  const handleNavigation = (url) => {
    navigate(url);
  };
  // useEffect(() => {
  const TopCarddata = [
    {
      id: 1,
      name: "No of companies",
      value: top?.num_companies,
      url: "/organization-management",
    },
    {
      id: 2,
      name: "No of surveys",
      value: top?.num_surveys,
      url: "/survey-management",
    },
    {
      id: 3,
      name: "No of respondents",
      value: top?.num_respondents,
      url: "/user-management",
    },
    {
      id: 4,
      name: "No of Solutions",
      value: top?.num_solutions,
      url: "/solution-hub-management",
    },
  ];
  // }, []);
  const chartdata = [
    { name: "Biffco Enterprises Ltd.", value: 60 },
    { name: "Acme Co.", value: 70 },
    { name: "Abstergo Ltd.", value: 50 },
    { name: "Big Kahuna Burger Ltd.", value: 15 },
    { name: "Binford Ltd.", value: 10 },
  ];
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};

      if (selected.startDate && selected.endDate) {
        finalFilters.start_date = formatDateFilter(selected.startDate);
        finalFilters.end_date = formatDateFilter(selected.endDate);
      }
      if (selectedDrop?.startDate && selectedDrop?.endDate) {
        finalFilters.start_date = formatDateFilter(selectedDrop.startDate);
        finalFilters.end_date = formatDateFilter(selectedDrop.endDate);
      }
      getTopCards(finalFilters);

    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selected]);
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters1 = {};

      if (selectedDrop?.startDate && selectedDrop?.endDate) {
        finalFilters1.start_date = formatDateFilter(selectedDrop.startDate);
        finalFilters1.end_date = formatDateFilter(selectedDrop.endDate);
      }

      getOrgnization(finalFilters1);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selectedDrop]);
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters2 = {};
      finalFilters2.page = pageState;

      if (selectedDrop1?.startDate && selectedDrop1?.endDate) {
        finalFilters2.start_date = formatDateFilter(selectedDrop1.startDate);
        finalFilters2.end_date = formatDateFilter(selectedDrop1.endDate);
      }

      getUserList(finalFilters2);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selectedDrop1, pageState]);
  useEffect(() => {
    const applyFilters = () => {
      const finalFiltersCom = {};
      finalFiltersCom.page = pageState;

      if (selectedDropCompany?.startDate && selectedDropCompany?.endDate) {
        finalFiltersCom.start_date = formatDateFilter(selectedDropCompany.startDate);
        finalFiltersCom.end_date = formatDateFilter(selectedDropCompany.endDate);
      }

      // Call the API with the initial filter when component mounts
      getTopOrgnization(finalFiltersCom);
    };

    applyFilters(); // Call API on mount

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
  }, [selectedDropCompany, pageState]);
  const formatDateFilter = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleApprove = (id) => {
    getAprrove(id);
  };
  const handleReject = (id) => {
    getReject(id);
  };
  const calculatePastDate = (days) => {
    let today = new Date();
    let pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);
    return { startDate: pastDate, endDate: today };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let today = new Date();
    let pastDate;

    if (name === "selectedDays") {
      if (value === 1) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
      } else if (value === 2) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 30);
      } else if (value === 3) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 90);
      } else if (value === 4) {
        pastDate = null; // For 'All', don't set any date range
      }
    }

    setSelectedDrop({
      ...selectedDrop,
      [name]: value,
      startDate: pastDate || selectedDrop.startDate,
      endDate: value === 4 ? null : today,
    });
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    let today = new Date();
    let pastDate;

    if (name === "selectedDays") {
      if (value === 1) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
      } else if (value === 2) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 30);
      } else if (value === 3) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 90);
      } else if (value === 4) {
        pastDate = null; // For 'All', don't set any date range
      }
    }

    setSelectedDrop1({
      ...selectedDrop1,
      [name]: value,
      startDate: pastDate || selectedDrop1.startDate,
      endDate: value === 4 ? null : today,
    });
  };
  const handleChangeCompany = (e) => {
    const { name, value } = e.target;

    let today = new Date();
    let pastDate;

    if (name === "selectedDays") {
      if (value == 1) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
      } else if (value == 2) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 30); // Last 30 Days
      } else if (value == 3) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 90);
      } else if (value == 4) {
        pastDate = new Date(today);
        pastDate.setFullYear(today.getFullYear() - 5); // Last 5 Years
      }
    }

    setSelectedDropCompany({
      ...selectedDropCompany,
      [name]: value,
      startDate: pastDate || selectedDropCompany.startDate,
      endDate: today,
    });
  
  };
  return (
    <div>
      <PageHeader
        heading={"Dashboard"}
        dropDown={dropDown}
        selected={selected}
        setSelected={setSelected}
        // handleClick={handleClick}
      />
      {confirm && popUp === 1 && (
        <ConformationPopUp
          confirmText={"Are you sure you want to Approve the user?"}
          bgColor={"#12B76A"}
          image={checkIcon}
          handleClose={handleClose}
          open={confirm}
          handleApprove={handleApprove}
          approveId={approveId}
        />
      )}
      {confirm && popUp === 2 && (
        <ConformationPopUp
          confirmText={"Are you sure you want to Reject the user?"}
          bgColor={"#DE6625"}
          image={crossIcon}
          handleClose={handleClose}
          open={confirm}
          handleReject={handleReject}
          rejectId={rejectId}
        />
      )}
      {/* <BuildSurvey/> */}
      <Header data={TopCarddata} />
      <div className={styles.charts}>
        <div style={style}>
          <HorizontalChart
            text="Top 5 Companies"
            data={org}
            handleChange={handleChangeCompany}
            selected={selectedDropCompany}
            dropdown={dropDownCompany}
          />
        </div>
        <div style={style1}>
          <CustomTable
            tableHeads={tableHeads1}
            tableBody={survey?.slice(0, 4)}
            tableVisible={false}
            heading={SurveysHead}
            handleNavigation={handleNavigation}
            dashBoard={true}
            loading={loading}
          />
        </div>
        <div style={style}>
          <CustomTable
            tableHeads={tableHeads}
            tableBody={orgnization}
            action={true}
            tableVisible={false}
            heading={organizationsHead}
            dashBoard={true}
            dropDown={dropDown1}
            handleChange={handleChange}
            selectedDrop={selectedDrop}
            setSelectedDrop={setSelectedDrop}
          />
        </div>
        <div style={style1}>
          <CustomTable
            tableHeads={tableHeads1}
            tableBody={soluctionChart?.slice(0, 5)}
            heading={soluctionHead}
            tableVisible={false}
            handleNavigation={handleNavigation}
            dashBoard={true}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <CustomTable
          tableHeads={ApprovalHeads}
          tableBody={user}
          heading={ApprovalHead}
          ApproveReject={true}
          headerCheck={false}
          handleOpen={handleOpen}
          handleOpenReject={handleOpenReject}
          dropDown={dropDown2}
          handleChange={handleChangeInput}
          selectedDrop={selectedDrop1}
          setSelectedDrop={setSelectedDrop1}
          loading={loading}
          max_pages={max_pages}
          setPageState={setPageState}
        />
      </div>
    </div>
  );
}

export default Dashboard;
const soluctionHead = {
  headName: "Recently added solutions",
  filterName: "See All ",
  icon: ChevronRightIcon,
  url: "/solution-hub-management",
};
const SurveysHead = {
  headName: "Recent Surveys",
  filterName: "See All Surveys ",
  icon: ChevronRightIcon,
  url: "/survey-management",
};
const organizationsHead = {
  headName: "Recently added organizations",
  filterName: "Last 30 Days",
  status: 1,
  icon: KeyboardArrowDownIcon,
  // secPara: "Lorem ipsum dolor sit amet, consectetur adipis.",
};

const ApprovalHead = {
  headName: "User’s Approval",
  filterName: "Last 30 Days",
  status: 1,
  icon: KeyboardArrowDownIcon,
  secPara: "Approve the user once the form is filled",
};
const tableHeads = [
  {
    id: 1,
    key: "name",
  },
  {
    id: 2,
    key: "date",
  },
  {
    id: 3,
    key: "location",
  },
];
const tableBody = [
  {
    id: 1,
    name: (
      <div>
        <p className={styles.head}> Organization name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    date: "Jan 17, 2022",
    jersey: "New Jersey",
  },
  {
    id: 2,
    name: (
      <div>
        <p className={styles.head}> Organization name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    date: "Jan 17, 2022",
    jersey: "New Jersey",
  },
  {
    id: 3,
    name: (
      <div>
        <p className={styles.head}> Organization name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    date: "Jan 17, 2022",
    jersey: "New Jersey",
  },
  {
    id: 4,
    name: (
      <div>
        <p className={styles.head}> Organization name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    date: "Jan 17, 2022",
    jersey: "New Jersey",
  },
];

const tableHeads1 = [
  {
    id: 1,
    key: "surveyName",
    width: "300px",
  },
  {
    id: 2,
    key: "companyName",
  },
];
const tableBody1 = [
  {
    id: 1,
    surveyName: (
      <div>
        <p className={styles.head}> Survey name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    companyName: (
      <div>
        <p className={styles.desc}> Conducted by</p>

        <p className={styles.head}> Company name</p>
      </div>
    ),
  },
  {
    id: 2,
    surveyName: (
      <div>
        <p className={styles.head}> Survey name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    companyName: (
      <div>
        <p className={styles.desc}> Conducted by</p>

        <p className={styles.head}> Company name</p>
      </div>
    ),
  },
  {
    id: 3,
    surveyName: (
      <div>
        <p className={styles.head}> Survey name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    companyName: (
      <div>
        <p className={styles.desc}> Conducted by</p>

        <p className={styles.head}> Company name</p>
      </div>
    ),
  },
  {
    id: 4,
    surveyName: (
      <div>
        <p className={styles.head}> Survey name 1 </p>

        <p className={styles.desc}> Lorem ipsom condrt loiek</p>
      </div>
    ),
    companyName: (
      <div>
        <p className={styles.desc}> Conducted by</p>

        <p className={styles.head}> Company name</p>
      </div>
    ),
  },
];

const chartdata1 = [
  { name: "Biffco Enterprises Ltd.", value: 60 },
  { name: "Acme Co.", value: 70 },
  { name: "Abstergo Ltd.", value: 50 },
  { name: "Big Kahuna Burger Ltd.", value: 15 },
  { name: "Binford Ltd.", value: 10 },
];

const ApprovalHeads = [
  {
    id: 1,
    key: "Company",
    label: "Company",
    showIcon: true,
  },
  {
    id: 2,
    key: "UserName",
    label: "User Name",
    showIcon: true,
  },

  {
    id: 3,
    key: "Email",
    label: "User Email",
  },
  {
    id: 4,
    key: "Designation",
    label: "Designation",
  },
  {
    id: 5,
    key: "PhoneNumber",
    label: "Phone Number",
  },
];
const ApprovalBody = [
  {
    id: 1,

    UserName: <p className={styles.imgdesc}>Catalog</p>,
    Company: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          {" "}
          <img src={profileTable} />
        </p>

        <p className={styles.imgdesc}>Savannah Nguyen</p>
      </div>
    ),
    UserEmail: "sara.cruz@example.com",
    Designation: "President",
    PhoneNumber: "(684) 555-0102",
  },
  {
    id: 2,

    UserName: <p className={styles.imgdesc}>Catalog</p>,
    Company: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          {" "}
          <img src={profileTable} />
        </p>

        <p className={styles.imgdesc}>abhay Nguyen</p>
      </div>
    ),
    UserEmail: "sara.cruz@example.com",
    Designation: "President",
    PhoneNumber: "(684) 555-0102",
  },
];
