import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DateField.module.css";
import { styled } from "@mui/material/styles";

const StyledDatePickerWrapper = styled("div")(({ theme }) => ({
  ".react-datepicker-wrapper": {
    width: "100%",
  },
  ".react-datepicker": {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  ".react-datepicker__header": {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__current-month, .react-datepicker__day-name": {
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected":
    {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.contrastText,
      color: "#fff",
    },
  ".react-datepicker__day--today": {
    fontWeight: "bold",
  },
  ".react-datepicker__day:hover": {
    backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__input-container input": {
    width: "100%",
    height: "40px",
    padding: "0 12px",
    fontSize: "16px",
    borderRadius: "4px",
    border: `1px solid rgba(0,0,0, 0.23)`,
    // border:"none",
    color: theme.palette.text.primary,
    "&:focus": {
      borderColor: theme.palette.primary.main,
      outline: "none",
    },
  },
  ".react-datepicker__input-container input:hover": {
    // border: `1px solid rgba(0,0,0, 0.83)`,
    // border:"none",
  },
  ".react-datepicker-popper": {
    zIndex: `15`,
    lineHeight: `0`,
  },
  ".react-datepicker__input-container .react-datepicker__calendar-icon": {
    position: `absolute`,
    padding: `0.7rem 0.4rem`,
    boxSizing: `content-box`,
  },
}));

const DateField = ({
  onChange,
  disabled = false,
  startDate = new Date(),
  ...props
}) => {
  return (
    <StyledDatePickerWrapper>
      <DatePicker
        className={styles.datePicker}
        // showIcon
        dateFormat="d MMMM yyyy"
        selected={startDate}
        onChange={onChange}
        {...props}
        disabled={disabled}
      />
    </StyledDatePickerWrapper>
  );
};

export default DateField;
