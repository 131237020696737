import React, { useContext, useEffect, useState } from "react";
import SearcHTextField from "../../../Components/SearchTextField/SearcHTextField";
import styles from "./CreateSurvey.module.css";
import shorttext from "../../../assets/survery/shorttext.svg";
import header from "../../../assets/survery/header.svg";
import longtext from "../../../assets/survery/longtext.svg";
import multiple from "../../../assets/survery/multiple.svg";
import drop from "../../../assets/survery/drop.svg";
import hash from "../../../assets/survery/hash.svg";
import at from "../../../assets/survery/at.svg";
import rating from "../../../assets/survery/rating.svg";
import gallery from "../../../assets/survery/gallery.svg";
import hierarchy from "../../../assets/survery/hierarchy.svg";
import calculate from "../../../assets/survery/calculate.svg";
import footer from "../../../assets/survery/footer.svg";
import CreateSurvey from "./CreateSurvey/CreateSurvey";
import { checkObject, pageIncrementor } from "./utility";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import add from "../../../assets/survery/add.svg";
import {
  GREYCOLOR,
  LIGHTBOXSHADOW,
  PRIMARYCOLOR,
  WHITE,
} from "../../../Utility/constants";
import { Button } from "@mui/material";
import { PatchApi, PostApi } from "../../../Utility/network";
const surveyId = 1;
function SurveyManagementForm(props) {
  const { surveyQuestions, setContext, contextState } = useContext(AppContext);
  const [survey, setSurvey] = useState();
  const handleChange = (_item) => {
    if (
      _item?.question_type === 1 ||
      _item?.question_type === 9 ||
      _item?.question_type === 4 ||
      _item?.question_type === 5 ||
      _item?.question_type === 6 ||
      _item?.question_type === 10 ||
      _item?.question_type === 2 ||
      _item?.question_type === 3 ||
      _item?.question_type === 7 ||
      _item?.question_type === 8 ||
      _item?.question_type === 13 ||
      _item?.question_type === 11 ||
      _item?.question_type === 15 ||
      _item?.question_type === 14
    ) {
      const questionList = [...surveyQuestions.questions];
      const data = checkObject(_item, questionList);
      questionList.push(data);
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...questionList] },
      }));
    } else {
    }
  };
  const handlePage = async () => {
    // const data = {
    //   survey_name: "",
    //   title: "",
    //   questions: [],
    // };
    try {
      //   const responseData = await PatchApi(
      //     `/survey/section/${contextState?.survey?.id}/`,
      //     data
      //   );
      // console.log(responseData, "responseData");
      const data = {
        survey_id: contextState?.survey?.id,
        title: `Page ${contextState?.allSection?.length + 1}`,
        questions: [],
        index: contextState?.allSection?.length + 1,
      };
      const responseData = await PostApi(`/survey/section/`, data);
      const { survey } = responseData.data;
      const newAllSection = contextState?.allSection;
      newAllSection.push({
        ...responseData.data,
        survey_id: survey?.id,
        survey_name: survey?.title,
      });
      setContext((prev) => ({
        ...prev,
        allSection: newAllSection,
      }));
    } catch (error) {
      console.log(error, "responseData");
    }
  };
  return (
    <div className={styles.surveyContainer}>
      <div className={styles.flexContainer}>
        <div className={styles.leftCC}>
          <div
            className={styles.leftContainer}
            style={{ background: WHITE, boxShadow: LIGHTBOXSHADOW }}
          >
            <h4 style={{ marginBottom: "5px", marginTop: "8px" }}>
              ADD FIELDS
            </h4>
            <SearcHTextField placeholder="" />
            {leftListing.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.surveyOptions}
                  style={{ background: GREYCOLOR }}
                  onClick={() => handleChange(item)}
                >
                  <div
                    className={styles.iconContainer}
                    style={{
                      background: `${PRIMARYCOLOR}9B`,
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      width: "30px",
                      marginRight: "10px",
                      justifyContent: "center",
                      borderRadius: "5px",
                    }}
                  >
                    <img alt="" src={item.icon} />
                  </div>
                  <p>
                    <b>{item.label}</b>
                  </p>
                </div>
              );
            })}
          </div>
          <Button
            style={{
              width: "95%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "10px",
            }}
            variant="contained"
            onClick={handlePage}
          >
            <div
              style={{
                background: WHITE,
                display: "flex",
                padding: "5px",
                borderRadius: "5px",
                marginLeft: "-10px",
                marginRight: "10px",
              }}
            >
              {" "}
              <img src={add} />{" "}
            </div>
            <p style={{ margin: "0px", fontWeight: "700" }}>Add New Page</p>
          </Button>
        </div>

        <CreateSurvey />
      </div>
    </div>
  );
}

export default SurveyManagementForm;

const leftListing = [
  {
    label: "Header",
    id: 1,
    icon: header,
    question_type: 1,
  },
  {
    label: "Introduction",
    id: 15,
    icon: header,
    question_type: 15,
  },
  {
    label: "Short Text",
    id: 2,
    icon: shorttext,
    question_type: 2,
  },
  {
    label: "Long Text",
    id: 3,
    icon: longtext,
    question_type: 3,
  },
  {
    label: "Multiple Choice",
    id: 4,
    icon: multiple,
    question_type: 4,
  },
  {
    label: "Drop Down",
    id: 5,
    icon: drop,
    question_type: 5,
  },
  {
    label: "Multi Select",
    id: 6,
    icon: multiple,
    question_type: 6,
  },
  {
    label: "Ordering",
    id: 14,
    icon: footer,
    question_type: 14,
  },
  {
    label: "Number",
    id: 7,
    icon: hash,
    question_type: 7,
  },
  {
    label: "Email Id",
    id: 8,
    icon: at,
    question_type: 8,
  },
  {
    label: "Rating Scale",
    id: 9,
    icon: rating,
    question_type: 9,
  },
  {
    label: "Upload Image",
    id: 10,
    icon: gallery,
    question_type: 10,
  },
  {
    label: "Conditional Logic",
    id: 11,
    icon: hierarchy,
    question_type: 11,
  },
  {
    label: "Calculated Fields",
    id: 12,
    icon: calculate,
    question_type: 0,
  },
  {
    label: "Footer",
    id: 13,
    icon: footer,
    question_type: 13,
  },
];
