import { MenuItem, Select } from "@mui/material";
import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Define your styles
const style = {
  boxShadow: "0px 5px 15px 0px #27305014",
  borderRadius: "15px",
  padding: "10px ",
};

const CurvedBar = (props) => {
  const { fill, x, y, width, height } = props;
  const radius = width / 5;

  if (height === 0 || width === 0) return null;

  return (
    <g>
      <path
        d={`M${x},${y + height} 
           H${x + width} 
           V${y + radius}
           A${radius},${radius} 0 0 0 ${x},${y + radius} 
           V${y + height} 
           Z`}
        fill={fill}
      />
    </g>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  const { value } = payload;
  const maxWidth = 150;
  const words = value.split(" ");
  const lines = [];
  let line = "";

  words.forEach((word) => {
    const testLine = line + (line ? " " : "") + word;
    const textWidth = testLine.length * 6; // Approximate text width
    if (textWidth > maxWidth) {
      lines.push(line);
      line = word;
    } else {
      line = testLine;
    }
  });

  lines.push(line);

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * 15}
          textAnchor="middle"
          fill="#666"
          fontSize={12}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

function HorizontalChart({ data, text, dropdown, selected, handleChange }) {
  // Ensure data is an array and has elements
  // if (!Array.isArray(data) || data.length === 0) return null;
const filteredData = data?.filter(item => item.response_count !== 0);
  return (
    <div style={style}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h3
        style={{
          fontSize: "24px",
          fontWeight: "600",
          margin: "0px",
          padding: "10px",
        }}
      >
        {text}
      </h3>
        <div style={{ width: "20%" }}>
          <Select
            size="small"
            style={{ width: "100%" }}
            onChange={handleChange}
            name="selectedDays"
            value={selected?.selectedDays || 4}
          >
            {dropdown?.days?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <ResponsiveContainer
       width="90%" height={300}
       style={{ margin: "auto" }}

       > 
      <ComposedChart
          layout="horizontal"
          // width={750}
          // height={Math.max(data.length * 70, 300)}
          data={filteredData}
          margin={{
            top: 20,
            right: 50,  // Increase right margin
            bottom: 20, // Increase bottom margin
            left: 50,   // Increase left margin if necessary
          }}
        >
          <CartesianGrid
            stroke="#f5f5f5"
            horizontal={false}
            vertical={false}
            strokeDasharray="1 1"
          />

          <XAxis
            type="category"
            dataKey="organisation"
            scale="point"
            tick={<CustomXAxisTick />}
            tickLine={false}
            tickMargin={20}
            axisLine={false}
            padding={20}
            fontSize={12}
            interval={"preserveStartEnd"}
          />

          {/* <YAxis
            type="number"
            display="none"
            stroke="#8E8E93"
            tickLine={false}
            tickMargin={5}
            axisLine={false}
            fontSize={12}
          /> */}
          <Tooltip
            cursor={false} // Hide cursor (line) when hovering
            contentStyle={{
              backgroundColor: "#fff",
              borderColor: "#ccc",
              borderRadius: "15px",
              top: "0px",
            }} // Customize tooltip style
          />
          <Bar
            dataKey="response_count"
            barSize={40}
            // minPointSize={10}
            fill="#4DD0E1"
            shape={<CurvedBar />}
          ></Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default HorizontalChart;
