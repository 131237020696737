import React from "react";
import styles from "./ConformationPopUp.module.css";
import cross from "../../assets/survery/cross.png";
import { Dialog, TextField } from "@mui/material";

function ConformationPopUp({
  confirmText,
  bgColor,
  image,
  open,
  handleClose,
  getDuplicate = () => {},
  id,
  handleApprove = () => {},
  approveId,
  handleReject = () => {},
  rejectId,
  setDuplicateName,
  duplicateName,
}) {
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDuplicateName({
      ...duplicateName,
      [name]: value,
    });
  };
  return (
    <>
      {" "}
      <Dialog onClose={handleClose} open={open}>
        <div className={id ? `${styles.duplicate1}` : `${styles.duplicate}`}>
          <div className={styles.cross} onClick={handleClose}>
            <img src={cross}></img>
          </div>
          <div className={styles.duplicateImg}>
            <img src={image} style={{ backgroundColor: bgColor }}></img>
          </div>
          <div className={styles.info}>
            <p>{confirmText}</p>
            {id && (
             <TextField
             size="small"
             style={{ width: "100%" }}
             value={duplicateName.survey_name}  // Use the correct property
             name="survey_name"
             onChange={handleChangeInput}
           />
            )}
          </div>
          <div className={styles.btns}>
            <button
              onClick={() => {
                if (approveId) {
                  handleApprove(approveId);
                  handleClose();
                }
                if (rejectId) {
                  handleReject(rejectId);
                  handleClose();
                }
                if (id) {
                  getDuplicate(id);
                  handleClose();
                }
              }}
            >
              Yes
            </button>
            <button onClick={handleClose}>No</button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ConformationPopUp;
