import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Collapse,
  IconButton,
  Box,
  Skeleton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./DrawerTable.module.css";
import send from "../../assets/componentAssest/send.svg";
import share from "../../assets/componentAssest/share.svg";
import trash from "../../assets/componentAssest/trash.svg";

import PieChart from "../PieChart/PieChart";
import { styled } from "@mui/system";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: " #F9FAFB",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props) {
  const {
    row,
    tableHeads,
    openRowId,
    setOpenRowId,
    onHandleView = () => {},
    handleShare = () => {},
    ThreeActions,
    getId = () => {},
    handleDelete = () => {},
    innerData,
    scoreData,
  } = props;
  const isOpen = openRowId === row?.id;

  const renderCellContent = (item, displayData, row) => {
    if (item?.key === "status") {
      // if (row?.status === "draft") {
      //   return <p className={styles.draft}>{row?.status}</p>;
      // }
      // if (row?.status === "completed") {
      //   return <p className={styles.active}>{row?.status}</p>;
      // }
      // if (row?.status === "paused") {
      //   return <p className={styles.paused}>{row?.status}</p>;
      // }
    }

    return displayData;
  };

  const handleId = (id) => {
    const newId = isOpen ? null : id; // Toggle open/close
    setOpenRowId(newId); // Set the current open row
    getId(newId);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell padding="checkbox" style={{ borderBottom: "unset" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleId(row?.id)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {tableHeads.map((item, index) => {
          const displayData = item.key ? row[item.key] : row[item.id];
          return (
            <TableCell
              key={index}
              onClick={() => item.edit && onHandleView(row)}
              style={{
                cursor: item.edit ? "pointer" : "default",
                borderBottom: "none",
              }}
            >
              <div className={styles.tableFontData}>
                {renderCellContent(item, displayData, row)}
              </div>
            </TableCell>
          );
        })}
        {ThreeActions && (
          <TableCell style={{ borderBottom: "unset" }}>
            <div className={styles.ThreeActions}>
              <div
                className={styles.actionsCon}
                onClick={() => onHandleView(row?.id)}
              >
                <img src={share} alt="share" />
              </div>
              <div
                className={styles.actionsCon}
                onClick={() => handleDelete(row?.id)}
              >
                <img src={trash} alt="edit" />
              </div>
              <div
                className={styles.actionsCon}
                onClick={() => handleShare(row?.id)}
              >
                <img src={send} alt="send" />
              </div>
            </div>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        {row?.status === "completed" && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <p className={styles.head}>Scorecard</p>

                    <div className={styles.chartOuter}>
                      {scoreData?.map((item, itemIndex) => (
                        <div className={styles.chartCon} key={itemIndex}>
                          <PieChart value={item.value} size={item.size} />
                          <p className={styles.name}>{item.name}</p>
                        </div>
                      ))}
                    </div>
                    <p className={styles.head}>
                      Organizational Culture & Environment
                    </p>

                    <div className={styles.chartOuter}>
                      {innerData?.slice(0, 4)?.map((item, itemIndex) => (
                        <div className={styles.chartCon} key={itemIndex}>
                          <PieChart value={item.value} size={item.size} />
                          <p className={styles.name}>{item.name}</p>
                        </div>
                      ))}
                    </div>
                    <p className={styles.head}>
                      Organizational Culture & Environment
                    </p>

                    <div className={styles.chartOuter}>
                      {innerData?.slice(4, 8)?.map((item, itemIndex) => (
                        <div className={styles.chartCon} key={itemIndex}>
                          <PieChart value={item.value} size={item.size} />
                          <p className={styles.name}>{item.name}</p>
                        </div>
                      ))}
                    </div>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

const DrawerTable = ({
  innerData,
  innerTable,
  scoreData,
  getId = () => {},
  tableHeads = [],
  tableBodys,
  expand = false,
  onHandleView = () => {},
  handleShare = () => {},
  handleDelete = () => {},
  ThreeActions = false,
  ...otherProps
}) => {
  const [tableData, setTableData] = useState([]);
  const [openRowId, setOpenRowId] = useState(null); // Track open row ID

  useEffect(() => {
    if (tableBodys && tableBodys.length > 0) {
      setTableData(tableBodys);
    }
  }, [tableBodys]);

  return (
    <TableContainer
      component={Paper}
      {...otherProps}
      style={{ overflow: "auto" }}
    >
      <Table aria-label="collapsible table">
        <TableHead style={{ background: "#FBFBFB" }}>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            {expand && <TableCell padding="expand"></TableCell>}
            {tableHeads.map((head, index) => (
              <TableCell
                key={index}
                style={{ minWidth: head?.minWidth }}
                className={styles.tableFontHead}
              >
                <div>{head.label}</div>
              </TableCell>
            ))}
            {ThreeActions && (
              <TableCell>
                <div className={styles.tableFontHead}>Quick Actions</div>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, rowIndex) => (
            <Row
              key={rowIndex}
              row={row}
              tableHeads={tableHeads}
              openRowId={openRowId}
              setOpenRowId={setOpenRowId}
              onHandleView={onHandleView}
              handleShare={handleShare}
              ThreeActions={ThreeActions}
              getId={getId}
              innerData={innerData}
              scoreData={scoreData}
              handleDelete={handleDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DrawerTable;
