import React from "react";
import styles from "./RecommendedPopUP.module.css";
import { Button, Dialog } from "@mui/material";
function RecommendedPopUP({ data, open, handleClose }) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={styles.con}>
        <div className={styles.video}>
          {/* <video src={data.video} controls></video> */}
          <iframe
            width="530"
            height="315"
            src={data?.video_url || data?.solution?.video_url}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        {/* <p className={styles.heading}>{data.title}</p> */}
        {/* <p className={styles.desc}>{data.description}</p> */}
        <div className={styles.btns}>
          {/* <div className={styles.left}>
            <button className={styles.add} disabled>
              Add Now
            </button>
            <button className={styles.shortlist} disabled>
              Shortlist For Later{" "}
            </button>
          </div> */}
          <div className={styles.right}>
            <Button className={styles.close} onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default RecommendedPopUP;
