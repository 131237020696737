import React, { useEffect, useState } from "react";
import styles from "./CatlogHeader.module.css";
import editCat from "../../assets/componentAssest/editCat.png";
import addPOC from "../../assets/componentAssest/addPOC.png";
import catlog from "../../assets/componentAssest/catlog.png";
import trash from "../../assets/componentAssest/trash.png";
import { getApi } from "../../Utility/network";
import dummy from "../../assets/componentAssest/dummy.svg";
function CatlogHeader({ data }) {
  //   const [contact, setContact] = useState(null); // Initialize as null to avoid undefined issues

  //   const getContactData = async () => {
  //     try {
  //       const getResponse = await getApi(`profile/organisation/3/contacts/`);
  //       console.log(getResponse, "contact");
  //       setContact(getResponse.data); // Set the entire data
  //     } catch (error) {
  //       console.error("Error fetching contacts:", error);
  //     }
  //   };
  // console.log(contact,"contactex");

  //   useEffect(() => {
  //     getContactData();
  //   }, []);
  return (
    <div>
      <div className={styles.headCon}>
        <div className={styles.imgCon}>
        <div className={styles.img1}
            style={{
              background: `url(${data?.logo ? data?.logo : dummy})`,
             
              width: "50px",
              height: "50px",
              borderRadius: "50px",
              backgroundPosition:"center"
            }}
          ></div>
          {/* <div className={styles.img1}>
            <img src={data?.logo ? data?.logo : dummy} />
          </div> */}
          <div>
            <p className={styles.head}>{data?.title}</p>
            <p className={styles.desc}>{data?.website}</p>
          </div>
        </div>
        {/* <div className={styles.buttonCon}>
          <div className={styles.iconCon}>
            <div className={styles.icons}>
              <img src={editCat} />
            </div>
            <p className={styles.iconName}>Update</p>
          </div>
          <div className={styles.iconConDeL}>
            <div className={styles.icons}>
              <img src={trash} />
            </div>
            <p className={styles.iconNameDel}>Delete</p>
          </div>
        </div> */}
      </div>

      <hr className={styles.hr} />
    </div>
  );
}

export default CatlogHeader;
