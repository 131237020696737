import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getApi } from "../../Utility/network";

function CustomSearchDropDown({
  handleValues = () => {},
  label = "label",
  variant = "outlined",
  apiHitPoint,
  userId,
  error,
  helperText,
  FieldName,
  dataField,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);

  // Fetch data on filter change
  useEffect(() => {
    const timeout = setTimeout(() => {
      const finalFilters = {};
      finalFilters.page = pageState - 1;
      finalFilters.search = filter?.search;
      getData(finalFilters);
    }, 600);
    return () => clearTimeout(timeout);
  }, [filter]);

  // API call function to fetch data
  const getData = async (filters) => {
    setLoading(true);
    try {
      const response = await getApi(`${apiHitPoint}`, filters);
      setData(response.data); // API data set here
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Update options when data changes
  useEffect(() => {
    if (!loading) {
      setOptions([...data]);
    }
  }, [loading, data]);

  // Reset options when Autocomplete is closed
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // Handle input change for search filter
  const handleInputChange = (event, value) => {
    if (value === "") {
      setFilter({});
    } else {
      setFilter({ ...filter, search: value });
    }
  };

  // Find the initial value in the options based on the dataField.company.id
  const initialValue =
    data.find((option) => option.id === dataField?.company) || null;
  console.log(error, helperText, "helperText");
  const initialValueLocation =
    data.find((option) => option.id === dataField?.location) || null;
  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        disabled={userId ? true : false}
        value={FieldName == "Location" ? initialValueLocation : initialValue}
        onChange={(event, value) =>
          handleValues(event, value ? value.id : null)
        }
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => option?.title || ""}
        options={options}
        loading={loading}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={
              helperText !== null ? (
                <p style={{ color: "#f51919" }}>{helperText}</p>
              ) : (
                ""
              )
            }
            error={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ marginTop: variant === "filled" ? "0" : "16px" }}
                >
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <div>{option.title}</div>
          </li>
        )}
      />
    </>
  );
}

export default CustomSearchDropDown;
