import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material";
import dbinactive from "../../assets/dashboard/dbinactive.svg";
import dbactive from "../../assets/dashboard/dbactive.svg";
import orgmanin from "../../assets/dashboard/orgmanin.svg";
import ormanac from "../../assets/dashboard/ormanac.svg";
import usermanin from "../../assets/dashboard/usermanin.svg";
import usermainac from "../../assets/dashboard/usermainac.svg";
import solhubin from "../../assets/dashboard/solhubin.svg";
import solhubac from "../../assets/dashboard/solhubac.svg";
import surmanin from "../../assets/dashboard/surmanin.svg";
import surmanac from "../../assets/dashboard/surmanac.svg";
import datmanin from "../../assets/dashboard/datmanin.svg";


import settingact from "../../assets/dashboard/settingact.svg";
import settingin from "../../assets/dashboard/settingin.svg";
import helpact from "../../assets/dashboard/helpact.svg";
import helpin from "../../assets/dashboard/helpin.svg";
const drawerWidth = 280;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "black",
  color: "white",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: "black",
  color: "white",
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white",
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const drawerList = [
  {
    title: "Dashboard",
    id: 1,
    active_icon: dbactive,
    inactive_icon: dbinactive,
    path: "dashboard",
  },
  {
    title: "Organization Management",
    id: 2,
    active_icon: ormanac,
    inactive_icon: orgmanin,
    path: "organization-management",
  },
  {
    title: "User Management",
    id: 3,
    active_icon: usermainac,
    inactive_icon: usermanin,
    path: "user-management",
  },
  {
    title: "Solution Hub Management",
    id: 4,
    active_icon: solhubac,
    inactive_icon: solhubin,
    path: "solution-hub-management",
  },
  {
    title: "Survey Management",
    id: 5,
    active_icon: surmanac,
    inactive_icon: surmanin,
    path: "survey-management",
  },
  {
    title: "Data Management",
    id: 6,
    active_icon: datmanin,
    inactive_icon: datmanin,
    path: "data-management",
  },
  {
    title: "Settings ",
    id:10  ,
    active_icon:  settingact,
    inactive_icon: settingin ,
    path: "settings"
  },
  // {
  //   title: "Help ",
  //   id:11 ,
  //   active_icon:helpact  ,
  //   inactive_icon:helpin  ,
  //   path: "help"
  // }
];
