import React, { useEffect, useState } from "react";
import styles from "./PublishSurveyHelpus.module.css";
import { PRIMARYCOLOR, WHITE } from "../../Utility/constants";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import updown from "../../assets/dashboard/updown.svg";
import { width } from "@mui/system";
function OrderSelect({ item, onChange, name, ...props }) {
  const { choices } = item;
  const [list, setList] = useState([]);
  const [displayList, setDisplayList] = useState([]);

  const handleClick = (id, idx) => {
    let tempArray = [...displayList];
    const value = list.includes(id)
      ? list.filter((i) => i !== id)
      : [...list, id];
    setList(value);
    if (value.length === 0) {
      setDisplayList(choices);
    } else {
      let updatedArr = reorderArray(tempArray, value);
      setDisplayList(updatedArr);
    }

    let matchingObjects = choices.filter((obj) => obj.id === id);
    onChange(value, name, matchingObjects, choices, item);
  };

  useEffect(() => {
    setDisplayList(choices);
  }, [choices]);

  function reorderArray(array1, array2) {
    return array1.sort((a, b) => {
      const indexA = array2.indexOf(a.id); // Index of `a.id` in `array2`
      const indexB = array2.indexOf(b.id); // Index of `b.id` in `array2`

      // If an ID is not in `array2`, place it after the ones present in `array2`.
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      // Otherwise, sort based on the index in `array2`.
      return indexA - indexB;
    });
  }

  return (
    <div className={styles.oContainer}>
      {displayList?.map((ch, idx) => {
        return (
          <div
            style={{
              background: `${list.includes(ch.id) ? PRIMARYCOLOR : "white"}`,
              cursor: "pointer",
              marginBottom: "20px",
            }}
            className={styles.genders}
            onClick={() => handleClick(ch.id, idx)}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {list.includes(ch.id) ? (
                <span className={styles.num}>{idx + 1} </span>
              ) : (
                <img src={updown} style={{ width: "20px" }} />
              )}
            </div>
            <p>{ch.title}</p>
          </div>
        );
      })}
    </div>
  );
}

export default OrderSelect;
