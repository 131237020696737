import React, { useContext, useEffect, useState } from "react";
import styles from "./PublishSurveyHelpus.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import homeLogo from "../../assets/componentAssest/Logo.png";
import homeImg from "../../assets/componentAssest/homeImg.png";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  CircularProgress,
  FormHelperText,
  getAccordionSummaryUtilityClass,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { PostApi, getApi } from "../../Utility/network";
import BasicSelect from "../BasicSelect/BasicSelect";
import MultiSelect from "./MultiSelect";
import ShowRating from "./ShowRating/ShowRating";
import MultiChoice from "./MultiChoice";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import OrderSelect from "./OrderSelect";
import { convertArrayToObjects } from "../../Utility/helper";
// question: null,
// answer_text: "",
// answer_choice: null,
// answer_choices_ids: null,

function PublishSurveyHelpus({ preview = false }) {
  const { setContext, contextState, token } = useContext(AppContext);

  const [listing, setListing] = useState([]);
  const [indexing, setIndexing] = useState(0);
  const [heading, setHeading] = useState({});
  const [hidden, setHidden] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [otherData, setOtherData] = useState();
  const [loading, setLoading] = useState(false);
  const [consert, setConsert] = useState();
  const [apiRes, setApiRes] = useState(false);

  const [surveyAnswer, setSurveyAnswer] = useState({
    uid_code: null,
    survey_id: null,
    question_responses: [],
    is_submit: false,
  });
  const [error, setError] = useState({ title: "" });
  const { surveyId } = useParams();
  const navigate = useNavigate();

  console.log(answer, "listing");
  useEffect(() => {
    // getData();
    getResponse();
  }, []);
  // useEffect(() => {
  //   if (consert == "no") {
  //     // navigate("/survey/acknowledgement");
  //     handleSubmit();
  //   }
  // }, [consert]);
  // const getData = async () => {
  //   try {
  //     const postResponse = await getApi(`/survey/section/?survey=${surveyId}`);
  //     // const { data } = postResponse;
  //     // let hiddenArray = [];
  //     // data.forEach((element) => {
  //     //   element.questions.forEach((el) => {
  //     //     if (el.is_hidden === true) {
  //     //       hiddenArray.push(el);
  //     //     }
  //     //     if (el.question_type === 1) {
  //     //       setHeading(el);
  //     //     }
  //     //   });
  //     // });

  //     // setHidden(hiddenArray);
  //     // setListing(data);
  //   } catch (error) {}
  // };

  const getResponse = async () => {
    setLoading(true);
    try {
      const postResponse = await PostApi(`/survey/response/`, {
        uid_code: null,
        survey_id: surveyId,
        is_submit: false,
        question_responses: [],
      });
      // const getApis = await getApi(`/survey/${surveyId}/summary/`);
      // const { data } = postResponse;
      const data = postResponse?.data?.survey?.sections;
      setOtherData(postResponse.data);
      let hiddenArray = [];
      data.forEach((element) => {
        element.questions.forEach((el) => {
          if (el.is_hidden === true) {
            hiddenArray.push(el);
          }
          if (el.question_type === 1) {
            setHeading(el);
          }
        });
      });

      setHidden(hiddenArray);
      setListing(data);
      setLoading(false);
    } catch (error) {}
  };

  const handleIndexing = (type) => {
    window.scrollTo(0, 0);

    let len = listing.length;
    if (type === "increment") {
      if (!handleValidation()) {
        return;
      } else {
        indexing + 1 < len && setIndexing((prev) => prev + 1);
      }
    }
    if (type === "decrement") {
      indexing - 1 >= 0 && setIndexing((prev) => prev - 1);
    }
  };

  const handleChange = (_value, _name, _data, _list, _item) => {
    handleAnswer(_value, _item, "select", _name);
    if (_data && _data.length > 0) {
      let new_data = _data[0];
      let totalChildQues = [];
      let showQuestions = [];
      let hideQuestions = [];

      _list.forEach((element) => {
        element.child_question.forEach((el) => {
          if (el.is_hidden === true) {
            totalChildQues.push(el.id);
          }
          if (element.id === new_data.id) {
            // if(operation === 1 ){

            // }
            showQuestions.push(el.id);
          } else {
            hideQuestions.push(el.id);
          }
        });
      });
      const newHidden = hidden.map((item, ind) => {
        if (showQuestions.includes(item.id)) {
          item.is_hidden = false;
        } else if (hideQuestions.includes(item.id)) {
          item.is_hidden = true;
        }
        return item;
      });
      setHidden(newHidden);
    }
  };
  console.log(answer, "answeranswer");
  const handleSubmit = async () => {
    // try {
    //   let data = { survey: surveyId, question_responses: answer };
    //   const responseData = await PostApi(`/survey/response/`, data);
    // } catch (error) {
    // }
    if (!handleValidation()) {
      return;
    }
    setApiRes(true);
    try {
      const data = {
        question_responses: answer,
        uid_code: otherData.uid_code,
        survey_id: surveyId,
        is_submit: true,
      };
      const responseData = await PostApi(`/survey/response/`, data);
      setContext((prev) => ({
        ...prev,
        snackbar: {
          open: true,
          message: "Survey Submmited Successfully",
          severity: "success",
        },
      }));
      setAnswer([]);
      navigate("/survey/acknowledgement");
      setApiRes(false);
    } catch (error) {}
  };
  const handleValidation = () => {
    let isValid = true;
    const errors = {};

    // Iterate through the current questions
    listing[indexing]?.questions.forEach((item) => {
      if (
        item.is_mandatory &&
        !hidden.some((h) => h.id === item.id && h.is_hidden)
      ) {
        const response = answer.find((a) => a.question === item.id);
        // if (consert == "no") {
        //   errors[item.id] = "This field is mandatory.";
        //   isValid = false;
        // }
        if (
          !response ||
          (!response.answer_text &&
            !response.answer_choice &&
            !response.answer_choices_ids)
        ) {
          errors[item.id] = "This field is mandatory.";
          isValid = false;
        }
      }
    });

    setError(errors);
    return isValid;
  };

  const handleAnswer = (e, item, type, name) => {
    const arr = [...answer];
    const position = arr.findIndex((itm, index) => {
      return itm.question === item.id;
    });
    if (listing[0] && type === "select") {
      const result = item?.choices.find((value) => value.id === e)?.title;
      if (result) {
        setConsert(result.toLowerCase());
      }
    }

    if (position !== -1) {
      if (type === "text") {
        const { value } = e.target;
        arr[position].answer_text = value;
        if (item.is_mandatory && !value.trim()) {
          setError((prev) => ({
            ...prev,
            [item.id]: "This field is mandatory.",
          }));
        }
      } else if (type === "multiple") {
        // arr[position].answer_choices_ids = e;
      } else {
        if (typeof e == "object") {
          if (name === "order") {
            const updArr = convertArrayToObjects(e);
            arr[position].answer_choices_ordering = updArr;
          } else {
            arr[position].answer_choices_ids = e;
          }
        } else {
          arr[position].answer_choice = e;
        }
      }
    } else {
      if (type === "text") {
        const { value } = e.target;
        arr.push({
          answer_text: value,
          question: item.id,
        });
      } else if (type === "multiple") {
        // arr.push({
        //   question: item.id,
        //   answer_choices_ids: e,
        // });
      } else {
        if (typeof e == "object") {
          if (name === "order") {
            const updArr = convertArrayToObjects(e);
            arr.push({
              question: item.id,
              answer_choices_ordering: updArr,
            });
          } else {
            arr.push({
              question: item.id,
              answer_choices_ids: e,
            });
          }
        } else {
          arr.push({
            question: item.id,
            answer_choice: e,
          });
        }
      }
    }

    setAnswer(arr);
  };
  return (
    <>
      {listing?.length > 0 && loading == false ? (
        <div className={styles.bocContainer}>
          <div className={styles.top}>
            <img
              src={heading?.header_banner ? heading?.header_banner : homeImg}
            ></img>
          </div>
          <div className={styles.middle}>
            <img src={!heading?.header_banner && homeLogo}></img>
          </div>

          <div className={styles.bottom}>
            {/* <h4 className={styles.head}> {data?.head1} </h4> */}
            {/* <p className={styles.desc}>{data?.desc}</p> */}
            {/* <h4 className={styles.head}> {data?.headGender} </h4> */}
            {listing.length > 0 &&
              listing[indexing].questions.map((item, index) => {
                const type = item.question_type;
                hidden.map((obj) => {
                  if (obj.id === item.id) {
                    item.is_hidden = obj.is_hidden;
                  }
                  return item;
                });
                // if (matchingObjects.length > 0) return;
                return (
                  <div key={item.id} style={{ marginBottom: "30px" }}>
                    {!item.is_hidden && [2, 3, 7, 8].includes(type) && (
                      <div style={{ width: "400px" }}>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          error={!!error[item.id]}
                          helperText={error[item.id]}
                          multiline={type === 3 ? true : false}
                          rows={4}
                          onChange={(e) => handleAnswer(e, item, "text")}
                        />
                      </div>
                    )}
                    {!item.is_hidden && type === 5 && (
                      <div style={{ width: "400px" }}>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        <BasicSelect
                          list={item.choices}
                          name="operator"
                          value="title"
                          onChange={handleChange}
                          item={item}
                          error={!!error[item.id]}
                          helperText={error[item.id]}
                        />
                      </div>
                    )}
                    {!item.is_hidden && type === 4 && (
                      <div>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        {error[item.id] && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {error[item.id]}
                          </FormHelperText>
                        )}

                        <MultiChoice
                          item={item}
                          onChange={handleChange}
                          name="multi"
                        />
                      </div>
                    )}
                    {!item.is_hidden && type === 6 && (
                      <div>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        {error[item.id] && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {error[item.id]}
                          </FormHelperText>
                        )}
                        <MultiSelect
                          item={item}
                          onChange={handleChange}
                          name="multiple"
                          error={!!error[item.id]}
                          helperText={error[item.id]}
                        />
                      </div>
                    )}
                    {!item.is_hidden && type === 14 && (
                      <div>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        {error[item.id] && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {error[item.id]}
                          </FormHelperText>
                        )}
                        <OrderSelect
                          item={item}
                          onChange={handleChange}
                          name="order"
                          error={!!error[item.id]}
                          helperText={error[item.id]}
                        />
                      </div>
                    )}
                    {!item.is_hidden && type === 9 && (
                      <div>
                        <p className={styles.fieldP}>
                          {item.placeholder}
                          {item.is_mandatory && "*"}
                        </p>
                        <p className={styles.fieldS}>{item.help_text}</p>
                        {error[item.id] && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {error[item.id]}
                          </FormHelperText>
                        )}
                        <div>
                          <ShowRating
                            item={item}
                            onChange={handleChange}
                            name="multi"
                            error={!!error[item.id]}
                            helperText={error[item.id]}
                          />
                        </div>
                      </div>
                    )}
                    {!item.is_hidden && type === 15 && (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${item.title}`,
                          }}
                        ></div>
                        {error[item.id] && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {error[item.id]}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            <div className={styles.gContainer}>
              {/* {data?.gender?.map((item, idx) => {
              return (
                <div className={styles.genders}>
                  <span className={styles.num}>{item.letter}</span>
                  <p>{item.name}</p>
                </div>
              );
            })} */}
            </div>
            <div className={styles.arrow}>
              <Button
                startIcon={<KeyboardBackspaceIcon />}
                variant="outlined"
                className={styles.back}
                onClick={() => handleIndexing("decrement")}
                disabled={indexing === 0}
              >
                Back
              </Button>
              <Button
                endIcon={<EastIcon />}
                variant="contained"
                className={styles.next}
                onClick={() => handleIndexing("increment")}
                disabled={
                  indexing === listing.length - 1
                    ? indexing === listing.length - 1
                    : consert == "no" && indexing === 0
                    ? true
                    : false
                }
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  apiRes
                    ? apiRes
                    : consert == "no" && indexing === 0
                    ? false
                    : preview == false
                    ? !(indexing === listing.length - 1)
                    : true
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      ) : listing?.length === 0 && loading == false ? (
        <h1 style={{ width: "100%", textAlign: "center" }}>No Data Found</h1>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default PublishSurveyHelpus;
// const data = {
//   head1: "Help us understand you a little better. ",
//   desc: "Please be rest assured that we will not collect any personally identifiable information from you. We are committed to individual privacy and none of these responses will ever be shared directly with your employer. We will only derive insights at a group level so that your employer can understand the real challenges and needs to help you stay in the workforce. ",
//   headGender: "Which gender identity do you most identify with?*",
//   gender: [
//     {
//       id: 1,
//       name: "Female",
//       letter: "A",
//     },
//     {
//       id: 2,
//       name: "Male",
//       letter: "B",
//     },
//     {
//       id: 3,
//       name: "Transgender Female",
//       letter: "C",
//     },
//     {
//       id: 4,
//       name: "Transgender Male",
//       letter: "D",
//     },
//     {
//       id: 5,
//       name: "Gender Non Conforming",
//       letter: "E",
//     },
//     {
//       id: 6,
//       name: "FemaPrefer not to Answere",
//       letter: "F",
//     },
//   ],
//   //   headEdu: "Educational Qualification (your highest degree)",
//   //   headChild: "How many children do you have?",
//   //   headAge: "Age of First Child",
//   //   headMail: "Email ID",
// };
