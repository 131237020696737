import React from "react";
import { PRIMARYCOLOR } from "../../../Utility/constants";

function ShowRating({ item, onChange = () => {}, name, ...props }) {
  const { choices } = item;
  const [list, setList] = React.useState(null);
  const handleClick = (id) => {
    setList(id);
    // setList(list.includes(id) ? list.filter((i) => i !== id) : [...list, id]);
    let matchingObjects = choices.filter((obj) => obj.id === id);
    onChange(id, name, matchingObjects, choices, item);
  };
  return (
    <div style={{ display: "inline-block", minWidth: "400px" }}>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {choices?.map((itm, idx) => {
          return (
            <p
              style={{
                border: "1px solid #e1e6ef80",
                padding: "8px 16px",
                margin: "0px",
                cursor: "pointer",
                flex: "1 0 auto",
                textAlign: "center",
                background: `${list == itm.id ? PRIMARYCOLOR : "white"}`,
              }}
              onClick={() => handleClick(itm.id)}
            >
              {itm.title}
            </p>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          color: "grey",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: "0px", fontSize: "12px" }}>{item.left_label}</p>
        <p style={{ margin: "0px", fontSize: "12px" }}>{item.central_label}</p>
        <p style={{ margin: "0px", fontSize: "12px" }}>{item.right_label}</p>
      </div>
    </div>
  );
}

export default ShowRating;
