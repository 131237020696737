import React from "react";
import styles from "./EmptyPage.module.css";
import lightHouse from "../../assets/componentAssest/lightHouse.png";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
function EmptyPage({ heading, desc, buttonName,pageImage,handleClick }) {
  return (
    <div className={styles.container}>
      <h3 className={styles.head}>{heading}</h3>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <div className={styles.img}>
            <img src={pageImage}></img>
          </div>
          <p className={styles.heading}> {desc} </p>
          {/* <p className={styles.desc}>We are waiting for data & insights before recommending solutions</p> */}
          <Button variant="contained" startIcon={<AddIcon />}onClick={handleClick} >
            {buttonName}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmptyPage;
