import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

export default function CustomSnackbar({ ...props }) {
  const { setContext, contextState } = React.useContext(AppContext);
  const { open, message, severity } = contextState.snackbar;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setContext((prev) => ({
      ...prev,
      snackbar: { open: false, message: "", severity: "success" },
    }));
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        severity="success"
        message={message}
        action={action}
      />
    </div>
  );
}
