import React, { useContext, useEffect, useState } from "react";
import styles from "../CreateSurvey.module.css";
import {
  GREYCOLOR,
  LIGHTBOXSHADOW,
  WHITE,
} from "../../../../Utility/constants";
import { dycryptAES } from "../../../../Utility/crypt";
import SurveyBoard from "../SurveyBoard/SurveyBoard";
import Properties from "../Properties/Properties";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import LabTabs from "../../../../Components/CustomMaterialComponents/LabTabs/LabTabs";
import { Button, TextField } from "@mui/material";
import { PatchApi, PostApi, baseURL, getApi } from "../../../../Utility/network";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { textObject } from "../utility";

function CreateSurvey(props) {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const { surveyQuestions, token, contextState, setContext } =
    useContext(AppContext);
  const [active, setActive] = useState(-1);
  const [propQuestion, setPropQuestion] = useState({});
  useEffect(() => {
    if (surveyQuestions && surveyQuestions?.questions.length > 0) {
      setPropQuestion(surveyQuestions?.questions[active]);
    } else {
      setPropQuestion({});
    }
  }, [surveyQuestions]);
  useEffect(() => {
    getData(1);
    setContext((prev) => ({ ...prev, surveyPage: { count: 1 } }));
  }, []);
  const handleClick = (index) => {
    setActive(index);
    setPropQuestion(surveyQuestions?.questions[index]);
    // submitForm();
  };

  const submitForm = async () => {
    const { allSection, currentSection, survey } = contextState;
    let allData = JSON.stringify(allSection);
    allData = JSON.parse(allData);
    allSection.forEach(async (item, index) => {
      let data = allData[index];
      if (item.index === currentSection.index) {
        const stringSection = JSON.stringify(currentSection);
        data = JSON.parse(stringSection);
      } else {
        return;
      }
      let isPost = true;
      const formDatas = currentSection.questions.filter((i, inn) => {
        if (i.hasOwnProperty("id")) isPost = false;
        return [1, 13, 10].includes(i.question_type);
      });
      const postData = data.questions.filter((i, inn) => {
        if (i.hasOwnProperty("id")) isPost = false;
        return ![1, 13, 11, 10].includes(i.question_type);
      });
      const conditionData = data.questions.filter((i, inn) => {
        if (i.hasOwnProperty("id")) isPost = false;
        return i.question_type === 11;
      });
      data.questions = postData;
      try {
        // const HitApi = isPost
        //   ? PostApi(`/survey/section/`, data)
        //   : PatchApi(`/survey/section/${data.id}/`, data);
        const HitApi = PatchApi(`/survey/section/${data.id}/`, data);
        const postResponse = await HitApi;
        let timer = false;
        if (data.id) {
          const getPromises = formDatas.map(async (item) => {
            const formData = new FormData();

            formData.append("title", item.title ? item.title : "title");
            formData.append("question_type", Number(item.question_type));
            formData.append("help_text", item.help_text ? item.help_text : `.`);
            formData.append("placeholder", item.placeholder);
            formData.append("is_mandatory", item.is_mandatory);
            formData.append("display_grid", item.display_grid);
            formData.append("index", item.index);
            if (item.header_banner && item.header_banner instanceof File) {
              formData.append("header_banner", item.header_banner);
            }
            if (item.footer_banner && item.footer_banner instanceof File) {
              formData.append("footer_banner", item.footer_banner);
            }
            if (item.image && item.image instanceof File) {
              formData.append("image", item.image);
            }
            formData.append("section_id", data.id);
            if (item.id) {
              timer = true;
              await axios
                .patch(
                  `${baseURL}/survey/question/${item.id}/`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "application/octet-stream",
                      Authorization: `Bearer ${token.access}`,
                    },
                  }
                )
                .then((response) => {})
                .catch((error) => {});
            } else {
              await axios
                .post(`${baseURL}/survey/question/`, formData, {
                  headers: {
                    "Content-Type": "application/octet-stream",
                    Authorization: `Bearer ${token.access}`,
                  },
                })
                .then((response) => {})
                .catch((error) => {});
            }
          }); // Create promises array
        }
        const postCondition = conditionData.map(async (i, ind) => {
          const { choice, question, section, operation, operator } = i;
          const newData = { choice, question, section, operation, operator };
          const data = await PostApi(
            `/survey/conditional_logic/update_choice/`,
            newData
          );
        });
        if (timer) {
          setTimeout(() => {
            getData();
          }, 1000);
        } else {
          getData();
        }
        // const getPromises = [1, 2, 3].map(item => GetApi(`/survey/${item}`)); // Create promises array
        // const getResponses = await Promise.all(getPromises); // Wait for all to resolve
        // setContext(prev=>({...prev,}))
      } catch (error) {
        console.log(error, "data is here if");
      }
    });
  };

  const getData = async (num) => {
    try {
      const postResponse = await getApi(`/survey/section/?survey=${surveyId}`);
      const { data } = postResponse;
      let newData = JSON.stringify(data);
      let newDataNew = JSON.parse(newData);
      newData = JSON.parse(newData);
      let newArray = [];
      if (newDataNew.length > 0) {
        newDataNew.map((ques, quesi) => {
          newDataNew[quesi].survey_id = newDataNew[quesi].survey.id;
          newDataNew[quesi].survey_name = newDataNew[quesi].survey.title;

          ques.questions.forEach((item, index) => {
            let newArrays = [];
            if (item.question_type === 4) {
              item.choices.forEach((item1, index1) => {
                item1.child_question.forEach((item2, index2) => {
                  const {
                    help_text,
                    index: innerIndex,
                    operation,
                    operator,
                    id: choice_id,
                  } = item1;
                  const { id: question_id } = item2;
                  newArrays.push({
                    ...textObject,
                    question_type: 11,
                    help_text: "",
                    index: innerIndex,
                    display_grid: 1,
                    choice: choice_id,
                    question: [question_id],
                    section: null,
                    operation: operation,
                    operator: operator,
                  });
                  newDataNew[quesi].questions.splice(index, 0, {
                    ...textObject,
                    question_type: 11,
                    help_text: "",
                    index: innerIndex,
                    display_grid: 1,
                    choice: choice_id,
                    question: [question_id],
                    section: null,
                    operation: operation,
                    operator: operator,
                  });
                });
              });
            }
          });
        });
      }
      if (data.length > 0) {
        const { count } = contextState.surveyPage;
        let currentCount = 0;
        if (num === 1) {
          currentCount = 0;
        } else {
          currentCount = count <= 1 ? 0 : count - 1;
        }

        setContext((prev) => ({
          ...prev,
          currentSection: {
            ...newDataNew[currentCount],
            survey_id: newDataNew[currentCount].survey.id,
            survey_name: newDataNew[currentCount].survey.title,
          },
          allSection: newDataNew,
        }));
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div
        className={styles.centerDiv}
        style={{ background: GREYCOLOR, boxShadow: LIGHTBOXSHADOW }}
      >
        <div
          style={{
            background: WHITE,
            padding: "10px",
            margin: "15px",
            borderRadius: "10px",
            height: "92%",
            overflow: "auto",
          }}
        >
          <LabTabs />
          {/* <ConditionalType /> */}
          {surveyQuestions?.questions.map((item, index) => {
            return (
              <div
                style={{
                  background: `${index === active ? `${GREYCOLOR}` : ""} `,
                  padding: "15px 10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => handleClick(index)}
              >
                <SurveyBoard data={item} />
              </div>
            );
          })}
        </div>
        <Button
          variant="contained"
          style={{ marginLeft: "10px" }}
          disabled={false}
          onClick={submitForm}
        >
          {" "}
          Save As Draft
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px" }}
          disabled={false}
          onClick={() => navigate(`/publish-survey/${surveyId}`)}
        >
          {" "}
          Preview
        </Button>
      </div>
      <div className={styles.rightContainer} style={{ background: WHITE }}>
        <Properties data={propQuestion} />
      </div>
    </React.Fragment>
  );
}

export default CreateSurvey;
