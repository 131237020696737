import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./UpdateOrganization.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import circular from "../../assets/componentAssest/circular.png";
import deleteIcon from "../../assets/componentAssest/delete.png";
import edit from "../../assets/componentAssest/edit.png";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomSerachDropDown from "../../Components/CustomSerachDropDown/CustomSerachDropDown";
import location from "../../assets/componentAssest/location.png";
import EmptyProfile from "../../assets/componentAssest/EmptyProfile.png";

import createOrg from "../../assets/componentAssest/createOrg.png";
import axios from "axios";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { baseURL, getApi, PostApi } from "../../Utility/network";

function UpdateOrganization({
  handleClickClose,
  open,
  getProfileDataprev = () => {},
  getImage = () => {},
  img = "",
  // updData,
  orgId,
  getContactData,
}) {
  const { setContext, contextState, token } = useContext(AppContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadeImgFile, setUploadImgFile] = useState("");
  const [image, setImage] = useState("");
  const [addError, setAddError] = useState({});

  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });
  const [crestData, setCreateData] = useState();
  const [updData, setUpData] = useState();
  const [dataField, setDataField] = useState({
    name: "",
    website: "",
    // city: "",
    location: "",
    industry: "",
    employee: "",
    createdBy: "",
    crestedOn: "",
  });
  const [dropDown, setDropDown] = useState([]);
  const fieldStyle = { width: "100%" };
  console.log(dataField, "dataField");

  const getData = async (id) => {
    try {
      const getResponse = await getApi(`profile/organisation/${id}`);
      setUpData(getResponse.data);
    } catch (error) {
      console.error("Error fetching organisation data", error);
    }
  };
  const CreateOrgData = async () => {
    try {
      const getResponse = await getApi(`profile/sector/`);
      setDropDown(getResponse.data);
    } catch (error) {
      console.error("Error fetching organisation data", error);
    }
  };
  useEffect(() => {
    CreateOrgData();
    // getData();
  }, []);
  useEffect(() => {
    if (orgId) {
      getData(orgId);
    }
  }, [orgId]);

  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
    setAddError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const handleValidation = async () => {
    // debugger;
    let errors = {};
    let formIsValid = true;

    if (!dataField?.name) {
      formIsValid = false;
      errors["name"] = "Organization Name required";
    }

    const websiteRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

    if (!dataField?.website) {
      formIsValid = false;
      errors["website"] = "Organization Website required";
    } else if (!websiteRegex.test(dataField?.website)) {
      formIsValid = false;
      errors["website"] = "enter valid website";
    }

    if (!dataField?.location) {
      formIsValid = false;
      errors["location"] = "Organization City required";
    }

    if (!dataField?.industry) {
      formIsValid = false;
      errors["industry"] = "Industry / Sector required";
    }

    if (!dataField?.employee) {
      formIsValid = false;
      errors["employee"] = "Employee Size required";
    }
    const fileType = /\.?(jpg|jpeg|png|webp)$/i;

    if (!uploadeImgFile) {
      formIsValid = false;
      errors["image"] = "Organization logo is required";
    } else if (
      !fileType.test(
        orgId
          ? typeof uploadeImgFile === "object" && uploadeImgFile.name
            ? uploadeImgFile.name
            : uploadeImgFile
          : typeof uploadeImgFile === "object" && uploadeImgFile.name
          ? uploadeImgFile.name
          : uploadeImgFile
      )
    ) {
      formIsValid = false;
      errors["image"] = "Enter a valid image format (jpg, jpeg, png, webp)";
    } else if (uploadeImgFile.size > 2 * 1024 * 1024) {
      formIsValid = false;
      errors["image"] = "Image  max size should be 2MB";
    }
    const isDimensionValid = await validateImageDimensions(
      orgId
        ? typeof uploadeImgFile === "object"
          ? uploadeImgFile
          : ""
        : uploadeImgFile,
      formIsValid,
      errors
    );
    if (!isDimensionValid) {
      formIsValid = false;
      errors["image"] = "Image must be 500px by 500px";
    }

    setAddError(errors);
    return formIsValid;
  };
  const validateImageDimensions = (file) => {
    if (!file) {
      return Promise.resolve(true); // No file, skip dimension validation
    }

    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
        img.onload = function () {
          if (!(img.width == 500 && img.height == 500)) {
            resolve(false); // Invalid dimensions
          } else {
            resolve(true); // Valid dimensions
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };
  const CreateData = async () => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }

    const formData = new FormData();
    formData.append("logo", uploadeImgFile);
    formData.append("title", dataField?.name);
    formData.append("employee_size", dataField?.employee);
    formData.append("website", dataField?.website);
    formData.append("location_id", dataField?.location);
    formData.append("sector_id", dataField?.industry);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .post(`${baseURL}/profile/organisation/`, formData, axiosConfig)
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          setImageUrl({ id: response.data.id, image: response.data.image });
          getProfileDataprev();
          handlepopUpclose();
          getContactData();
        } else if (response.status == 400) {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOrg = async (_orgId) => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }
    const formData = new FormData();
    if (typeof uploadeImgFile == "object") {
      formData.append("logo", uploadeImgFile);
    }

    formData.append("title", dataField?.name);
    formData.append("employee_size", dataField?.employee);
    formData.append("website", dataField?.website);
    formData.append("location_id", dataField?.location);
    formData.append("sector_id", dataField?.industry);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .patch(
        `${baseURL}/profile/organisation/${_orgId}/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          setImageUrl({ id: response.data.id, image: response.data.image });
          getProfileDataprev();
          handlepopUpclose();
          getContactData();
        } else if (response.status == 400) {
          console.log(response?.data?.error, "error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const getImageFile = async () => {
  //   if (!orgId) {
  //     return;
  //   }
  //   const formData = new FormData();

  //   formData.append("thumbnail", uploadeImgFile);

  //   const axiosConfig = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${token.access}`,
  //     },
  //   };
  //   axios
  //     .patch(
  //       `${baseURL}/survey/recommended_solutions/${orgId}/`,
  //       formData,
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       if (
  //         response.status == 200 ||
  //         response.status == 201 ||
  //         response.status == 204
  //       ) {
  //         console.log(response.data, "hello moto");
  //       } else if (response.status == 400) {
  //         // console.log(response?.data?.error, "error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   if (selectedImage) {
  //     getImageFile();
  //   }
  // }, [selectedImage]);

  useEffect(() => {
    if (updData) {
      setDataField({
        name: updData?.title,
        employee: updData?.employee_size,
        website: updData?.website,
        location: updData?.location,
        industry: updData?.sector?.id,
        crestedOn: updData?.created,
        createdBy: `${updData?.created_by?.first_name} ${updData?.created_by?.last_name}`,
      });
      setUploadImgFile(updData?.logo);
    }
  }, [updData]);
  console.log(updData, "updData");

  useEffect(() => {
    setImage(img);
  }, [img]);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      getImage(event.target.files[0]);
      setUploadImgFile(event.target.files[0]);
    }
    setAddError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };

  const handleClick = () => {
    fileInputRef.current.click();
    setImage("");
  };
  const clearState = () => {
    setDataField({
      name: "",
      website: "",
      location: "",
      industry: "",
      employee: "",
      createdBy: "",
    });
    setUploadImgFile("");
  };

  const handlepopUpclose = () => {
    handleClickClose();
    clearState();
  };
  const handleValuesCity = (e, value) => {
    console.log(value, "check");

    // if (!value) {
    //   setAddError(true);
    // } else {
    //   setAddError(false);

    setDataField((prevState) => ({
      ...prevState,
      location: value,
    }));
    // }
  };
  return (
    <Dialog
      onClose={handlepopUpclose}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={styles.con}>
        <div className={styles.head}>
          <h3 className={styles.heading}>Create New Organization</h3>
          <CloseIcon onClick={handlepopUpclose} style={{ cursor: "pointer" }} />
        </div>
        <div className={styles.profile}>
          <div className={styles.img}>
            {orgId ? (
              orgId && selectedImage ? (
                <img src={selectedImage} />
              ) : selectedImage == null && uploadeImgFile == null ? (
                <img src={createOrg} />
              ) : (
                <img src={uploadeImgFile} />
              )
            ) : uploadeImgFile ? (
              <img src={selectedImage} onClick={handleClick} />
            ) : (
              <img src={createOrg} onClick={handleClick} />
            )}
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(e) => handleImageChange(e, "userFile")}
            />
          </div>
          <div className={styles.profileInfo}>
            {orgId && (
              <div className={styles.editIcon}>
                <div className={styles.edit}>
                  <img src={edit} onClick={handleClick} />
                </div>

                {/* <div className={styles.delete}>
                  <img src={deleteIcon} />
                </div> */}
              </div>
            )}
            <p className={styles.info}>
              Image Size should be max - 2 mb
              <br />
              Image Dimensions - 500px by 500px
              <br />
              File Type - Jpg, Png, WebP
            </p>
            {addError.image && (
              <p style={{ color: "red", fontSize: "12px" }}>{addError.image}</p>
            )}
            {selectedImage && !addError?.image && (
              <p style={{ color: "green", fontSize: "12px" }}>
                Upload image successfully
              </p>
            )}
          </div>
        </div>
        <div className={styles.fieldbox}>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Organization Name</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="name"
              value={dataField?.name}
              error={!!addError.name}
              helperText={addError.name}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Organization Website</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="website"
              value={dataField?.website}
              error={!!addError.website}
              helperText={addError.website}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Organization City</p>
            {/* <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="city"
              value={dataField?.city}
              error={!!addError.city}
              helperText={addError.city}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={location} alt="Location Icon" />
                  </InputAdornment>
                ),
              }}
            /> */}
            <CustomSerachDropDown
              handleValues={handleValuesCity}
              helperText={addError?.location}
              error={!!addError.location}
              // label="Search Block"
              apiHitPoint="/profile/location/"
              variant="filled"
              // userId={userId}
              dataField={dataField}
              FieldName={"Location"}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Industry / Sector</p>
            <Select
              value={dataField?.industry}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="industry"
              error={!!addError.industry}
            >
              {dropDown?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
            {addError.industry && (
              <p style={{ color: "red", fontSize: "12px" }}>
                {addError.industry}
              </p>
            )}
          </div>
          <div className={styles.smallField}>
            <div className={orgId ? "" : styles.fieldCon}>
              <p className={styles.name}> Employee Size</p>
              <TextField
                size="small"
                style={fieldStyle}
                onChange={handleChangeINput}
                name="employee"
                value={dataField?.employee}
                error={!!addError.employee}
                helperText={addError.employee}
              />
            </div>
            <div className={orgId ? "" : styles.fieldCon}>
              <p className={styles.name}> Created by</p>
              <TextField
                size="small"
                style={fieldStyle}
                disabled
                onChange={handleChangeINput}
                name="createdBy"
                value={
                  orgId
                    ? dataField?.createdBy
                    : `${contextState?.user?.first_name} ${contextState?.user?.last_name}`
                }

                // contextState?.user?.first_name
              />
            </div>
            {orgId && (
              <div>
                <p className={styles.name}>Created On</p>
                <TextField
                  size="small"
                  style={fieldStyle}
                  disabled
                  value={formatDate(dataField?.crestedOn)}
                />
              </div>
            )}
          </div>
          <div className={styles.btns}>
            <Button
              variant="contained"
              onClick={() => {
                orgId ? UpdateOrg(orgId) : CreateData();
              }}
            >
              {orgId ? "Update Organization" : "Create Organization"}
            </Button>
            <Button variant="outlined" onClick={handlepopUpclose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default UpdateOrganization;
