import React from "react";
import styles from "./Summary.module.css";
import { CircularProgress } from "@mui/material";
function Summary({ data, loading, props }) {
  return (
    <>
      {data?.length > 0 && loading == false ? (
        <div>
          {data.map((item, index) => {
            return (
              <div
                style={{
                  marginBottom: "50px",
                  display: `${item.choices.length > 0 ? "block" : "none"}`,
                }}
              >
                <p style={{ marginBottom: "10px" }}>
                  <b>{item.question}</b>
                </p>
                <div className={styles.gContainer}>
                  {item.choices.map((itm, idx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "48%",
                        }}
                      >
                        <div
                          style={{
                            background: "rgb(249, 250, 251)",
                            cursor: "pointer",
                            boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
                            marginRight: "10px",
                          }}
                          className={styles.genders}
                        >
                          <span className={styles.num}>{idx + 1} </span>
                          <p>
                            <b>{itm.choice}</b>
                          </p>
                        </div>
                        <span style={{ fontSize: "14px" }}>
                          (<b>{itm.percentage}%</b>) <b>{itm.count}</b>{" "}
                          Responses
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : data?.length === 0 && loading == false ? (
        <h1 style={{ width: "100%", textAlign: "center" }}>No Data Found</h1>
      ) : (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default Summary;

const ques = [
  {
    title: "I have understood informed consent above and wish to proceed",
    id: 1,
    questions: [
      {
        title: "yes",
        id: 1,
        count: 130,
        percent: "98%",
      },
      {
        title: "No",
        id: 2,
        count: 10,
        percent: "2%",
      },
    ],
  },
  {
    title: "Which gender indentify do you most identify with",
    id: 2,
    questions: [
      {
        title: "Female",
        id: 3,
        count: 130,
        percent: "98%",
      },
      {
        title: "Male",
        id: 4,
        count: 10,
        percent: "2%",
      },
      {
        title: "Transgender",
        id: 5,
        count: 10,
        percent: "2%",
      },
      {
        title: "Transgender Male",
        id: 6,
        count: 10,
        percent: "2%",
      },
      {
        title: "Male",
        id: 4,
        count: 10,
        percent: "2%",
      },
      {
        title: "Male",
        id: 4,
        count: 10,
        percent: "2%",
      },
    ],
  },
];
