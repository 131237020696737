import React, { useContext, useEffect, useState } from "react";
import styles from "./BuildSurvey.module.css";
import {
  Button,
  Dialog,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import addEmptyImg from "../../assets/componentAssest/addEmptyImg.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SearchIcon from "@mui/icons-material/Search";
import locationAdd from "../../assets/componentAssest/locationAdd.png";
import remove from "../../assets/componentAssest/locationRemove.png";
import calender from "../../assets/componentAssest/Calendar.png";
import DateField from "../DateField/DateField";
import UploadImage from "../UploadImage/UploadImage";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import BasicSelect from "../BasicSelect/BasicSelect";
import { stringToYYYYMMDD } from "../../Utility/helper";
import { baseURL, getApi, PatchApi } from "../../Utility/network";
import axios from "axios";
function BuildSurvey({
  handleClose = () => {},
  open,
  data,
  getDetails = () => {},
  surveyId,
}) {
  const { setContext, contextState, token, searchValue } =
    useContext(AppContext);
  const [orgSelect, setOrgSelect] = useState(true);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });
  const [org, setOrg] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const start_date = new Date();
  const formattedDate =
    start_date.getFullYear() +
    "-" +
    String(start_date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(start_date.getDate()).padStart(2, "0");

  const [form, setForm] = useState({
    title: "",
    language: 1,
    location_id: 1,
    organisation_id: 1,
    start_date: formattedDate,
    thumbnail_id: imageUrl,
    end_date: formattedDate,
  });

  const handleChange = (name, data) => {
    setForm((prev) => ({
      ...prev,
      [name]:
        name === "start_date" || name === "end_date"
          ? stringToYYYYMMDD(data)
          : data,
    }));
  };
  const getOrganization = async () => {
    const getResponse = await getApi(`/profile/organisation/`);
    const { data } = getResponse;

    data.splice(0, 0, { id: 0, title: "All" });

    setOrg(data);
  };
  useEffect(() => {
    getOrganization();
  }, []);
  const handleSubmit = async () => {
    const data = { ...form, thumbnail_id: imageUrl?.id };
    try {
      const postResponse = await PatchApi(`/survey/${surveyId}/`, data);

      getDetails(surveyId);
      handleClose();
    } catch (error) {
      console.log(error, "helo data form");
    }
  };

  useEffect(() => {
    if (data !== null) {
      setForm({
        title: data?.title,
        language: 1,
        location_id: 1,
        organisation_id: data?.organisation?.id,
        start_date: data?.start_date,
        thumbnail_id: imageUrl,
        end_date: data?.end_date,
      });
      // setSelectedImage(data?.thumbnail?.image);
      setImageUrl({ id: data?.thumbnail?.id, image: data?.thumbnail?.image });
    }
  }, []);

  useEffect(() => {
    if (imageUrl?.id !== null) {
      const formData = new FormData();
      formData.append("image", image);
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token.access}`,
        },
      };
      const id = imageUrl?.id;
      axios
        .patch(
          `${baseURL}/survey/survey_thumbnail/${id}/`,
          formData,
          axiosConfig
        )
        .then((response) => {
          setImageUrl({ id: response.data.id, image: response.data.image });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [image]);
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      <div className={styles.con}>
        <h1 className={styles.heading}> Update Survey</h1>
        <div>
          <p style={{ marginTop: "0px" }}>
            <b>Survey Name</b>
          </p>
          <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Survey Name"
            onChange={(e) => handleChange("title", e.target.value)}
            name="title"
            value={form?.title}
          />
        </div>
        <div>
          <p>
            <b>Company Name</b>
          </p>
          {/* <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Search Company"
            value={"G10 consultancy"}
            disabled={true}
          /> */}
          <BasicSelect
            value_id={form?.organisation_id}
            list={org}
            name="list"
            value="title"
            orgSelect={orgSelect}
            onChange={(value, name) => {
              setForm((prev) => ({ ...prev, organisation_id: value }));
            }}
          />
        </div>
        <div>
          <p>
            <b>Location</b>
          </p>
          <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Location"
            disabled={true}
          />
        </div>
        {/* <div>
          <p>
            <b>Location</b>
          </p>
          <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Location"
            disabled={true}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: "1 1 0", marginRight: "10px" }}>
            <p>
              <b>Created by</b>
            </p>
            <TextField
              style={{ width: "100%" }}
              size="small"
              placeholder="Search Company"
              disabled={true}
              value={`${contextState?.user?.first_name} ${contextState?.user?.last_name}`}
            />
          </div>
          <div style={{ flex: "1 1 0" }}>
            <p>
              <b>Language</b>
            </p>
            <TextField
              style={{ width: "100%" }}
              size="small"
              placeholder="Search Company"
              disabled={true}
              value="English"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div style={{ width: "80%" }}>
            <p>
              <b>Survey Creation</b>
            </p>
            <DateField disabled={true} />
          </div>
          <div style={{ width: "80%" }}>
            <p>
              <b>Start Date</b>
            </p>
            <DateField
              onChange={(date) => handleChange("start_date", date)}
              name="start_date"
              startDate={form.start_date}
            />
          </div>
          <div style={{ width: "80%", marginLeft: "auto" }}>
            <p>
              <b>Close Date</b>
            </p>
            <DateField
              onChange={(date) => handleChange("end_date", date)}
              name="end_date"
              startDate={form.end_date}
            />
          </div>
        </div>
        <div>
          <p>
            <b>Select Survey Thumbnail</b>
          </p>
          <div style={{ width: "200px", width: "200px" }}>
            {/* <img src={addImage} onClick={() => setView(2)} /> */}
            <UploadImage
              getImage={setImage}
              img={imageUrl?.image}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
          >
            Update Survey
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
export default BuildSurvey;
