import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./ShareSurveyPopUp.module.css";
import { Button, Dialog, InputAdornment, TextField } from "@mui/material";
import copy from "../../assets/componentAssest/copy.png";
import pdf1 from "../../assets/componentAssest/pdf1.png";
import jpg from "../../assets/componentAssest/jpg.png";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function ShareSurveyPopUp({
  openDialog,
  handleCloseDialog = () => {},
  data,
  setInvited,
  respondatePrev,
  handlePatch = () => {},
  surveyId,
}) {
  const { setContext } = useContext(AppContext);

  const [respo, setRespo] = useState({ respondent: "" });
  const textFieldRef = useRef(null);

  useEffect(() => {
    // debugger
    if(respondatePrev){
      setRespo({ respondent: respondatePrev });

    }
  }, [openDialog,respondatePrev]);

  const handlepdf = (url) => {
    window.open(url);
  };
  const handleGpej = (url) => {
    window.open(url);
  };
  const handleCopyClick = () => {
    const textFieldValue = textFieldRef.current.value; // Get the value from the text field

    navigator.clipboard
      .writeText(textFieldValue)
      .then(() => {
        setContext((prev) => ({
          ...prev,
          snackbar: { open: true, message: "Link Copied", severity: "success" },
        }));
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setContext((prev) => ({
          ...prev,
          snackbar: {
            open: true,
            message: "Failed to copy link",
            severity: "error",
          },
        }));
      });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setRespo({
      ...respo,
      [name]: value,
    });

  };
  useEffect(() => {
    setInvited(respo?.respondent);
  }, [respo]);
 
  return (
    <Dialog open={openDialog} fullWidth={true}>
      <div className={styles.con}>
        <div className={styles.top}>
          <h3 className={styles.heading}>Share Survey</h3>
          <div onClick={handleCloseDialog} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </div>
        </div>
        <div>
          <p className={styles.text}>
            Enter the number of respondents you are sending the survey to
          </p>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Estimated # of respondents"
            value={respo?.respondent} // Bind value to respo state
            name="respondent"
            onChange={handleChangeInput} // Update respo state on input change
          />
        </div>
        <div className={styles.qr}>
          <img src={data?.qr_code_jpeg_url} alt="QR Code" />
        </div>
        <div>
          <TextField
            size="small"
            style={{ width: "100%" }}
            value={data?.survey_url}
            inputRef={textFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={handleCopyClick}
                >
                  <img src={copy} alt="copy" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <p className={styles.download}>Download as</p>
        <div className={styles.btns}>
          <div className={styles.left}>
            <Button
              variant="contained"
              startIcon={<img src={pdf1} alt="PDF" />}
              onClick={() => handlepdf(data?.qr_code_pdf_url)}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              startIcon={<img src={jpg} alt="JPG" />}
              // Uncomment and implement if you want to handle JPG download
              onClick={() => handleGpej(data?.qr_code_jpeg_url)}
            >
              JPG
            </Button>
          </div>
          <Button
            variant="outlined"
            onClick={() => {
              handlePatch(surveyId);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ShareSurveyPopUp;
