import React, { useEffect, useState } from "react";
import styles from "./AddCatlog.module.css";
import CustomTable from "../CustomTable/CustomTable";
import { DeleteApi, getApi, PostApi } from "../../Utility/network";
import { width } from "@mui/system";
import AddContact from "../AddContact/AddContact";
import { useLocation, useParams } from "react-router-dom";
import Header from "../Header/Header";
import AddNewContact from "../AddNewContact/AddNewContact";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";
import RecommendedPopUP from "../../Pages/Dashboard/RecommendedPopUP/RecommendedPopUP";
import AddSolution from "../AddSolution/AddSolution";
import AddOrgSoluction from "../AddOrgSoluction/AddOrgSoluction";
import addPoc from "../../assets/componentAssest/addPOC.png";
import AddSoluctionPop from "../AddSoluctionPop/AddSoluctionPop";
function AddCatlog() {
  const { id } = useParams();

  const [tableBody1, setTableBody1] = useState([
    {
      id: 1,
      Name: "Shriya Gupta",
      Email: "willie.jennings@example.com",
      Date: "10/12/2016",
    },
  ]);
  const [open1, setOpen1] = useState(false);

  const [topData, setTopData] = useState();
  const [contact, setContact] = useState();
  const [openContact, setOPenContact] = useState(false);
  const [editData, setEditData] = useState();
  const [soluctionData, setSoluctionData] = useState([]);
  const [open, setOPen] = useState();
  const [deletedata, setdeleteData] = useState(false);
  const [pocId, setPocId] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [orgTop, setOrgTop] = useState();
  const [video, setVideo] = useState();
  const [createOrg, setCreateOrg] = useState(false);
  const [update, setUpdate] = useState();
  const [deleteSol, setDeleteSol] = useState(null);
  const [openPop, setOpenPop] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const HandleOpen = () => {
    setOPenContact(true);
  };
  const Close = () => {
    setOPenContact(false);
  };
  const addOPen = () => {
    setOPen(true);
  };
  const addClose = () => {
    setOPen(false);
  };
  const handleClose = () => {
    setOpen1(false);
  };
  const videoData = (data) => {
    handleOpen();
    setVideo(data);
  };
  const handleOpen = () => {
    setOpen1(true);
  };
  const handleClickClose = () => {
    setCreateOrg(false);
    setUpdate();
  };
  const getContactData = async (id) => {
    try {
      const getResponse = await getApi(`profile/organisation/${id}/get_pocs/`);
      // setTopData(getResponse?.data);
      setEditData(getResponse?.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getSolution = async (id) => {
    try {
      const getResponse = await getApi(
        `profile/organisation/${id}/recommended_solutions/`
      );

      const modifyArray = getResponse?.data?.map((item) => {
        let statusStyle;

        switch (item.status_str) {
          case "Active":
            statusStyle = styles.active;
            break;
          case "Paused":
            statusStyle = styles.Paused;
            break;
          case "Withdrawn":
            statusStyle = styles.Withdrawn;
            break;
          case "Inactive":
            statusStyle = styles.Inactive;
            break;
        }

        return {
          ...item,
          name: <p className={styles.head}>{item?.solution?.title}</p>,
          statuses: <div className={statusStyle}>{item?.status_str}</div>,
          solutionprovider: <p>{item?.solution?.provider?.title}</p>,
          solutiontype: <p>{item?.solution?.solution_type?.title}</p>,
        };
      });

      setSoluctionData(modifyArray);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getDelete = async (_id, poc_id) => {
    // const url = poc_id
    //   ? `profile/organisation/${_id}/delete_poc?poc_id=${poc_id}`
    //   : `survey/recommended_solutions/${id}/`;
    try {
      const getResponse = await DeleteApi(
        `profile/organisation/${_id}/delete_poc?poc_id=${poc_id}`
      );
      getContactData(id);
    } catch (error) {
      console.error(error);
    }
  };
  const getDelete1 = async (soluction_id) => {
    try {
      const getResponse = await DeleteApi(
        `/survey/recommended_solutions/assigned/${soluction_id}/`
      );
      // getContactData(id);
      getSolution(id);
    } catch (error) {
      console.error(error);
    }
  };
  const getTopDAta = async (id) => {
    try {
      const getResponse = await getApi(`profile/organisation/${id}`);
      setTopData(getResponse?.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    getContactData(id);
    getSolution(id);
    getTopDAta(id);
    getOrgTop(id);
  }, []);
  const handleDelete = (poc_id) => {
    setdeleteData(true);
    setDeleteId(id);
    // getDelete(id);
    setPocId(poc_id);
  };
  const handleDelete1 = (id) => {
    setdeleteData(true);
    setDeleteSol(id);
  };
  // const handleDelete1 = (id) => {
  //   setdeleteData(true);
  //   setDeleteId(id);
  // };
  const handlepopUpclose = () => {
    setdeleteData(false);
  };
  const getOrgTop = async (id) => {
    try {
      const getResponse = await getApi(
        `/profile/organisation/${id}/dashboard_stats/`
      );
      setOrgTop(getResponse?.data);
      // setOrg(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const headerData = [
    {
      id: 1,
      name: "NO OF SURVEYS",
      value: orgTop?.no_of_surveys,
      url: "details",
    },
    {
      id: 2,
      name: "NO OF SOLUTIONS",
      value: orgTop?.no_of_solutions,
    },
    {
      id: 3,
      name: "TOTAL Respondents",
      value: orgTop?.total_respondents,
      outOf: orgTop?.total_invited_respondents,
    },
  ];
  const handleEdit = (data) => {
    setCreateOrg(true);
    setUpdate(data);
  };
  const handleClosePop = () => {
    setOpenPop(false);
  };
  return (
    <div>
      <Header data={headerData} />
      <CustomTable
        tableHeads={tableHeads}
        tableBody={editData}
        tableVisible={false}
        catlogData={true}
        cat={topData}
        HandleOpen={HandleOpen}
        addOPen={addOPen}
        handleDelete={handleDelete}
        formatDate={formatDate}
        dashBoard={true}
      />
      <div className={styles.soluction}>
        <p className={styles.nameSoluction}>Recommended Solutions</p>
        <div className={styles.add} onClick={() => setOpenPop(true)}>
          <img src={addPoc} />
          <p>Add Solutions</p>
        </div>
      </div>
      {/* {soluctionData.length > 0 && ( */}
      <div>
        <CustomTable
          tableHeads={tableHeads1}
          tableBody={soluctionData}
          ThreeActions={true}
          videoData={videoData}
          handleEdit={handleEdit}
          handleDelete={handleDelete1}
          dashBoard={true}
        />
      </div>
      {/* )} */}
      {openContact && (
        <div>
          <AddContact
            open={openContact}
            close={Close}
            datas={editData}
            id={id}
            getContactData={getContactData}
          />
        </div>
      )}
      {open && (
        <AddNewContact
          close={addClose}
          open={open}
          id={id}
          getContactData={getContactData}
        />
      )}
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          delete1={getDelete1}
          handlepopUpclose={handlepopUpclose}
          pocId={pocId}
          deleteSol={deleteSol}
        />
      )}
      <RecommendedPopUP data={video} open={open1} handleClose={handleClose} />
      {createOrg && (
        <AddOrgSoluction
          handleClickClose={handleClickClose}
          open={createOrg}
          update={update}
          soluctionId={update?.id}
          getOrgData={getSolution}
          _id={id}
        />
      )}
      {openPop && (
        <AddSoluctionPop
          open={openPop}
          handleClosePop={handleClosePop}
          orgId={id}
          getSolution={getSolution}
        />
      )}
    </div>
  );
}

export default AddCatlog;
const tableHeads = [
  { id: 1, label: "Name", key: "Name", width: "100px" },
  { id: 2, label: "Email", key: "email", width: "100px" },
  { id: 3, label: "Date", key: "created", width: "500px" },
];

const tableBody = [
  {
    id: 1,
    name: (
      <div>
        <p className={styles.desc}> Name</p>

        <p className={styles.head}> Shriya Gupta</p>
      </div>
    ),
    Email: (
      <div>
        <p className={styles.desc}> Email</p>

        <p className={styles.head}> willie.jennings@example.com</p>
      </div>
    ),
    Date: (
      <div>
        <p className={styles.desc}> Date</p>

        <p className={styles.head}> 10/12/2016</p>
      </div>
    ),
  },
];
const tableHeads1 = [
  { id: 1, label: "Solution Name", key: "name", showIcon: "true" },
  {
    id: 2,
    label: "Solution Provider",
    key: "solutionprovider",
    showIcon: "true",
  },
  { id: 3, label: "Solution Type", key: "solutiontype" },
  { id: 4, label: "Start Date", key: "start_date" },
  { id: 5, label: "End Date", key: "end_date" },
  { id: 3, label: "Status", key: "statuses" },
];
