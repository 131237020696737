import React, { useEffect } from "react";
import useInput from "../../../../../hooks/useInput";
import InputText from "../../../../../Components/InputText/InputText";

function ControlledInput({ handleChange = () => {}, index, item }) {
  const text = useInput("", null);
  useEffect(() => {
    handleChange(text.value, index);
  }, [text.value]);
  useEffect(() => {
    text.setExternalValue(item.title);
  }, []);
  return <InputText {...text} />;
}

export default ControlledInput;
