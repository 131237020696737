import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function BasicSelect({
  size = "small",
  list = [],
  label = "",
  value = "title",
  choice = false,
  secValue = "title",
  onChange = () => {},
  name,
  value_id = null,
  item = null,
  error,
  helperText,
  orgSelect,
  ...props
}) {
  const [selectOption, setSelectOption] = React.useState("");
  const handleChange = (event) => {
    setSelectOption(event.target.value);
    let matchingObjects = list.filter((obj) => obj.id === event.target.value);
    onChange(event.target.value, name, matchingObjects, list, item);
  };
  React.useEffect(() => {
    if (value_id) {
      setSelectOption(value_id);
    }
  }, [value_id, list]);
  React.useEffect(() => {
    if (!orgSelect) {
      setSelectOption("");
    }
  }, [orgSelect]);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth size={size}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectOption}
          label={label}
          error={error}
          onChange={handleChange}
        >
          {list.flatMap((item, index) =>
            choice ? (
              item?.choices.map((item1, index1) => (
                <MenuItem key={item1.id} value={item1.id}>
                  {item1[secValue]}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={item.id} value={item.id}>
                {item[value]}
              </MenuItem>
            )
          )}
          {choice && (
            <MenuItem key={99} value={99}>
              Page 1
            </MenuItem>
          )}
        </Select>
        {error && <FormHelperText sx={{color:"#d32f2f"}}>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
