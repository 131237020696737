import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import up from "../../assets/componentAssest/up.png";
import { getApi } from "../../Utility/network";
import { color, width } from "@mui/system";
import { useNavigate } from "react-router-dom";

function Header({ data }) {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  return (
    <div className={styles.top}>
      {data?.map((item) => (
        <div
          key={item.id}
          className={styles.boxContainer}
          style={{ width: `${data.length === 4 ? 23 : 30}%` }}
          onClick={() => handleNavigation(item?.url)}
        >
          <p className={styles.name}>{item.name}</p>
          <div className={styles.divide}>
            <p
               className={item?.url1 ? styles.url : styles.value}
            
            >
              {item?.outOf ? (
                <>
                  {item?.value} <span className={styles.subname}>out of</span> {item?.outOf} <span>employees</span>
                </>
              ) : (
                item?.value
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Header;
