import React, { useContext, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ControlledButton from "../../../../Components/ControlledButton/ControlledButton";
import add from "../../../../assets/dashboard/add.svg";
import deleteIcon from "../../../../assets/dashboard/delete.svg";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";

function IntroductionType({ data, handleChange, ...props }) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const [load, setLoad] = useState(false);
  const [editorData, setEditorData] = useState("");
  useEffect(() => {
    if (load) return;
    setEditorData(data.title);
    setLoad(true);
  }, [data]);
  const handleEditorChange = (event, editor) => {
    const _data = editor.getData();
    setEditorData(_data); // Update the state with the editor content
  };
  const handleClick = async (_type) => {};

  useEffect(() => {
    if (!load) return;
    let quesListing = [...surveyQuestions?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      quesListing[index].title = editorData;
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  }, [editorData, load]);
  //   placeholder.value, instructions.value, checked
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("duplicate")}
        >
          <img src={add} style={{ marginRight: "10px" }} />{" "}
          <span>
            <b>Duplicate</b>
          </span>
        </ControlledButton>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("delete")}
        >
          <img src={deleteIcon} style={{ marginRight: "10px" }} />{" "}
          <span style={{ color: "grey" }}>
            <b>Delete</b>
          </span>
        </ControlledButton>
      </div>
      <div style={{ marginTop: "20px" }}>
        <p>
          <b>Instructions</b>
        </p>
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={handleEditorChange}
        />
        <style jsx>{`
          .ck-editor__editable {
            min-height: 400px; // Set the desired height
          }
        `}</style>
      </div>
    </>
  );
}

export default IntroductionType;
