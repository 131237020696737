import { capitalise } from "../../../Utility/helper";

// constant strings //
export const TEXT = "text";
// constant objects //

export const TEXTField = {
  name: capitalise(TEXT),
  type: TEXT,
  label: "",
  required: false,
  placeholder: capitalise(TEXT),
};

export const choice = {
  display: true,
  help_text: "lorem ipsum",
  index: null,
  title: " ",
};

export const imageObject = {
  question_type: null,
  help_text: "",
  title: "",
  placeholder: "",
  is_mandatory: false,
  is_multi: false,
  choices: [],
  index: null,
  display_grid: null,
  footer_banner: null,
  section_id: null,
  header_banner: null,
  image: null,
};

export const textObject = {
  question_type: null,
  help_text: "",
  placeholder: "",
  is_mandatory: false,
  index: null,
  is_multi: false,
  display_grid: null,
  choices: [],
  // id: null,
  type: "number",
  title: "h",
};

// export const imageObject = {
//   question_type: null,
//   help_text: "",
//   placeholder: "",
//   is_mandatory: false,
//   index: null,
//   is_multi: false,
//   display_grid: null,
//   choices: [],
//   // id: null,
//   title: "h",
// };

export const ratingObject = {
  question_type: null,
  help_text: "",
  placeholder: "",
  is_mandatory: false,
  index: null,
  is_multi: false,
  display_grid: null,
  choices: [],
  // id: null,
  title: "h",
};

export const multiObject = {
  question_type: null,
  help_text: "",
  placeholder: "",
  is_mandatory: false,
  index: null,
  is_multi: false,
  display_grid: null,
  choices: [],
  // id: null,
  title: "h",
};

export const ordering = {
  question_type: null,
  help_text: "",
  placeholder: "",
  is_mandatory: false,
  index: null,
  is_multi: false,
  display_grid: null,
  choices: [],
  // id: null,
  title: "h",
};

export const checkObject = (_object, questions) => {
  const indexing =
    questions.length > 0 ? questions[questions.length - 1].index + 1 : 1;
  switch (_object?.question_type) {
    case 1:
      return {
        ...imageObject,
        question_type: 1,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 2:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 15:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 3:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 7:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 8:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 10:
      return {
        ...imageObject,
        question_type: 10,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 9:
      return {
        ...ratingObject,
        question_type: 9,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
        left_label: "",
        right_label: "",
        central_label: "",
        rating_scale_start: 1,
        rating_scale_end: 5,
        scale_step: 5,
      };
    case 4:
      return {
        ...multiObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 5:
      return {
        ...multiObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 6:
      return {
        ...multiObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 14:
      return {
        ...ordering,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 13:
      return {
        ...imageObject,
        question_type: 13,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        // id: _object?.id,
      };
    case 11:
      return {
        ...textObject,
        question_type: _object?.question_type,
        help_text: "",
        placeholder: _object?.label,
        label: _object?.label,
        index: indexing,
        display_grid: 1,
        choice: null,
        question: [],
        section: null,
        operation: null,
        operator: null,
        // id: _object?.id,
      };
  }
};

export const generateRatingScale = (startNum, endNum, numStep) => {
  const startNumber = Number(startNum);
  const endNumber = Number(endNum);
  const numSteps = Number(numStep);
  const stepSize =
    (Number(endNumber) - Number(startNumber)) / (Number(numSteps) - 1);
  const ratingScale = [];
  if (!stepSize) return [];
  for (let i = 0; i < numSteps; i++) {
    let value = startNumber + stepSize * i;
    value = Number(value);
    ratingScale.push(value % 1 === 0 ? value.toFixed(0) : value.toFixed(1)); // Format to one decimal place
  }
  return ratingScale;
};

// const step = 5;
// const start = 1;
// const end = 10;

// function check() {
//   let arr = [];
//   let cal = end / step;
//   let each = cal;
//   while (each <= end) {
//     arr.push(each);
//     each = each + cal;
//   }
//   return arr;
// }

export const updateContext = () => {};

export const pageIncrementor = (string) => {
  let stringArr = string.split(" ");
  let newString =
    stringArr.length > 1
      ? `${stringArr[0]} ${Number(stringArr[1]) + 1}`
      : "Page 1";

  return newString;
};

export const tableHead = [
  {
    title: "Survey Name",
    id: 1,
    key: "",
  },
  {
    title: "Company Name",
    id: 2,
    key: "",
  },
  {
    title: "Created by",
    id: 3,
    key: "",
  },
  {
    title: "Status",
    id: 4,
    key: "",
  },
  {
    title: "Quick Actions",
    id: 5,
    key: "",
  },
];
