import Step1 from "./StepperComponent/Step1";
import Step3 from "./StepperComponent/Step3";
import Step2 from "./StepperComponent/Step2";
import Step4 from "./StepperComponent/Step4";
import Step5 from "./StepperComponent/Step5";
import { emailField, nameField, passwordField } from "../Utility/constants";
import { PatchApi, PostApi } from "../Utility/network";
import { apiCallsConfig } from "../Utility/apiCallsConfig";

export const initialState = {
  error: "",
  loggedin: false,
  loading: false,
  disabled: false,
};

export const stepsArray = [
  {
    id: 1,
    label: "Step 1",
    component: Step1,
    fields: [{ ...emailField, placeholder: "Enter your Email" }, passwordField],
    url: "step1",
    // showOnlyIf: (formData) => formData.agreedToTerms // Example condition
  },
  {
    id: 2,
    label: "Step 2",
    component: Step2,
    fields: [{ ...emailField, placeholder: "Enter Email" }],
    url: "step2",
  },
  {
    id: 3,
    label: "Step 3",
    component: Step3,
    fields: [],
    url: "step3",
  },
  {
    id: 4,
    label: "Step 4",
    component: Step4,
    fields: [
      { ...passwordField, name: "new_password", label: "New Password" },
      {
        ...passwordField,
        name: "confirm_password",
        label: "Confirm New Password",
      },
    ],
    url: "step4",
  },
  {
    id: 5,
    label: "Step 5",
    component: Step5,
    fields: [],
    url: "step4",
  },
];

export const validatePassword = (value) => {
  const status = {
    hasLowerCase: /[a-z]/.test(value),
    hasUpperCase: /[A-Z]/.test(value),
    hasNumber: /\d/.test(value),
    isValidLength: value.length >= 10,
    is7BitAscii: /^[ -~]+$/.test(value),
  };

  let error = "";
  if (!status.hasLowerCase)
    error = "Password must contain at least one lower case letter";
  else if (!status.hasUpperCase)
    error = "Password must contain at least one upper case letter";
  else if (!status.hasNumber)
    error = "Password must contain at least one number";
  else if (!status.isValidLength)
    error = `Password must be at least 10 characters long`;
  else if (!status.is7BitAscii)
    error = "Password must contain only 7-bit ASCII characters";

  return { error, status };
};

export const handleApiCall = async (
  callType,
  postData,
  successCallback,
  dispatch
) => {
  const { endpoint } = apiCallsConfig[callType];
  dispatch({ type: "SET_LOADING" });
  try {
    let data;
    if (callType === "resetPassword") {
      data = await PatchApi(endpoint, postData);
    } else {
      data = await PostApi(endpoint, postData);
    }
    if (data.data.error) {
      dispatch({ type: "SET_ERROR", payload: data.data.error.message });
    } else {
      if (typeof successCallback !== "function") {
        dispatch({ type: successCallback, payload: data.data });
      } else {
        dispatch({ type: "SET_LOADING" });
        successCallback();
      }
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: "SET_ERROR", payload: "Something went wrong" });
  }
};

// export const checkComponent = (step, field, dispatch) => {
//     let valid = false;
//     let errorMessage = '';
//     switch (step) {
//         case 1:
//             if (getLength(field, 'name', 'error') === 0 && // Check if length is valid (e.g., >= 0)
//                 getLength(field, 'password', 'error') === 0 &&
//                 getLength(field, 'name', 'value') >= 3 &&
//                 getLength(field, 'password', 'value') >= 3) {
//                 valid = true;
//             }
//             break;
//         case 2:
//             if (getLength(field, 'email', 'error') === 0 && getLength(field, 'email', 'value') > 0) {
//                 valid = true
//             }
//             break;

//     }
//     if (valid) {
//         dispatch({ type: 'SET_CONDITION', payload: false })
//     } else {
//         dispatch({ type: 'SET_CONDITION', payload: true })
//     }
// }

// const getLength = (field, name, value) => {
//     return field[name][value].length ? field[name][value].length : 0
// }
// src/components/PasswordField.js
// import React from 'react';
// import './PasswordField.css';

// const PasswordField = ({ label, value, onChange, error, validationStatus }) => {
//   return (
//     <div className="input-field">
//       {label && <label>{label}</label>}
//       <input type="password" value={value} onChange={onChange} />
//       {error && <span className="error">{error}</span>}
//       <div className="validation-requirements">
//         <p style={{ color: validationStatus.hasLowerCase ? 'green' : 'red' }}>
//           • Lower case alpha (a – z)
//         </p>
//         <p style={{ color: validationStatus.hasUpperCase ? 'green' : 'red' }}>
//           • Upper case alpha (A – Z)
//         </p>
//         <p style={{ color: validationStatus.hasNumber ? 'green' : 'red' }}>
//           • Number (0 – 9)
//         </p>
//         <p style={{ color: validationStatus.isValidLength ? 'green' : 'red' }}>
//           • Minimum length of 10
//         </p>
//         <p style={{ color: validationStatus.is7BitAscii ? 'green' : 'red' }}>
//           • Only 7-bit ASCII characters
//         </p>
//       </div>
//     </div>
//   );
// };

// export default PasswordField;
