import React, { useContext, useEffect, useState } from "react";
import gitIcon from "../../../../assets/survery/git_request_icon.png";
import styles from "../CreateSurvey.module.css";
import BasicSelect from "../../../../Components/BasicSelect/BasicSelect";
import zap from "../../../../assets/survery/zap.png";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";

function ConditionalType({ data, ...props }) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const [types, setTypes] = useState({
    choice: null,
    question: [],
    section: null,
    operation: null,
    operator: null,
  });
  const handleChange = (value, name) => {
    if (name === "question")
      return setTypes((prev) => ({ ...prev, question: [value] }));
    setTypes((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    let quesListing = [...surveyQuestions?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      quesListing[index].choice = types.choice;
      quesListing[index].question = types.question;
      quesListing[index].section = types.section;
      quesListing[index].operation = types.operation;
      quesListing[index].operator = types.operator;
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  }, [types]);

  useEffect(() => {
    setTypes({
      ...types,
      choice: data.choice,
      question: data.question,
      operation: data.operation,
      operator: data.operator,
    });
  }, [data]);

  return (
    <div>
      <div>
        <div className={styles.inputDisplay}>
          <img src={gitIcon} style={{ width: "20px" }} />
          <p>When</p>
          <div className={styles.selectD}>
            <BasicSelect
              list={whenListing}
              name="list"
              value="title"
              onChange={handleChange}
              value_id={1}
            />
          </div>
          <div className={styles.selectD}>
            <BasicSelect
              list={whenOperator}
              name="operator"
              value="title"
              onChange={handleChange}
              value_id={types.operator}
            />
          </div>
          <div className={styles.selectD}>
            <BasicSelect
              list={surveyQuestions.questions}
              name="choice"
              value="placeholder"
              onChange={handleChange}
              value_id={types.choice}
              choice={true}
            />
          </div>
        </div>
        <div className={styles.inputDisplay}>
          <img src={zap} style={{ width: "20px" }} />
          <p>Then</p>
          <div className={styles.selectD}>
            <BasicSelect
              list={operation}
              name="operation"
              value="title"
              onChange={handleChange}
              value_id={types.operation}
            />
          </div>
          <div className={styles.selectD}>
            <BasicSelect
              list={surveyQuestions?.questions}
              name="question"
              value="placeholder"
              onChange={handleChange}
              value_id={types.question.length > 0 ? types.question[0] : null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConditionalType;

const whenListing = [
  {
    title: "Select one option",
    id: 1,
  },
];

const whenOperator = [
  {
    title: "Is",
    id: 1,
  },
  {
    title: "Is Not",
    id: 2,
  },
];

const operation = [
  {
    title: "Show Block",
    id: 1,
  },
  {
    title: "Show Section",
    id: 2,
  },
  {
    title: "Hide Block",
    id: 3,
  },
];
