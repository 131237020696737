import React, { useEffect, useState } from "react";
import AddSolution from "../../Components/AddSolution/AddSolution";
import PageHeader from "../../Components/PageHeader/PageHeader";
import deleteIcon from "../../assets/componentAssest/deleteIcon.png";
import { DeleteApi, getApi, PostApi } from "../../Utility/network";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import SelectStatus from "../../Components/SelectStatus/SelectStatus";
import styles from "./SoluctionDetails.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTable from "../CustomTable/CustomTable";
import RecommendedPopUP from "../../Pages/Dashboard/RecommendedPopUP/RecommendedPopUP";
import Header from "../Header/Header";
import dummy from "../../assets/componentAssest/dummy.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function SoluctionDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createOrg, setCreateOrg] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const [update, setUpdate] = useState();
  const [open, setOpen] = useState(false);
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [video, setVideo] = useState();
  const [selectDataId, setSelectDatatId] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [top, setTop] = useState();
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [dropDown, setDropDown] = useState({
    type: [],
  });
  const [statusValue, setStatusValue] = useState();
  const [selected, setSelected] = useState({
    selectedType: "",
  });
  const location = useLocation();
  const name = location.state?.name;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setCreateOrg(true);
  };
  const handleClickClose = () => {
    setCreateOrg(false);
    setUpdate();
  };
  useEffect(() => {
    getTop(id);
  }, []);

  const getOrgData = async (id, filter) => {
    setLoading(true);

    try {
      const getResponse = await getApi(
        `survey/recommended_solutions/${id}/assigned_list`,
        filter
      );
      const modifyArray = getResponse?.data?.map((item) => {
        let statusStyle;

        switch (item.status_str) {
          case "Active":
            statusStyle = styles.active;
            break;
          case "Paused":
            statusStyle = styles.paused;
            break;
          case "Withdrawn":
            statusStyle = styles.withdrawn;
            break;
          case "Inactive":
            statusStyle = styles.Inactive;
            break;
          default:
            statusStyle = styles.default;
        }

        return {
          ...item,
          Organisationtitle: (
            <div className={styles.imgCon}>
              {/* <p className={styles.img}> */}
                {/* <img
                  src={
                    item?.organisation?.logo ? item?.organisation?.logo : dummy
                  }
                /> */}
                <div
                  style={{
                    background: `url(${
                      item?.organisation?.logo
                        ? item?.organisation?.logo
                        : dummy
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                ></div>
              {/* </p> */}
              <div>
                <p className={styles.head}>{item?.organisation?.title}</p>
                <p className={styles.desc}>{item?.organisation?.website}</p>
              </div>
            </div>
          ),
          SolutionProvider: (
            <p className={styles.head}>{item?.solution?.provider?.title}</p>
          ),
          solutionTypes: (
            <p className={styles.head}>
              {item?.solution?.solution_type?.title}
            </p>
          ),
          PoC: (
            <div>
              <p className={styles.head}>{item?.solution?.poc_name}</p>
              <p className={styles.desc}>{item?.solution?.poc_email}</p>
            </div>
          ),
          status: <p className={statusStyle}>{item?.status_str}</p>,
        };
      });

      setOrgData(modifyArray);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;

      getOrgData(id, finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [, pageState]);

  const handleEdit = (data) => {
    handleClick();
    setUpdate(data);
  };
  const videoData = (data) => {
    handleOpen();
    setVideo(data?.solution);
  };

  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
    // getDelete(id);
  };
  const handlepopUpclose = () => {
    setdeleteData(false);
    setSelectedIds([]);
  };
  const getDelete = async (id) => {};
  //   const getSoluctionData = async () => {
  //     try {
  //       const getResponse = await getApi(`survey/solution_type/`);
  //       console.log(getResponse?.data, "soluction");
  //       setDropDown({ type: getResponse?.data });
  //     } catch (error) {
  //       console.error("Error fetching contacts:", error);
  //     }
  //   };
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};

      if (selected.selectedType) {
        finalFilters.selectedType = selected?.selectedType;
      }

      // getTopCards(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
  }, [selected]);

  //   const handleBulkDelete = async () => {
  //     if (!selectDataId || selectDataId.length === 0) return;

  //     const ids = { solution_ids: selectDataId };
  //     console.log(ids, "org_ids");

  //     try {
  //       const getResponse = await PostApi(
  //         `/survey/recommended_solutions/bulk_delete/`,
  //         ids
  //       );

  //       //   getOrgData();
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const popOpen = () => {
    setdeleteData(true);
  };
  const getTop = async (id) => {
    try {
      const getResponse = await getApi(
        `/survey/recommended_solutions/${id}/top_cards/`
      );
      setTop(getResponse?.data);
      // setUpdate(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const TopCarddata = [
    {
      id: 1,
      name: "SOLUTION RECOMMENDED TO",
      value: top?.recommended_organisation_count,
    },
    {
      id: 2,
      name: "No of ORGANISATIONS - ACTIVE",
      value: top?.no_of_active_organisation,
    },
    // {
    //   id: 3,
    //   name: "No of employees IMPACTED",
    //   // value: top?.num_respondents,
    // },
  ];
  const handleNavigate = () => {
    navigate("/solution-hub-management");
  };
  return (
    <div>
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div onClick={handleNavigate}>
            <ArrowBackIcon style={{ cursor: "pointer" }} />
          </div>
          <p className={styles.head}>Solution Hub Management</p>
        </div>
        <h3 className={styles.pageHeading}>{name}</h3>
        <Header data={TopCarddata} />
        <div style={{ marginTop: "10px" }}>
          <CustomTable
            tableBody={orgData}
            tableHeads={tableHeads}
            //   headerCheck={true}
            // ThreeActions={true}
            //   handleEdit={handleEdit}
            videoData={videoData}
            handleDelete={handleDelete}
            //   setSelectDatatId={setSelectDatatId}
            //   handlepopUpclose={handlepopUpclose}
            //   selectedIds={selectedIds}
            //   setSelectedIds={setSelectedIds}
            loading={loading}
            soluctionInnner={true}
            max_pages={max_pages}
            setPageState={setPageState}
          />
        </div>

        {/* <RecommendedPopUP data={video} open={open} handleClose={handleClose} /> */}
        <RecommendedPopUP data={video} open={open} handleClose={handleClose} />
      </div>

      {createOrg && (
        <AddSolution
          handleClickClose={handleClickClose}
          open={createOrg}
          update={update}
          soluctionId={update?.id}
          //   getOrgData={getOrgData}
        />
      )}
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          //   handleBulkDelete={handleBulkDelete}
          selectDataId={selectDataId}
        />
      )}
    </div>
  );
}

export default SoluctionDetails;

const tableHeads = [
  {
    id: 1,
    label: "Organisation Name",
    key: "Organisationtitle",
    showIcon: "true",
  },
  {
    id: 2,
    label: "Solution Provider",
    key: "SolutionProvider",
    showIcon: "true",
  },
  { id: 3, label: "Solution Type", key: "solutionTypes" },
  { id: 4, label: "Point of Contact", key: "PoC" },
  { id: 5, label: "Status", key: "status" },
];

// const tableBody = [
//   {
//     id: 1,
//     SolutionName: <p className={styles.head}>Wealth Creation Workshop</p>,
//     SolutionProvider: <p className={styles.head}>Sama</p>,
//     SolutionType: <p className={styles.head}>L&D</p>,
//     PointofContact: (
//       <div>
//         <p className={styles.head}>Eleanor Pena</p>
//         <p className={styles.desc}>nathan.roberts@example.com</p>
//       </div>
//     ),
//     Status: "Paused",
//   },
// ];
