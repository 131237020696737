import React, { useContext, useEffect, useState } from "react";
import PublishSurveyHelpus from "../../../Components/PublishSurveyHelpus/PublishSurveyHelpus";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./PublishSurvey.module.css";
import { Button } from "@mui/material";
import { getApi, PostApi } from "../../../Utility/network";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";

function PublishSurvey(props) {
  const navigate = useNavigate();
  const { setContext, contextState, token } = useContext(AppContext);

  const { surveyId } = useParams();
  const [status, setStatus] = useState();
  const getSurveyStatus = async () => {
    try {
      const postResponse = await getApi(`/survey/${surveyId}/basic_details/`);
      setStatus(postResponse?.data?.published);
    } catch (error) {}
  };
  const publishSurvey = async () => {
    try {
      const postResponse = await PostApi(`/survey/${surveyId}/publish/`);

      getSurveyStatus();
      setContext((prev) => ({
        ...prev,
        snackbar: {
          open: true,
          message: "Survey published Successfully",
          severity: "success",
        },
      }));
    } catch (error) {}
  };
  useEffect(() => {
    getSurveyStatus();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.headers}>
        <p>Survey Preview</p>
        <div className={styles.cross}>{/* <ClearIcon /> */}</div>
      </div>
      <PublishSurveyHelpus />
      <div className={styles.buttons}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#4DD0E1" }}
          disabled={status}
          onClick={publishSurvey}
        >
          Publish survey
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/survey-management/${surveyId}`)}
        >
          Close
        </Button>
      </div>
    </div>
  );
}

export default PublishSurvey;
