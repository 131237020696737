import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./EditProfile.module.css";
import cross from "../../assets/componentAssest/cross.png";
import pensil from "../../assets/componentAssest/pensil.png";
import deleteImg from "../../assets/componentAssest/deleteImg.png";

// import profileImg1 from "../../assets/componentAssest/profileImg1.png";

import { Button, Dialog, MenuItem, Select, TextField } from "@mui/material";
import { baseURL, getApi, PatchApi } from "../../Utility/network";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
function EditProfile({
  open,
  handleClose,
  id,
  profile,
  drop,
  setDrop,
  dropDown,
  useProfileId,
  getImage = () => {},
  img = "",
  getProfileDataprev = () => {},
}) {
  const { token } = useContext(AppContext);
  const [image, setImage] = useState("");
  const [edit, setEdit] = useState();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadeImgFile, setUploadImgFile] = useState("");

  // const [uploadFile, setUploadFile] = useState({
  //   file: "",
  // });
  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    title: "",
    designation: "",
    date_joined: "",
    bio: "",
    avatar: "",
  });
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });

  const styletags = {
    width: "100%",
  };

  const getProfileData = async (id) => {
    if (!id) return;
    const data = {
      first_name: fields?.first_name,
      last_name: fields?.last_name,
      email: fields?.email,
      phone: fields?.phone,
      profile: {
        bio: fields?.bio,
        designation_id: drop?.designation,
        // avatar: fields?.avatar,
        // position_level: fields?.designation,
      },
    };

    try {
      const getResponse = await PatchApi(`auth/user/${id}/`, data);
      setEdit(getResponse.data);
      getProfileDataprev();
    } catch (error) {
      console.log(error, "error finding");
    }
  };

  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const clearState = () => {
    setFields({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      designation: "",
      bio: "",
    });
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setDrop((prevState) => ({
      ...prevState,
      designation: value,
    }));
  };

  useEffect(() => {
    if (profile) {
      setFields({
        first_name: profile?.first_name || "",
        last_name: profile?.last_name || "",
        email: profile.email || "",
        phone: profile.phone || "",
        title: profile.profile?.organisation?.title || "",

        date_joined: profile.date_joined || "",
        bio: profile?.profile?.bio || "",
        avatar: profile?.profile?.avatar || "",
      });
      setDrop({ designation: profile?.profile?.designation?.id || "" });
    }
  }, [profile]);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleDelete = () => {};

  useEffect(() => {
    if (uploadeImgFile) {
      const formData = new FormData();
      formData.append("avatar", uploadeImgFile);
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token.access}`,
        },
      };
      axios
        .patch(
          `${baseURL}/profile/user_profile/${useProfileId}/`,
          formData,
          axiosConfig
        )
        .then((response) => {

          setImageUrl({ id: response.data.id, image: response.data.image });

          getProfileDataprev();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [uploadeImgFile]);
  useEffect(() => {
    setImage(img);
  }, [img]);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      getImage(event.target.files[0]);
      setUploadImgFile(event.target.files[0]);
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
    setImage("");
  };
  const handleClickClose = () => {
    clearState();
    handleClose();
  };
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
      <div className={styles.container}>
        <div className={styles.top}>
          <h3 className={styles.heading}>Edit Profile</h3>
          <img
            src={cross}
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className={styles.profile}>
          <div className={styles.profileImg1}>
            <img src={fields?.avatar} />
          </div>
          <div className={styles.icons}>
            <div onClick={handleClick}>
              <div>
                <img src={pensil} className={styles.pencil} />

                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={(e) => handleImageChange(e, "userFile")}
                />
              </div>
            </div>
            <div>
              <img
                src={deleteImg}
                className={styles.delete}
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>

        <div className={styles.about}>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>First Name</p>
            <TextField
              type="text"
              value={fields?.first_name}
              size="small"
              sx={styletags}
              name="first_name"
              onChange={handleChangeINput}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Last Name</p>
            <TextField
              type="text"
              value={fields?.last_name}
              size="small"
              sx={styletags}
              name="last_name"
              onChange={handleChangeINput}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Email</p>
            <TextField
              type="text"
              size="small"
              value={fields?.email}
              sx={styletags}
              name="email"
              onChange={handleChangeINput}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Mobile Number</p>
            <TextField
              type="text"
              size="small"
              value={fields?.phone}
              sx={styletags}
              name="phone"
              onChange={handleChangeINput}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Company Name </p>
            <TextField
              type="text"
              size="small"
              value={fields?.title}
              sx={styletags}
              name="company"
              // onChange={handleChangeINput}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Designation</p>
            {/* <TextField
              type="text"
              size="small"
              value={fields?.designation}
              sx={styletags}
              name="designation"
              onChange={handleChangeINput}
            /> */}
            <Select
              value={drop?.designation || ""}
              onChange={handleChange}
              sx={{ width: "100%" }}
              size="small"
              name="location"
            >
              {dropDown?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.labels}>Joining Date</p>
            <TextField
              type="text"
              size="small"
              value={formatDate(fields?.date_joined)}
              sx={styletags}
              name="date_joined"
              disabled
              // onChange={handleChangeINput}
            />
          </div>
        </div>
        <p className={styles.labels}>Bio</p>
        {/* <div className={styles.desc}> */}
        <textarea
          style={{ width: "95%" }}
          onChange={handleChangeINput}
          name="bio"
          value={fields?.bio}
        ></textarea>
        {/* <p className={styles.labels} >{fields?.bio}</p> */}
        {/* </div> */}
        <div className={styles.buttons}>
          <Button
            variant="contained"
            onClick={() => {
              getProfileData(id);
              handleClickClose();
            }}
          >
            save
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default EditProfile;
