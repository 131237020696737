import React, { useEffect, useState } from "react";
import styles from "./ReleasedData.module.css";
import cross from "../../assets/survery/cross.png";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";

function ReleasedData({
  pocId,
  open,
  handlepopUpclose = () => {},
  deleteId,
  getDelete = () => {},
  handleBulkDelete = () => {},

  deleteSol,
}) {
  const [randomNo, setRandomNo] = useState(null);
  const [dataField, setDataField] = useState({
    value: "",
  });
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
  };
  useEffect(() => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    setRandomNo(randomNumber);
  }, []);

  return (
    <Dialog
      onClose={handlepopUpclose}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <div className={styles.Confirm}>
        <div className={styles.head}>
          <h3 className={styles.heading}>Confirm</h3>
          <div style={{ cursor: "pointer" }} onClick={handlepopUpclose}>
            <img src={cross} alt="close"></img>
          </div>
        </div>
        <div className={styles.desc}>
          <p>Are you sure you need to want to release “Sama Survey”?</p>
          <p className={styles.red}>
            Once survey is released for the organisation, the data will be
            available in HR SPOC Panel and this cannot be undone.
          </p>
        </div>

        <div>
          <p>Type {randomNo} to Confirm</p>
          <TextField
            size="small"
            style={{ width: "100%" }}
            onChange={handleChangeINput}
            name="value"
            value={dataField?.value}
          />
        </div>
        <div className={styles.buttons}>
          <button
            onClick={() => {
              if (dataField?.value == randomNo) {
                getDelete(deleteId);
                handlepopUpclose();
              } else {
                alert("Please enter the correct value (4175) to confirm.");
              }
            }}
          >
            Yes
          </button>

          <button
            onClick={() => {
              handlepopUpclose();
            }}
            style={{ cursor: "pointer" }}
          >
            No
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ReleasedData;
