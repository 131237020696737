import React from "react";
import styles from "./Setting.module.css";
import TabList from "@mui/lab/TabList";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Profile from "../../Components/Profile copy/Profile";
import ChangePassword from "../../Components/ChangePassword/ChangePassword";
function Settings() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabStyle = {
    fontSize: "20px",
    fontWeight: "600",
  };
  return (
    <div className={styles.setting}>
      <h3 className={styles.head}>Settings</h3>

      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Profile" value="1" style={tabStyle} />
          <Tab label="Change Password" value="2" style={tabStyle} />
        </TabList>

        <TabPanel value="1">
          <Profile />
        </TabPanel>

        <TabPanel value="2">
          <ChangePassword />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Settings;
const headers = [
  {
    id: 1,
    name: "Profile",
  },

  {
    id: 2,
    name: "Change Password",
  },

];
