import React, { useEffect, useState } from "react";
import styles from "./PageHeader.module.css";
import { Button, MenuItem, Select } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getApi } from "../../Utility/network";

function PageHeader({
  heading,
  org = false,
  buttonName,
  addInfo,
  buttonIcon,
  dropDown,
  selected,
  setSelected,
  handleClick,
  selectDataId,
  getPdf = () => {},
  popOpen = () => {},
  buttonName1,
  buttonIcon1,
}) {
  const calculatePastDate = (days) => {
    let today = new Date();
    let pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);
    return { startDate: pastDate, endDate: today };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let today = new Date();
    let pastDate;

    if (name === "selectedDays") {
      if (value === 1) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
      } else if (value === 2) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 30);
      } else if (value === 3) {
        pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 90);
      } else if (value === 4) {
        pastDate = null; // For 'All', don't set any date range
      }
    }

    setSelected({
      ...selected,
      [name]: value,
      startDate: pastDate || selected.startDate,
      endDate: value === 4 ? null : today,
    });
  };
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setSelected({
      ...selected,
      [name]: value,
    });
  };

  return (
    <div className={styles.headings}>
      <p className={styles.pageHeading}>{heading}</p>
      {buttonIcon && selectDataId?.length > 0 &&!org&& (
        <Button
          onClick={() => {
            getPdf();
            popOpen();
          }}
          variant="contained"
          startIcon={<img src={buttonIcon} alt="icon" />}
        >
          {buttonName}
        </Button>
      )}
      {buttonIcon && selectDataId?.length > 0 && org && (
        <Button
          onClick={() => {
            getPdf();
            // popOpen();
          }}
          variant="contained"
          startIcon={<img src={buttonIcon} alt="icon" />}
        >
          {buttonName}
        </Button>
      )}
      {buttonIcon && selectDataId?.length > 0 && org && (
        <Button
          onClick={() => {
            popOpen();
          }}
          variant="contained"
          startIcon={<img src={buttonIcon1} alt="icon" />}
        >
          {buttonName1}
        </Button>
      )}
      <div className={styles.right}>
        {dropDown?.verify && (
          <Select
            className={styles.filtering}
            size="small"
            name="selectedVerify"
            value={selected?.selectedVerify || "All"}
            onChange={handleChange}
          >
            {dropDown.verify.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {dropDown?.days && (
          <Select
            className={styles.filtering}
            size="small"
            name="selectedDays"
            value={selected?.selectedDays || 4}
            onChange={handleChange}
          >
            {dropDown.days.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {dropDown?.type && (
          <Select
            style={{ width: "200px" }}
            size="small"
            name="selectedType"
            value={selected?.selectedType}
            onChange={handleChangeINput}
          >
            {dropDown.type.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        )}
        {addInfo && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {addInfo}
          </Button>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
