import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import ControlledButton from "../../../../Components/ControlledButton/ControlledButton";
import ControlledSwitches from "../../../../Components/ControlledFields/ControlledSwitches";
import add from "../../../../assets/dashboard/add.svg";
import deleteIcon from "../../../../assets/dashboard/delete.svg";
import useInput from "../../../../hooks/useInput";
import { TEXTField, choice } from "../utility";
import InputText from "../../../../Components/InputText/InputText";
import ControlledInput from "../Components/ControlledInput/ControlledInput";
import { RED, REDBG } from "../../../../Utility/constants";
import styles from "../CreateSurvey.module.css";

function MultipleChoiceType({ data, ...props }) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const placeholder = useInput(data?.placeholder, null, {
    ...TEXTField,
    placeholder: data?.placeholder,
  });
  const instructions = useInput(data?.help_text, null, {
    ...TEXTField,
    placeholder: "Sub heading",
  });
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    // label.setExternalValue(data.label);
    // instructions.setExternalValue(data.help_text);
    setChecked(data.is_mandatory);
  }, [
    // data.label,
    // data.help_text,
    data.is_mandatory,
  ]);
  useEffect(() => {
    let quesListing = [...surveyQuestions?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      quesListing[index].help_text = instructions.value;
      quesListing[index].placeholder = placeholder.value;
      quesListing[index].is_mandatory = checked;
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  }, [placeholder.value, instructions.value, checked]);
  const handleClick = (_type) => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      if (_type === "duplicate") {
        let newIndex = { ...quesListing[index] };
        quesListing.push({ ...newIndex, index: quesListing.length + 1 });
      } else {
        quesListing.splice(index, 1);
      }
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  const addChoices = () => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      let newIndex = { ...quesListing[index] };
      let choices = { ...choice, index: newIndex.choices.length + 1 };
      newIndex.choices.push(choices);
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  const handleChange = (value, indexs) => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      let newIndex = { ...quesListing[index] };
      newIndex.choices[indexs].title = value;
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  const handleDelete = (items, indexs) => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      let newIndex = { ...quesListing[index] };
      newIndex.choices.splice(indexs, 1);
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("duplicate")}
        >
          <img src={add} style={{ marginRight: "10px" }} />{" "}
          <span>
            <b>Duplicate</b>
          </span>
        </ControlledButton>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("delete")}
        >
          <img src={deleteIcon} style={{ marginRight: "10px" }} />{" "}
          <span style={{ color: "grey" }}>
            <b>Delete</b>
          </span>
        </ControlledButton>
      </div>
      <p>
        <b>Label</b>
      </p>
      <InputText {...placeholder} />
      <p>
        <b>Instructions</b>
      </p>
      <InputText {...instructions} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>Required</b>
        </p>
        <ControlledSwitches checked={checked} setChecked={setChecked} />
      </div>{" "}
      <div>
        <p>
          <b>Edit Choices</b>
        </p>
        {data?.choices?.map((item, index) => {
          return (
            <div className={styles.editChoiceContainer} key={index}>
              <div className={styles.inputD}>
                <ControlledInput
                  index={index}
                  handleChange={handleChange}
                  item={item}
                />
              </div>
              <div
                onClick={() => handleDelete(item, index)}
                className={styles.buttonD}
                style={{ background: REDBG }}
              >
                <div className={styles.divMinus} style={{ background: RED }}>
                  &nbsp;
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <ControlledButton
            variant="contained"
            style={{ width: "100%", textTransform: "uppercase" }}
            onClick={addChoices}
          >
            <b>Add Choice</b>
          </ControlledButton>
        </div>
      </div>
    </div>
  );
}

export default MultipleChoiceType;
