import React, { useEffect, useState } from "react";
import AddUser from "../../Components/AddUser/AddUser";
import CustomTable from "../../Components/CustomTable/CustomTable";
import styles from "./UserManagement.module.css";
import profileTable from "../../assets/componentAssest/profileTable.png";
import catlog from "../../assets/componentAssest/catlog.png";
import PageHeader from "../../Components/PageHeader/PageHeader";
import deleteIcon from "../../assets/componentAssest/deleteIcon.png";
import AproveUser from "../../Components/AproveUser/AproveUser";
import checkIcon from "../../assets/componentAssest/checkIcon.png";
import { Button, CircularProgress } from "@mui/material";
import crossIcon from "../../assets/componentAssest/crossIcon.png";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import UpdateOrganization from "../../Components/UpdateOrganization/UpdateOrganization";
import userManagement from "../../assets/componentAssest/userManagement.png";
import { DeleteApi, getApi, PostApi } from "../../Utility/network";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import dummy from "../../assets/componentAssest/dummy.svg";
function UserManagement() {
  const [confirm, setConfirm] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const [createOrg, setCreateOrg] = useState(false);
  const [userData, setUserData] = useState();
  const [update, setUpdate] = useState();
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectDataId, setSelectDatatId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [loading, setLoading] = useState(false);
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [dropDown, setDropDown] = useState({
    days: [
      { id: 1, name: "Last 1 week" },
      { id: 2, name: "Last 30 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "All" },
    ],
  });
  const [selected, setSelected] = useState({
    startDate: "",
    endDate: "",
    selectedDays: 4,
  });

  const handleClose = () => {
    setConfirm(false);
  };
  const handleOpen = (key, id) => {
    setConfirm(true);
    setPopUp(key);
    getAprrove(id);
  };
  const handleOpenReject = (key, id) => {
    getReject(id);
    setConfirm(true);
    setPopUp(key);
  };
  setTimeout(() => {
    setConfirm(false);
  }, 5000);

  const handleClick = () => {
    setCreateOrg(true);
    setUpdate();
  };
  const handleClickClose = () => {
    setCreateOrg(false);
  };
  const getTableData = async (filter) => {
    setLoading(true);
    try {
      const getResponse = await getApi(`/dashboard/admin/user/`, filter);
      setPaginationData(getResponse?.data);
      const modifyArray = getResponse?.data?.data.map((item) => ({
        ...item,

        name: (
          <div className={styles.imgCon}>
            <p className={styles.img}>
              {/* <img
                src={item?.profile?.avatar ? item?.profile?.avatar : dummy}
              /> */}
              <div
                style={{
                  background: `url(${
                    item?.profile?.avatar ? item?.profile?.avatar : dummy
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50px",
                  backgroundPosition: "center",
                }}
              ></div>
            </p>
            <div>
              <p className={styles.head}>
                {item?.first_name} {item?.last_name}
              </p>
              <p className={styles.desc}>{item?.email}</p>
            </div>
          </div>
        ),
        Designation: (
          <p className={styles.head}>{item?.profile?.designation?.title}</p>
        ),
        Company: (
          <div className={styles.imgCon}>
            <p className={styles.img}>
              {/* <img
                src={
                  item?.profile?.organisation?.logo
                    ? item?.profile?.organisation?.logo
                    : dummy
                }
              /> */}
              <div
                style={{
                  background: `url(${
                    item?.profile?.organisation?.logo
                      ? item?.profile?.organisation?.logo
                      : dummy
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50px",
                }}
              ></div>
            </p>
            <div>
              <p className={styles.head}>
                {item?.profile?.organisation?.title}
              </p>
              {/* <p className={styles.desc}>catalogapp.io</p>   */}
            </div>
          </div>
        ),
        LastLoggedIn: (
          <div>
            <p className={styles.desc}>{dateTime(item?.last_login)}</p>
            {/* <p className={styles.time}>10:20AM</p> */}
          </div>
        ),
      }));
      setLoading(false);

      setUserData(modifyArray);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const dateTime = (item) => {
    if (!item) return null;

    const dateTime = new Date(item);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = dateTime.toLocaleDateString("en-US", options);

    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return (
      <div>
        <p className={styles.desc}>{formattedDate}</p>
        <p className={styles.time}>{formattedTime}</p>
      </div>
    );
  };

  const handleEdit = (data) => {
    handleClick();
    setUpdate(data?.id);
  };
  // useEffect(() => {
  //   getTableData();
  // }, []);

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};

      if (selected.startDate && selected.endDate) {
        finalFilters.start_date = formatDateFilter(selected.startDate);
        finalFilters.end_date = formatDateFilter(selected.endDate);
      }

      // getContactData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selected]);
  const formatDateFilter = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getAprrove = async (id) => {
    try {
      const getResponse = await PostApi(`dashboard/admin/user/${id}/approve/`);
      // getUserList();
      // setApprove(modifyArray);
      getTableData();
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getReject = async (id) => {
    try {
      const getResponse = await PostApi(`dashboard/admin/user/${id}/reject/`);
      getTableData();
      // getUserList();
      // setApprove(modifyArray);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
    // getDelete(id);
  };
  const handlepopUpclose = () => {
    setdeleteData(false);
    setSelectedIds([]);
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`dashboard/admin/user/${id}/`);
      getTableData();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;
      if (selected.startDate && selected.endDate) {
        finalFilters.start_date = formatDateFilter(selected.startDate);
        finalFilters.end_date = formatDateFilter(selected.endDate);
      }

      getTableData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selected, pageState]);

  const handleBulkDelete = async () => {
    const ids = { user_ids: selectDataId };

    try {
      const getResponse = await PostApi(
        `/dashboard/admin/user/bulk_delete/`,
        ids
      );
      getTableData();
    } catch (error) {
      console.error(error);
    }
  };
  const popOpen = () => {
    setdeleteData(true);
  };
  return (
    <div className={styles.pageCon}>
      {userData && userData.length > 0 && loading === false ? (
        <div>
          <PageHeader
            heading={"User Management"}
            buttonIcon={deleteIcon}
            buttonName={"Delete"}
            addInfo={"Add New User"}
            handleClick={handleClick}
            dropDown={dropDown}
            selected={selected}
            setSelected={setSelected}
            popOpen={popOpen}
            selectDataId={selectDataId}
          />
          <div style={{ marginTop: "10px" }}>
            <CustomTable
              tableBody={userData}
              tableHeads={tableHeads}
              FourActions={true}
              headerCheck={true}
              handleOpen={handleOpen}
              handleEdit={handleEdit}
              handleOpenReject={handleOpenReject}
              handleDelete={handleDelete}
              setSelectDatatId={setSelectDatatId}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              loading={loading}
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : userData?.length == 0 && loading === false ? (
        <EmptyPage
          heading={"User Management"}
          desc={`Looks like you don't have users yet... Add your first one now.`}
          buttonName={"Add New User"}
          pageImage={userManagement}
          handleClick={handleClick}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}

      {confirm && popUp === 1 && (
        <AproveUser
          icon={checkIcon}
          heading={"User Approved"}
          secPara={
            "User has been approved for this Company and email has been sent to them"
          }
          bgColor={"#d2f1e3"}
          handleClose={handleClose}
          imgBG={"#12B76A"}
        />
      )}
      {confirm && popUp === 2 && (
        <AproveUser
          icon={crossIcon}
          heading={"User Rejected"}
          secPara={"Unable to verify at this time"}
          bgColor={"#ffe2e2"}
          handleClose={handleClose}
          imgBG={"#ff0000"}
        />
      )}
      {createOrg && (
        <AddUser
          handleEdit={handleEdit}
          handleOpenReject={handleOpenReject}
          handleClickClose={handleClickClose}
          handleApprove={handleOpen}
          open={createOrg}
          userId={update}
          getTableData={getTableData}
        />
      )}
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          handleBulkDelete={handleBulkDelete}
          selectDataId={selectDataId}
        />
      )}
      {/* {!userData && ( */}

      {/* )} */}
    </div>
  );
}

export default UserManagement;

const tableHeads = [
  {
    id: 1,
    label: "User Name",
    key: "name",
    showIcon: "true",
  },
  {
    id: 2,
    label: "Designation",
    key: "Designation",
  },
  {
    id: 3,
    label: "Company",
    key: "Company",
  },
  {
    id: 4,
    label: "Last Logged In",
    key: "LastLoggedIn",
  },
];

const tableBody = [
  {
    id: 1,
    UserName: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          <img src={profileTable} />
        </p>
        <div>
          <p className={styles.head}>Savannah Nguyen</p>
          <p className={styles.desc}>nathan.roberts@example.com</p>
        </div>
      </div>
    ),
    Designation: <p className={styles.head}>President</p>,
    Company: (
      <div className={styles.imgCon}>
        <p className={styles.img}>
          <img src={catlog} />
        </p>
        <div>
          <p className={styles.head}>Catalog</p>
          <p className={styles.desc}>catalogapp.io</p>
        </div>
      </div>
    ),
    LastLoggedIn: (
      <div>
        <p className={styles.desc}>May 29, 2024</p>
        <p className={styles.time}>10:20AM</p>
      </div>
    ),
  },
];
