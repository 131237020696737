import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, colors, Typography } from "@mui/material";
import { PRIMARYCOLOR } from "../../Utility/constants";

export default function PieChart({
  value,
  color,
  size,
  Font,
  Thikness,
  ...props
}) {
  const circleColor =
    value < 50 ? "#F67D7D" : value < 80 ? "#FBD26A" : "#92F9C9";
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#f7f7f7",
          // color: (theme) =>
          //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          position: "absolute",
        }}
        size={size}
        thickness={Thikness ? Thikness : 4}
        {...props}
        value={100}
      />

      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          color: circleColor,
        }}
        size={size}
        thickness={Thikness ? Thikness : 4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.primary"
          sx={{ fontSize: Font ? Font : "15px", fontWeight: "600" }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
