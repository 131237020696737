import React from "react";
import InputText from "../../../../Components/InputText/InputText";
import useInput from "../../../../hooks/useInput";
import { TEXTField } from "../utility";

function TextType({ data, ...props }) {
  const field = useInput("", null, {
    ...TEXTField,
    placeholder: data?.placeholder,
  });
  console.log(data, "helllo data");
  return (
    <div style={{ width: "60%" }}>
      <p style={{ marginBottom: "5px", marginTop: "0px" }}>
        {data?.placeholder}
        {data?.is_mandatory && "*"}
      </p>
      <p style={{ marginBottom: "5px", marginTop: "0px", color: "grey" }}>
        {" "}
        {data.help_text}
      </p>
      <InputText
        {...field}
        value=""
        multiline={data?.question_type === 3 ? true : false}
      />
    </div>
  );
}

export default TextType;
