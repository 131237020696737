import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function IntroductionType({ data, ...props }) {
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data); // Update the state with the editor content
  };
  return (
    <>
      <p>Introduction</p>
      <div
        dangerouslySetInnerHTML={{
          __html: `${data.title}`,
        }}
      ></div>
      {/* <CKEditor
        editor={ClassicEditor}
        data={data.title}
        onChange={handleEditorChange}
        onReady={(editor) => {
          // Make the editor read-only
          editor.enableReadOnlyMode("my-feature");
        }}
      />
      <style jsx>{`
        .ck-editor__editable {
          min-height: 400px; // Set the desired height
        }
      `}</style> */}
    </>
  );
}

export default IntroductionType;
