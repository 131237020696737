import React from "react";
import styles from "../CreateSurvey.module.css";
import { GREYCOLOR, HRLINE } from "../../../../Utility/constants";
import TextType from "./TextType";
import ImageType from "./ImageType";
import RatingType from "./RatingType";
import MultipleChoiceType from "./MultipleChoiceType";
import BannerType from "./BannerType";
import IntroductionType from "./IntroductionType";

function Properties({ data, ...props }) {
  return (
    <React.Fragment>
      <div className={styles.leftHeader}>
        <h4 style={{ marginBottom: "10px" }}>Properties</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img alt="" src={close} style={{ cursor: "pointer" }} /> */}
        </div>
      </div>
      <hr style={{ borderColor: HRLINE }} />
      {data?.question_type === 2 && (
        <TextType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 3 && (
        <TextType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 7 && (
        <TextType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 8 && (
        <TextType key={data.index} data={data} handleChange />
      )}
      {/* {data?.question_type === 1 && <TextType data={data} handleChange />} */}
      {data?.question_type === 1 && (
        <BannerType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 10 && (
        <ImageType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 9 && (
        <RatingType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 4 && (
        <MultipleChoiceType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 5 && (
        <MultipleChoiceType key={data.index} data={data} handleChange />
      )}{" "}
      {data?.question_type === 6 && (
        <MultipleChoiceType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 13 && (
        <BannerType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 15 && (
        <IntroductionType key={data.index} data={data} handleChange />
      )}
      {data?.question_type === 14 && (
        <MultipleChoiceType key={data.index} data={data} handleChange />
      )}
      {/* {data?.question_type === 11 && (
        <ConditionalType data={data} handleChange />
      )} */}
    </React.Fragment>
  );
}

export default Properties;
