import React from "react";
import styles from "./SurveyManage.module.css";
import cross from "../../assets/survery/cross.png";
import duplicate from "../../assets/survery/duplicate.png";
import Thumbnail1 from "../../assets/survery/Thumbnail1.png";
import Thumbnail2 from "../../assets/survery/Thumbnail2.png";
import Thumbnail3 from "../../assets/survery/Thumbnail3.png";
import Thumbnail4 from "../../assets/survery/Thumbnail4.png";
import Thumbnail5 from "../../assets/survery/Thumbnail5.png";
import Thumbnail6 from "../../assets/survery/Thumbnail6.png";
import search from "../../assets/survery/search.png";

function SurveyManage() {
  return (
    <div className={styles.Con}>
      {/* duplicate popup  */}
      <div className={styles.duplicate}>
        <div className={styles.cross}>
          <img src={cross}></img>
        </div>
        <div className={styles.duplicateImg}>
          <img src={duplicate}></img>
        </div>
        <p className={styles.info}>
          Are you sure you want to
          <br />
          Duplicate this Survey
        </p>
        <div className={styles.btns}>
          <button>Yes</button>
          <button>No</button>
        </div>
      </div>

      {/* Confirm survey */}

      <div className={styles.Confirm}>
        <div className={styles.head}>
          <h3 className={styles.heading}>Confirm</h3>
          <div>
            <img src={cross}></img>
          </div>
        </div>
        <div className={styles.desc}>
          <p>1 Survey will be deleted, along with all related activities.</p>
          <p>Delete 1 Survey</p>
        </div>

        <div className={styles.code}>
          <p>Type 4175 to Confirm</p>
          <input type="text" placeholder="Enter Value"></input>
        </div>
        <div className={styles.buttons}>
          <button>Yes</button>
          <button>No</button>
        </div>
      </div>

      {/* Thumbnail */}

      <div className={styles.Thumbnail}>
        <div className={styles.headThumbnail}>
          <h3 className={styles.headingThumbnail}>Select Survey Thumbnail</h3>
          <div>
            <img src={cross}></img>
          </div>
        </div>
        <div className={styles.ThumbnailImgs}>
          {Thumbnail?.map((item) => {
            return (
              <div className={styles.images}>
                <img src={item.img}></img>
                <p>{item.name}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.buttons}>
          <button>Select Thumbnail</button>
          <button>Close</button>
        </div>
      </div>

      {/* Create Survey */}

      <div className={styles.create}>
        <div className={styles.headcreate}>
          <h3 className={styles.headingcreate}>Select Survey Thumbnail</h3>
          <div>
            <img src={cross}></img>
          </div>
        </div>
        <div className={styles.search}>
          <input type="text" placeholder="Search Survey"></input>
          <div>
            <img src={search}></img>
          </div>
        </div>

        <div className={styles.imgInfo}>
          {Create?.map((item) => {
            return (
              <>
                <div className={styles.createImg}>
                  <img src={item.img}></img>
                  <p>{item.desc}</p>
                </div>
              </>
            );
          })}
        </div>

        <div className={styles.buttons}>
          <button>Add Survey</button>
          <button>Close</button>
        </div>
      </div>
    </div>
  );
}

export default SurveyManage;
const Thumbnail = [
  {
    id: 1,
    img: Thumbnail1,
    name: "Thumbnail 1",
  },
  {
    id: 2,
    img: Thumbnail2,
    name: "Thumbnail 2",
  },
  {
    id: 3,
    img: Thumbnail3,
    name: "Thumbnail 3",
  },
  {
    id: 4,
    img: Thumbnail4,
    name: "Thumbnail 4",
  },
  {
    id: 5,
    img: Thumbnail5,
    name: "Thumbnail 5",
  },
  {
    id: 6,
    img: Thumbnail6,
    name: "Add New ",
  },
];
const Create = [
  {
    id: 1,
    img: Thumbnail1,
    name: "Thumbnail 1",
    desc: "Diagnostic_Baseline_Infosys_17.6.2024",
  },
  {
    id: 2,
    img: Thumbnail2,
    name: "Thumbnail 2",
    desc: "Diagnostic_Endline_Infosys_30.9.2023",
  },
  {
    id: 3,
    img: Thumbnail3,
    name: "Thumbnail 3",
    desc: "Diagnostic_Baseline_Infosys_14.1.2023",
  },
];
