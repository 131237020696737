import React, { useContext, useState } from "react";
import UploadImage from "../../../../Components/UploadImage/UploadImage";
import useInput from "../../../../hooks/useInput";
import { TEXTField } from "../utility";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";

function BannerType({ data, ...props }) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const [image, setImage] = useState({
    image: null,
    header_banner: null,
    footer_banner: null,
  });
  const handleImage = (_data, sec) => {
    let quesListing = [...surveyQuestions?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      if (sec === "image") quesListing[index].image = _data;
      if (sec === "banner") quesListing[index].header_banner = _data;
      if (sec === "footer") quesListing[index].footer_banner = _data;
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };

  return (
    <div>
      <p style={{ marginBottom: "5px", marginTop: "0px" }}>
        {data?.placeholder}
        {data?.is_mandatory && "*"}
      </p>
      <p style={{ marginBottom: "5px", marginTop: "0px", color: "grey" }}>
        {" "}
        {data.help_text}
      </p>
      {data.question_type === 1 && (
        <React.Fragment>
          <p style={{ fontSize: "12px", color: "grey" }}>Banner Image</p>
          <UploadImage
            getImage={(data) => handleImage(data, "banner")}
            img={
              typeof data.header_banner !== "string" ? "" : data.header_banner
            }
          />
        </React.Fragment>
      )}
      {data.question_type === 13 && (
        <React.Fragment>
          <p style={{ fontSize: "12px", color: "grey" }}>Footer Image</p>
          <UploadImage
            getImage={(data) => handleImage(data, "image")}
            img={
              typeof data.footer_banner !== "string" ? "" : data.footer_banner
            }
          />
        </React.Fragment>
      )}
      {data.question_type === 10 && (
        <React.Fragment>
          <p style={{ fontSize: "12px", color: "grey" }}>Secondary Image</p>
          <UploadImage
            getImage={(data) => handleImage(data, "image")}
            img={typeof data.image !== "string" ? "" : data.image}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default BannerType;
