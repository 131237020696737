import TextField from "@mui/material/TextField";
import { width } from "@mui/system";
import React from "react";

function InputText({
  name = "",
  value = "",
  onChange,
  label = "",
  size = "small",
  placeholder = "",
  type = "text",
  style = { width: "100%" },
  error = "",
  required = false,
  disabled = false,
  multiline = false,
  ...props
}) {
  return (
    <React.Fragment>
      <TextField
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        style={style}
        size={size}
        placeholder={placeholder}
        type={type}
        error={error ? true : false}
        helperText={error}
        required={required}
        disabled={disabled}
        multiline={multiline}
        rows={4}
      />
    </React.Fragment>
  );
}

export default InputText;
