import React, { useContext, useState } from "react";
import ControlledButton from "../../../../Components/ControlledButton/ControlledButton";
import add from "../../../../assets/dashboard/add.svg";
import deleteIcon from "../../../../assets/dashboard/delete.svg";
import ControlledSwitches from "../../../../Components/ControlledFields/ControlledSwitches";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import useInput from "../../../../hooks/useInput";
import { TEXTField } from "../utility";
import InputText from "../../../../Components/InputText/InputText";

function BannerType(data, ...props) {
  const { surveyQuestions, setContext } = useContext(AppContext);
  const [checked, setChecked] = useState(false);
  const placeholder = useInput(data?.placeholder, null, {
    ...TEXTField,
    placeholder: data?.placeholder,
  });
  const handleClick = (_type) => {
    var jsonString = JSON.stringify(surveyQuestions);
    var quesListing = JSON.parse(jsonString);
    quesListing = [...quesListing?.questions];
    if (quesListing && quesListing.length > 0) {
      const index = quesListing.findIndex((i) => i.index === data.index);
      if (_type === "duplicate") {
        let newIndex = { ...quesListing[index] };
        quesListing.push({ ...newIndex, index: quesListing.length + 1 });
      } else {
        quesListing.splice(index, 1);
      }
      setContext((prev) => ({
        ...prev,
        currentSection: { ...surveyQuestions, questions: [...quesListing] },
      }));
    }
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("duplicate")}
        >
          <img src={add} style={{ marginRight: "10px" }} />{" "}
          <span>
            <b>Duplicate</b>
          </span>
        </ControlledButton>
        <ControlledButton
          variant="outlined"
          style={{
            borderRadius: "15px",
          }}
          onClick={() => handleClick("delete")}
        >
          <img src={deleteIcon} style={{ marginRight: "10px" }} />{" "}
          <span style={{ color: "grey" }}>
            <b>Delete</b>
          </span>
        </ControlledButton>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>Required</b>
        </p>
        <ControlledSwitches checked={checked} setChecked={setChecked} />
      </div>{" "}
      <p>
        <b>Placeholder</b>
      </p>
      <InputText {...placeholder} />
    </div>
  );
}

export default BannerType;
