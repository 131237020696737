import React, { useEffect, useState } from "react";
import AddSolution from "../../Components/AddSolution/AddSolution";
import PageHeader from "../../Components/PageHeader/PageHeader";
import deleteIcon from "../../assets/componentAssest/deleteIcon.png";
import CustomTable from "../../Components/CustomTable/CustomTable";
import styles from "./SolutionHubManagement.module.css";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import soluction from "../../assets/componentAssest/soluction.png";
import { DeleteApi, getApi, PatchApi, PostApi } from "../../Utility/network";
import RecommendedPopUP from "../Dashboard/RecommendedPopUP/RecommendedPopUP";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import SelectStatus from "../../Components/SelectStatus/SelectStatus";
import { useNavigate } from "react-router-dom";
import { resolveUrl } from "ajv/dist/compile/resolve";
import { CircularProgress } from "@mui/material";
function SolutionHubManagement() {
  const [createOrg, setCreateOrg] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const [update, setUpdate] = useState();
  const [open, setOpen] = useState(false);
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [video, setVideo] = useState();
  const [selectDataId, setSelectDatatId] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});

  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [dropDown, setDropDown] = useState({
    type: [],
  });
  const [statusValue, setStatusValue] = useState({ rowid: "", status: "" });
  const [selected, setSelected] = useState({
    selectedType: 0,
  });
  const navigate = useNavigate();
  const handleNavigate = (id, data) => {
    navigate(`/solution-hub-management/${id}`, {
      state: { name: data?.title },
    });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setCreateOrg(true);
  };
  const handleClickClose = () => {
    setCreateOrg(false);
    setUpdate();
  };
  useEffect(() => {
    getOrgData();
    getSoluctionData();
  }, []);

  const getStatus = async (id) => {
    if (!id) return;
    let data = {};

    switch (statusValue.status) {
      case "Active":
        data = { status: 1 };
        break;
      case "Inactive":
        data = { status: 2 };
        break;
      case "Paused":
        data = { status: 3 };
        break;
      case "Withdrawn":
        data = { status: 4 };
        break;
      default:
        data = {};
    }
    try {
      const getResponse = await PatchApi(
        `survey/recommended_solutions/${id}/`,
        data
      );
      // console.log(getResponse?.data?.data, "dataresponse");
      getOrgData();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (statusValue?.rowid !== null) {
      getStatus(statusValue?.rowid);
    }
  }, [statusValue]);
  const getOrgData = async (filter) => {
    setLoading(true);

    try {
      const getResponse = await getApi(`survey/recommended_solutions/`, filter);

      const modifyArray = getResponse?.data?.data.map((item) => {
        // let statusStyle;

        // switch (item.status_str) {
        //   case "Active":
        //     statusStyle = styles.active;
        //     break;
        //   case "Paused":
        //     statusStyle = styles.paused;
        //     break;
        //   case "Withdrawn":
        //     statusStyle = styles.withdrawn;
        //     break;
        //   case "Inactive":
        //     statusStyle = styles.Inactive;
        //     break;
        //   default:
        //     statusStyle = styles.default;
        // }

        return {
          ...item,
          soluctionName: <p className={styles.head}>{item?.title}</p>,
          SoluctionProvider: <p className={styles.head}>{item?.provider}</p>,
          solutionType: <p className={styles.head}>{item?.solution_type}</p>,
          PointofContact: (
            <div>
              <p className={styles.head}>{item?.poc_email}</p>
              <p className={styles.desc}>{item?.poc_name}</p>
            </div>
          ),
          statusDrop: (
            <SelectStatus
              statusValue={item?.status_str}
              setStatusValue={setStatusValue}
              id={item?.id}
              soluction={true}
            />
          ),
        };
      });

      setOrgData(modifyArray);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;

      if (selected?.selectedType) {
        finalFilters.solution_type = selected?.selectedType;
      }
      getOrgData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [selected, pageState]);

  const handleEdit = (data) => {
    handleClick();
    setUpdate(data);
  };
  const videoData = (data) => {
    handleOpen();
    setVideo(data);
  };
  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
    // getDelete(id);
  };
  const handlepopUpclose = () => {
    setdeleteData(false);
    setSelectedIds([]);
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(
        `survey/recommended_solutions/${id}/`
      );
      getOrgData();
    } catch (error) {
      console.error(error);
    }
  };
  const getSoluctionData = async () => {
    try {
      const getResponse = await getApi(`survey/solution_type/`);
      const updatedData = [{ id: 0, title: "All" }, ...getResponse?.data];
      setDropDown({ type: updatedData });
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};

      if (selected.selectedType) {
        finalFilters.selectedType = selected?.selectedType;
      }

      // getTopCards(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
  }, [selected]);

  const handleBulkDelete = async () => {
    if (!selectDataId || selectDataId.length === 0) return;

    const ids = { solution_ids: selectDataId };

    try {
      const getResponse = await PostApi(
        `/survey/recommended_solutions/bulk_delete/`,
        ids
      );

      getOrgData();
    } catch (error) {
      console.error(error);
    }
  };

  const popOpen = () => {
    setdeleteData(true);
  };

  return (
    <div>
      {orgData?.length > 0 && loading === false ? (
        <div>
          <PageHeader
            heading={"Solution Hub Management"}
            buttonName={"Delete"}
            buttonIcon={deleteIcon}
            addInfo={"Add New Solution"}
            handleClick={handleClick}
            dropDown={dropDown}
            selected={selected}
            setSelected={setSelected}
            popOpen={popOpen}
            selectDataId={selectDataId}
          />

          <CustomTable
            tableBody={orgData}
            tableHeads={tableHeads}
            headerCheck={true}
            ThreeActions={true}
            handleEdit={handleEdit}
            videoData={videoData}
            handleDelete={handleDelete}
            setSelectDatatId={setSelectDatatId}
            handlepopUpclose={handlepopUpclose}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            loading={loading}
            handleView={handleNavigate}
            max_pages={max_pages}
            setPageState={setPageState}
          />

          <RecommendedPopUP
            data={video}
            open={open}
            handleClose={handleClose}
          />
        </div>
      ) : orgData?.length == 0 && loading === false ? (
        <EmptyPage
          heading={"Solution Hub Management"}
          desc={`Every problem has a solution. The problem is to find the solution to the problem. Start by adding a solution.`}
          buttonName={"Add New Solution"}
          pageImage={soluction}
          handleClick={handleClick}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}
      {createOrg && (
        <AddSolution
          handleClickClose={handleClickClose}
          open={createOrg}
          update={update}
          soluctionId={update?.id}
          getOrgData={getOrgData}
        />
      )}
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          handleBulkDelete={handleBulkDelete}
          selectDataId={selectDataId}
        />
      )}
    </div>
  );
}

export default SolutionHubManagement;

const tableHeads = [
  {
    id: 1,
    label: "Solution Name",
    key: "soluctionName",
    showIcon: "true",
    edit: "true",
  },
  {
    id: 2,
    label: "Solution Provider",
    key: "SoluctionProvider",
    showIcon: "true",
  },
  { id: 3, label: "Solution Type", key: "solutionType" },
  { id: 4, label: "Point of Contact", key: "PointofContact" },
  { id: 5, label: "Status", key: "statusDrop" },
];

// const tableBody = [
//   {
//     id: 1,
//     SolutionName: <p className={styles.head}>Wealth Creation Workshop</p>,
//     SolutionProvider: <p className={styles.head}>Sama</p>,
//     SolutionType: <p className={styles.head}>L&D</p>,
//     PointofContact: (
//       <div>
//         <p className={styles.head}>Eleanor Pena</p>
//         <p className={styles.desc}>nathan.roberts@example.com</p>
//       </div>
//     ),
//     Status: "Paused",
//   },
// ];
