import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";

export default function LabTabs({ props }) {
  const { surveyPage, setContext, contextState } = React.useContext(AppContext);
  const [value, setValue] = React.useState("0");
  const handleChange = (event, newValue) => {
    let { allSection, currentSection } = contextState;
    const oldIndex = allSection.findIndex(
      (obj) => obj.index === Number(value) + 1
    );
    const newIndex = allSection.findIndex(
      (obj) => obj.index === Number(newValue) + 1
    );
    const newAllSection = allSection.map((item, index) => {
      if (index == oldIndex) {
        return currentSection;
      } else {
        return item;
      }
    });
    setContext((prev) => ({
      ...prev,
      currentSection: allSection[newIndex],
      allSection: newAllSection,
      surveyPage: { count: Number(newValue) + 1 },
    }));
    setValue(newValue);

    // if (value !== newValue) {
    //   const oldIndex = allSection.findIndex(
    //     (obj) => obj.index === Number(value) + 1
    //   );
    //   const newIndex = allSection.findIndex(
    //     (obj) => obj.index === Number(newValue) + 1
    //   );
    //   allSection[oldIndex] = currentSection;
    //   setContext((prev) => ({
    //     ...prev,
    //     surveyPage: { count: `${newValue}` },
    //     currentSection: allSection[newIndex],
    //     ...(oldIndex != -1 && { allSection: allSection }),
    //   }));
    // }
    // setValue(newValue);
  };

  // React.useEffect(() => {
  //   setValue(surveyPage.count -1);
  // }, [surveyPage]);
  // console.log(surveyPage,'value')
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {contextState?.allSection?.map((item, index) => {
              return (
                <Tab
                  label={`Page ${index + 1}`}
                  value={`${index}`}
                  key={index}
                />
              );
            })}
          </TabList>
        </Box>
        {/* <TabPanel value="1"></TabPanel> */}
        {/* <TabPanel value="2">Page Two</TabPanel>
        <TabPanel value="3">Page Three</TabPanel> */}
      </TabContext>
    </Box>
  );
}
