import { Button, Dialog, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./AddSoluctionPop.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DateField from "../DateField/DateField";
import { getApi, PostApi } from "../../Utility/network";
function AddSoluctionPop({
  handleClosePop = () => {},
  open,
  orgId,
  getSolution = () => {},
}) {
  const [dataField, setDataField] = useState({
    name: "",
    status: "",
    start_date: "",
    end_date: "",
  });
  const [dropDown, setDropDown] = useState({
    status: [
      { id: 1, title: "Active" },
      { id: 2, title: "Inactive" },
      { id: 3, title: "Paused" },
      { id: 4, title: "Withdrawn" },
    ],
  });
  const [org, setOrg] = useState([]);
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
  };
  const handleChange = (name, data) => {
    setDataField((prev) => ({
      ...prev,
      [name]:
        name === "start_date" || name === "end_date"
          ? stringToYYYYMMDD(data)
          : data,
    }));
  };
  const stringToYYYYMMDD = (dateString) => {
    const startDate = new Date(dateString);
    const formattedDate = startDate.toISOString().slice(0, 10);
    return formattedDate;
  };
  const clearState = () => {
    setDataField({
      name: "",
      status: "",
      start_date: "",
      end_date: "",
    });
  };
  const addSoluction = async (id) => {
    const data = {
      solution_id: dataField?.name,
      start_date: dataField?.start_date,
      end_date: dataField?.end_date,
      status: dataField?.status,
      organisation_id: +id,
    };
    try {
      const getResponse = await PostApi(
        `/survey/recommended_solutions/assigned/`,
        data
      );

      handleClosePop();
      clearState();
      setTimeout(() => {
        getSolution(orgId);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };
  const getDrop = async (id) => {
    try {
      const getResponse = await getApi(
        `/survey/recommended_solutions/dropdown/`
      );

      setOrg(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getDrop();
  }, []);
  return (
    <Dialog onClose={handleClosePop} open={open} fullWidth="true" maxWidth="md">
      <div className={styles.con}>
        <div className={styles.top}>
          <p className={styles.heading}>Add Recommended Solution</p>
          <CloseIcon onClick={handleClosePop} style={{ cursor: "pointer" }} />
        </div>
        <div className={styles.fieldCon}>
          <div className={styles.innerCon}>
            <p className={styles.name}>Solution</p>
            <Select
              value={dataField?.name}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="name"
              // error={!!addError.industry}
            >
              {org?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.innerCon}>
            <p className={styles.name}>Status</p>
            <Select
              value={dataField?.status}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="status"
              // error={!!addError.industry}
            >
              {dropDown?.status?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.innerCon}>
            <p className={styles.name}>Start Date</p>
            <DateField
              onChange={(date) => handleChange("start_date", date)}
              name="start_date"
              startDate={dataField.start_date}
            />
          </div>
          <div className={styles.innerCon}>
            <p className={styles.name}>End Date</p>
            <DateField
              onChange={(date) => handleChange("end_date", date)}
              name="end_date"
              startDate={dataField.end_date}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            variant="contained"
            onClick={() => {
              addSoluction(orgId);
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddSoluctionPop;
