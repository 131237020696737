import React, { useState } from "react";
import styles from "./PublishSurveyHelpus.module.css";
import { PRIMARYCOLOR, WHITE } from "../../Utility/constants";

function MultiChoice({ item, onChange = () => {}, name, ...props }) {
  const { choices } = item;
  const [list, setList] = React.useState(null);
  const handleClick = (id) => {
    setList(id);
    // setList(list.includes(id) ? list.filter((i) => i !== id) : [...list, id]);
    let matchingObjects = choices.filter((obj) => obj.id === id);
    onChange(id, name, matchingObjects, choices, item);
  };
  return (
    <div className={styles.gContainer}>
      {choices?.map((ch, idx) => {
        return (
          <div
            style={{
              background: `${list == ch.id ? PRIMARYCOLOR : "white"}`,
              cursor: "pointer",
            }}
            className={styles.genders}
            onClick={() => handleClick(ch.id)}
          >
            <span className={styles.num}>{idx + 1} </span>
            <p>{ch.title}</p>
          </div>
        );
      })}
    </div>
  );
}

export default MultiChoice;
