import React, { useEffect, useState } from "react";
import styles from "./AddContact.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, MenuItem, Select, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { emailField } from "../../Utility/constants";
import { getApi, PostApi } from "../../Utility/network";

function AddContact({ close, open, datas, id, getContactData }) {
  const [dataField, setDataField] = useState([
    {
      id: 1,
      name: "",
      email: "",
    },
  ]);
  const [dropDown, setDropDown] = useState([]);

  const handleInputChange = (id, e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const selectedContact = dropDown.find((item) => item.name === value);

      setDataField(
        dataField.map((field) =>
          field.id === id
            ? {
                ...field,
                name: selectedContact?.name || "",
                email: selectedContact?.email || "",
              }
            : field
        )
      );
    } else {
      setDataField(
        dataField.map((field) =>
          field.id === id ? { ...field, [name]: value } : field
        )
      );
    }
  };

  const addField = () => {
    setDataField([
      ...dataField,
      {
        id: dataField?.length + 1,
        name: "",
        email: "",
      },
    ]);
  };

  useEffect(() => {
    if (datas && datas.length > 0) {
      let updatedData = datas.map((item) => ({
        id: item.id,
        name: item.name || "",
        email: item.email || "",
      }));

      updatedData.push({
        id: datas.length + 1,
        name: "",
        email: "",
      });

      setDataField(updatedData);
    }
  }, [datas]);
  const clearState = () => {
    setDataField([
      {
        name: "",
        email: "",
      },
    ]);
  };
  const handleFieldDelete = (id) => {
    setDataField((prevState) => prevState.filter((item) => item.id !== id));
  };
  const postPOc = async (id) => {
    const filteredData = dataField
      .filter((field) => field.name.trim() !== "" && field.email.trim() !== "")
      .map(({ ...rest }) => {
        return { ...rest }; // Explicitly returning the object without 'id'
      });

    const data = { points_of_contact: filteredData };

    try {
      const getResponse = await PostApi(
        `profile/organisation/${id}/add_update_poc/`,
        data
      );
      getContactData(id);
      // setContact(formattedData);
      clearState();
      close();
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getContactDrop = async () => {
    try {
      const getResponse = await getApi(
        `/dashboard/admin/user/?organisation_id=${id}`
      );
      // setDropDown(getResponse.data);
      const filteredData = getResponse?.data?.data.map((item) => ({
        id: item?.id,
        name: item?.first_name,
        email: item?.email,
      }));

      // Update the dropdown state
      setDropDown(filteredData);
    } catch (error) {
      console.error("Error fetching organisation data", error);
    }
  };
  useEffect(() => {
    getContactDrop();
  }, []);
  return (
    <Dialog onClose={close} open={open} fullWidth="true" maxWidth="md">
      <div className={styles.con}>
        <div className={styles.head}>
          <h3 className={styles.heading}>Add Point of Contact</h3>
          <CloseIcon onClick={close} style={{ cursor: "pointer" }} />
        </div>
        {dataField?.map((item, index) => {
          const isLastField = index === dataField.length - 1;
          return (
            <div className={styles.fieldCon} key={item.id}>
              <div className={styles.innerCon}>
                <p className={styles.name}>Name</p>
                <Select
                  value={item.name} // Bound to the 'name' field in dataField
                  onChange={(e) => handleInputChange(item.id, e)}
                  sx={{ width: "100%" }}
                  size="small"
                  name="name"
                >
                  {dropDown?.map((dropDownItem) => (
                    <MenuItem key={dropDownItem.id} value={dropDownItem.name}>
                      {dropDownItem.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <TextField
                  placeholder="Name"
                  size="small"
                  sx={{ width: "100%" }}
                  name="name"
                  value={item?.name}
                  onChange={(e) => handleChangeInput(item.id, e)}
                /> */}
              </div>
              <div className={styles.innerCon}>
                <p className={styles.name}>Email</p>
                <TextField
                  placeholder="Email"
                  size="small"
                  name="email"
                  sx={{ width: "100%" }}
                  value={item?.email}
                  onChange={(e) => handleInputChange(item.id, e)}
                />
              </div>
              <div className={styles.iconCon}>
                {!isLastField ? (
                  <div
                    className={styles.iconAdd}
                    onClick={() => handleFieldDelete(item.id)}
                  >
                    <RemoveIcon color="warning" />
                  </div>
                ) : (
                  <div className={styles.iconMinus} onClick={addField}>
                    <AddIcon />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div className={styles.btns}>
          <Button variant="contained" onClick={() => postPOc(id)}>
            Update
          </Button>
          <Button variant="outlined" onClick={close}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddContact;
