import React, { useState } from "react";
import styles from "./SummaryCard.module.css";
import Avatar from "../../../assets/survery/Avatar.png";
import { Button } from "@mui/material";
import ShareSurveyPopUp from "../../ShareSurveyPopUp/ShareSurveyPopUp";
import { DeleteApi, getApi, PatchApi } from "../../../Utility/network";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
import BuildSurvey from "../../BuildSurvey/BuildSurvey";
import dummy from '../../../assets/componentAssest/dummy.svg'
function SummaryCard({
  details,
  count,
  respondent,
  surveyId,
  getDetails = () => {},
  ...props
}) {
  const [openDialog, set0penDialog] = useState(false);
  const [invited, setInvited] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deletedata, setdeleteData] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const handleDialog = () => {
    set0penDialog(true);
    getShareSurvey(surveyId);
  };
  const handleCloseDialog = () => {
    set0penDialog(false);
  };

  const getShareSurvey = async (id) => {
    try {
      const data = await getApi(`survey/${id}/qr_data/`);
      setData(data?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const patchShareSurvey = async (id) => {
    const dataInvited = { total_invited_respondents: invited };
    try {
      const data = await PatchApi(`survey/${id}/`, dataInvited);
      set0penDialog(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = () => {
    // setEditId(id);

    setOpenEdit(true);
    // console.log(id, "sid");
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`/survey/${id}/`);
      // getData();
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
  };

  const handlepopUpclose = () => {
    setdeleteData(false);
    // getSurvey();
    navigate(`/survey-management`);
  };
  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>{details.title}</h3>
          <div className={styles.cardButtons}>
            <Button
              variant="outlined"
              style={{ borderRadius: "50px" }}
              onClick={() => {
                handleEdit();
              }}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              style={{
                color: "#798294",
                borderRadius: "50px",
                borderColor: "#798294",
              }}
              onClick={() => handleDelete(surveyId)}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "50px",
                color: "#12B76A",
                borderColor: "#12B76A",
              }}
              onClick={handleDialog}
            >
              Share
            </Button>
          </div>
        </div>
        <hr />
        <div className={styles.cardBody}>
          <div>
            <p>Company Name</p>
            <div className={styles.companyInfo}>
              <img
                src={details?.organisation?.logo?details?.organisation?.logo:dummy}
                alt=""
                className={styles.companyLogo}
              />
              <div className={styles.companyDetails}>
                <p className={styles.companyName}>
                  {details?.organisation?.title}
                </p>
                <p className={styles.companyUrl}>
                  {details?.organisation?.website}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.createdBy}>
            <p>Created by</p>
            <p
              className={styles.creatorName}
              style={{ textTransform: "capitalize" }}
            >
              {details?.created_by?.first_name} {details?.created_by?.last_name}
            </p>
          </div>
          <div className={styles.totalResponses}>
            <p>Total Responses</p>
            <p className={styles.responseNumber}>{count}</p>
          </div>
          <div className={styles.status}>
            <p>Status</p>
            <p
              className={` ${
                details?.status === "ongoing"
                  ? styles.paused
                  : details?.status === "draft"
                  ? styles.Inactive
                  : details?.status === "completed"
                  ? styles.active
                  : ""
              }`}
            >
              {details?.status}
            </p>
          </div>
        </div>
      </div>
      <ShareSurveyPopUp
        openDialog={openDialog}
        handleDialog={handleDialog}
        handleCloseDialog={handleCloseDialog}
        data={data}
        respondatePrev={respondent}
        setInvited={setInvited}
        handlePatch={patchShareSurvey}
        surveyId={surveyId}
      />
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={surveyId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          // handleBulkDelete={handleBulkDelete}
          // selectDataId={selectDataId}
        />
      )}
      {openEdit && (
        <BuildSurvey
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          data={details}
          getDetails={getDetails}
          surveyId={surveyId}
        />
      )}
    </>
  );
}

export default SummaryCard;
