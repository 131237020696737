import React from "react";
import styles from "./AproveUser.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";

function AproveUser({ icon, heading, secPara ,bgColor,handleClose,imgBG }) {
  return (
  
    <div className={styles.conOuter}style={{backgroundColor:bgColor}} >
      <div className={styles.con}>
        <div className={styles.icon} style={{backgroundColor:imgBG}}>
          <img src={icon} />
        </div>
        <div>
          <p className={styles.head}>{heading} </p>
          <p className={styles.desc}>{secPara}</p>
        </div>
      </div>
      <div className={styles.close} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
   
  );
}

export default AproveUser;
