import React, { useEffect, useState } from "react";
import styles from "../CreateSurvey.module.css";
import { WHITE } from "../../../../Utility/constants";
import { generateRatingScale } from "../utility";
function RatingType({ data, ...props }) {
  const [ratingNum, setRatingNum] = useState([]);
  const { rating_scale_start, rating_scale_end, scale_step } = data;
  useEffect(() => {
    const arr = generateRatingScale(
      rating_scale_start,
      rating_scale_end,
      scale_step
    );
    setRatingNum([...arr]);
  }, [rating_scale_start, rating_scale_end, scale_step]);
  return (
    <div>
      <p style={{ marginBottom: "5px", marginTop: "0px" }}>
        {data?.placeholder}
        {data?.is_mandatory && "*"}
      </p>
      <div className={styles.ratingCss}>
        {ratingNum.map((item, index) => {
          return (
            <div
              style={{ background: `${WHITE}` }}
              key={index}
              className={styles.ratingCssdiv}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className={styles.textBottom}>
        <div>{data.left_label}</div>
        <div>{data.central_label}</div>
        <div>{data.right_label}</div>
      </div>
    </div>
  );
}

export default RatingType;
