import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import styles from "./SubbmisionTable.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect, useState } from "react";
import yes from "../../assets/componentAssest/yes.png";
import no from "../../assets/componentAssest/no.png";
import ConformationPopUp from "../ConformationPopUp/ConformationPopUp";
import trash from "../../assets/componentAssest/trash.svg";
import copy from "../../assets/componentAssest/copy1.svg";
import send from "../../assets/componentAssest/send.svg";

import edit from "../../assets/componentAssest/edit.svg";

import share from "../../assets/componentAssest/share.svg";
import { styled } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import add from "../../assets/componentAssest/add.png";
import editCat from "../../assets/componentAssest/editCat.png";
import addPOC from "../../assets/componentAssest/addPOC.png";
import catlog from "../../assets/componentAssest/catlog.png";
import CatlogHeader from "../CatlogHeader/CatlogHeader";

const StyledTableRow = styled(TableRow)(({ theme, dashBoard }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: dashBoard ? "none" : "#F9FAFB",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SubbmisionTable = ({
  soluctionInnner = false,
  tableHeads,
  setSelectedIds = () => {},
  selectedIds,
  tableBody,
  headerCheck = false,
  truncateText,
  dropDown,
  cat,
  handleOpen = false,
  FormatDate,
  handleSingleDelete = () => {},
  formatDate = () => {},
  handleReject = () => {},
  setSelectDatatId = () => {},
  released,
  loading,
  // setSelectedDrop,
  selectedDrop,

  // handleApprove=()=>{},
  handleNavigation,
  ...otherProps
}) => {
  const [tableData, setTableData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedId, setSelectedId] = useState([]);

  useEffect(() => {
    setTableData(tableBody);
  }, [tableBody]);
  const handleSelect = (row) => {
    setSelectedIds((prevSelected) => {
      const newSelected = [...prevSelected];
      const selectedIndex = newSelected.indexOf(row.userId);

      if (selectedIndex === -1) {
        // Add selected row ID
        newSelected.push(row.userId);
      } else {
        // Remove deselected row ID
        newSelected.splice(selectedIndex, 1);
      }

      setSelectDatatId(newSelected); // Update state with the selected IDs
      return newSelected; // Return the updated selectedIds state
    });
  };

  const handleSelectAll = () => {
    const visibleIds = tableData.map((row) => row.userId);
    if (allSelected) {
      // Deselect all: Remove only the currently visible rows' IDs from selectedIds
      const newSelectedIds = selectedIds.filter(
        (id) => !visibleIds.includes(id)
      );
      setSelectedIds(newSelectedIds);
      setSelectDatatId(newSelectedIds);
    } else {
      // Select all: Add only the currently visible rows' IDs to selectedIds
      const newSelectedIds = [
        ...selectedIds,
        ...visibleIds.filter((id) => !selectedIds.includes(id)),
      ];
      setSelectedIds(newSelectedIds);
      setSelectDatatId(newSelectedIds);
    }
    setAllSelected(!allSelected);
  };
  useEffect(() => {
    // This will check whether all visible rows on the current page are selected
    const visibleIds = tableData.map((row) => row.userId);
    const allRowsSelected = visibleIds.every((id) => selectedIds.includes(id));
    setAllSelected(allRowsSelected);
  }, [tableData, selectedIds]);
  return (
    <TableContainer component={Paper} {...otherProps}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headerCheck && (
              <TableCell>
                <Checkbox
                  checked={allSelected}
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < tableData.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            <TableCell>
              <div
                className={styles.tableFontHead}
                style={{ minWidth: "100px" }}
              >
                Submitted at
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.tableFontHead}>User ID</div>
            </TableCell>
            {!released && <TableCell></TableCell>}
            {tableHeads?.map((column, index) =>
              loading ? (
                <TableCell key={index}>
                  <Skeleton animation="wave" />
                </TableCell>
              ) : (
                <TableCell
                  key={index}
                  style={{ cursor: "pointer", minWidth: "250px" }}
                >
                  <div
                    className={styles.tableFontHead}
                    style={{ display: "flex", gap: "10px" }}
                    title={column.label}
                  >
                    {truncateText(column.label, 15)}
                  </div>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={tableHeads.length + 3}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            : tableData?.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {headerCheck && (
                    <TableCell>
                      <Checkbox
                        checked={selectedIds.includes(row.userId)} // Check by row.id, not row
                        onClick={() => handleSelect(row)}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <div className={styles.tableFontData}>
                      {FormatDate(row.response_date)}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{row.userId}</div>
                  </TableCell>
                  {!released && (
                    <TableCell sx={{ cursor: "pointer" }}>
                      <div
                        className={styles.tableData}
                        onClick={() => handleSingleDelete(row.userId)}
                      >
                        <img src={trash} alt="Delete" />
                      </div>
                    </TableCell>
                  )}

                  {row.answers_text.map((answer, answerIndex) => (
                    <TableCell key={answerIndex} className={styles.tableData}>
                      <div className={styles.tableFontData} title={answer}>
                        {answer == "" ? " - " : truncateText(answer, 20)}
                      </div>
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubbmisionTable;
