import React, { useState } from "react";
import styles from "./SelectStatus.module.css";
import { MenuItem, Select } from "@mui/material";
function SelectStatus({ setStatusValue, statusValue, id, soluction = false }) {
  const [optionValue, setOptionValue] = useState([
    "draft",
    "ongoing",
    "completed",
  ]);
  const [soluctionValue, setSoluctionValue] = useState([
    "Active",
    "Paused",
    "Withdrawn",
    "Inactive",
  ]);
  const handleStatusChange = async (id, newStatus) => {
    setStatusValue({
      status: newStatus,
      rowid: id,
    });
  };

  return (
    <div>
      <Select
        labelId="status-select-label"
        value={statusValue}
        onChange={(e) => handleStatusChange(id, e.target.value)}
        size="small"
        sx={{ width: "60%", borderRadius: "15px", border: "none" }}
        className={
          statusValue === "draft" || statusValue === "Inactive"
            ? styles.Inactive
            : statusValue === "completed" || statusValue === "Active"
            ? styles.active
            : statusValue === "paused" ||
              statusValue === "Paused" ||
              statusValue === "ongoing"
            ? styles.paused
            : statusValue === "Withdrawn"
            ? styles.withdrawn
            : ""
        }
      >
        {(soluction ? soluctionValue : optionValue)?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SelectStatus;
