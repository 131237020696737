import { getData } from "ajv/dist/compile/validate";
import React, { useContext, useEffect, useState } from "react";
import {
  DeleteApi,
  PatchApi,
  PostApi,
  baseURL,
  getApi,
} from "../../Utility/network";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SurveyManage from "../../Components/SurveyManage/SurveyManage";
import AlertDialogSlide from "../../Components/AlertDialogSlide/AlertDialogSlide";
import DateField from "../../Components/DateField/DateField";
import UploadImage from "../../Components/UploadImage/UploadImage";
import axios from "axios";
import { dycryptAES } from "../../Utility/crypt";
import { stringToYYYYMMDD } from "../../Utility/helper";
import styles from "../../Components/SurveyManage/SurveyManage.module.css";
import Thumbnail1 from "../../assets/survery/Thumbnail1.png";
import Thumbnail2 from "../../assets/survery/Thumbnail2.png";
import Thumbnail3 from "../../assets/survery/Thumbnail3.png";
import addImage from "../../assets/survery/addImage.svg";
import BasicSelect from "../../Components/BasicSelect/BasicSelect";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { tableHead } from "./SurveyManagementForm/utility";
import DownloadIconButton from "../../Components/DownloadIconButton/DownloadIconButton";
import CustomTextField from "../../Components/CustomTextField/CustomTextField";
import AddButton from "../../Components/AddButton/AddButton";
import exports from "../../assets/survery/export.svg";
import edit from "../../assets/survery/edit.svg";
import copy from "../../assets/survery/copy.svg";
import CustomSnackbar from "../../Components/CustomSnackbar/CustomSnackbar";
import duplicate from "../../assets/componentAssest/duplicate.svg";
import confirmDup from "../../assets/componentAssest/confirmDup.svg";

import ConformationPopUp from "../../Components/ConformationPopUp/ConformationPopUp";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import SelectStatus from "../../Components/SelectStatus/SelectStatus";
import dummy from "../../assets/componentAssest/dummy.svg";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import CustomSerachDropDown from "../../Components/CustomSerachDropDown/CustomSerachDropDown";
function SurveyManagement(props) {
  const { setContext, contextState, token, searchValue } =
    useContext(AppContext);
  const [urlParam, setUrlParam] = useState({ status: "All", org: "All" });
  const [duplicateId, setDuplicateId] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectDataId, setSelectDatatId] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [shareId, setShareId] = useState(null);
  const [orgSelect, setOrgSelect] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [apires, setApiRes] = useState(false);
  const [status, setStatus] = useState([
    { title: "All", id: 0 },
    { title: "draft", id: 1 },
    { title: "ongoing", id: 2 },
    { title: "completed", id: 3 },
  ]);
  const [survey, setSurvey] = useState([]);
  const [survey1, setSurvey1] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [view, setView] = useState(1);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });
  const [thumbnailListing, setThumbnailListing] = useState([]);
  const [org, setOrg] = useState([]);
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [addError, setAddError] = useState({});
  console.log(addError, "addError");

  // const [duplicateName, setDuplicateName] = useState({ survey_name: "" });

  // const [optionValue, setOptionValue] = useState([
  //   "draft",
  //   "ongoing",
  //   "completed",
  // ]);
  const [statusValue, setStatusValue] = useState({ rowid: "", status: "" });
  const start_date = new Date();
  const formattedDate =
    start_date.getFullYear() +
    "-" +
    String(start_date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(start_date.getDate()).padStart(2, "0");

  const [form, setForm] = useState({
    title: "",
    language: 1,
    location: null,
    organisation_id: 1,
    start_date: formattedDate,
    thumbnail_id: imageUrl,
    end_date: formattedDate,
  });
  // Output: 2024-10-09 (if today's date is Oct 9, 2024)

  const [copySuccess, setCopySuccess] = useState("");
  console.log(duplicateId, "duplicateId");

  const copyToClipboard = async (id) => {
    try {
      const dd = `http://65.0.182.37/survey/${id}`;
      // await navigator.clipboard.writeText(dd);
      // setCopySuccess("Copied!");
      // setContext((prev) => ({
      //   ...prev,
      //   snackbar: { open: true, message: "Link Copied", severity: "success" },
      // }));
      handleDuplicate(id);
      setContext((prev) => ({
        ...prev,
        open: !prev.open,
      }));
      setOrgSelect(true);
    } catch (err) {
      // console.log(err, "err");
      // navigate(`/survey/${id}`);
      // setCopySuccess("Failed to copy!");
      // setContext((prev) => ({
      //   ...prev,
      //   snackbar: {
      //     open: true,
      //     message: "Failed to Copy!",
      //     severity: "fail",
      //   },
      // }));
    }
  };
  const handleDuplicate = (id) => {
    setDuplicateId(id);
    // setOpen({ status: true, id: id });
  };
  useEffect(() => {
    getData();
    getThumbnails();
    getOrganization();
  }, []);
  const handleParams = (e) => {
    const { value, name } = e.target;
    setUrlParam((prev) => ({ ...prev, [name]: value }));
  };
  const searchString = searchValue.trim().toLowerCase();

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;
      finalFilters.q = searchString;
      if (urlParam?.org) {
        if (urlParam?.org == "All") {
          delete finalFilters.org;
        } else {
          const changeValue = org?.find((v) => v?.title === urlParam?.org);
          finalFilters.organisation = changeValue?.id;
        }
      }
      if (urlParam?.status) {
        if (urlParam?.status == "All") {
          delete finalFilters.status;
        } else {
          finalFilters.status = urlParam?.status;
        }
      }
      getData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [urlParam, pageState, searchValue]);

  const getOrganization = async () => {
    const getResponse = await getApi(`/profile/organisation/`);
    const { data } = getResponse;

    data.splice(0, 0, { id: 0, title: "All" });

    setOrg(data);
  };

  const getStatus = async (id) => {
    let data = { status: statusValue?.status };

    try {
      const getResponse = await PatchApi(`/survey/${id}/`, data);
      // console.log(getResponse?.data?.data, "dataresponse");
      getData();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (statusValue?.rowid) {
      getStatus(statusValue?.rowid);
    }
  }, [statusValue]);
  const getData = async (filter) => {
    setLoading(true);

    try {
      const data = await getApi(`/dashboard/admin/survey/`, filter);
      setPaginationData(data?.data);
      const modifyArray = data?.data?.data.map((item) => {
        return {
          ...item,
          CompanyName: (
            <div className={styles.imgCon}>
              {/* <div> */}
              {/* <p className={styles.img}> */}
              {/* <img
                    src={
                      item?.organisation?.logo
                        ? item?.organisation?.logo
                        : dummy
                    }
                  /> */}
              <div
                className={styles.img}
                style={{
                  background: `url(${
                    item?.organisation?.logo ? item?.organisation?.logo : dummy
                  })`,

                  width: "50px",
                  height: "50px",
                  borderRadius: "50px",
                }}
              ></div>
              {/* </p> */}
              {/* </div> */}
              <div>
                <p className={styles.imgdesc}>{item?.organisation?.title}</p>
                <p className={styles.desc}> {item?.organisation?.website}</p>
              </div>
            </div>
          ),
          surveyName: <p className={styles.imgdesc}>{item?.title}</p>,
          created_by: <p>{item?.organisation?.created_by}</p>,
          status: (
            <SelectStatus
              statusValue={item?.status}
              id={item?.id}
              setStatusValue={setStatusValue}
            />
          ),
          createdby: (
            <p>
              {item?.created_by?.first_name} {item?.created_by?.last_name}
            </p>
          ),
        };
      });
      setSurvey(data.data.data);
      setSurvey1(modifyArray);
      setLoading(false);
    } catch (error) {}
  };

  const getThumbnails = async () => {
    try {
      const data = await getApi("/survey/survey_thumbnail/");
    } catch (error) {}
  };
  const handleNavigate = (item) => {
    const {
      id,
      language,
      end_date,
      location,
      organisation,
      start_date,
      thumbnail,
      title,
    } = item;
    setContext((prev) => ({
      ...prev,
      survey: {
        ...prev.survey,
        id,
        language,
        end_date,
        location,
        organisation,
        start_date,
        thumbnail,
        title,
        thumbnail_id: id,
      },
      allSection: [
        {
          survey_id: id,
          survey_name: title,
          title: "Page",
          questions: [],
          index: 1,
        },
      ],
      currentSection: {
        survey_id: id,
        survey_name: title,
        title: "Page",
        questions: [],
        index: 1,
      },
    }));
    navigate(`/survey-management/${item.id}`);
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!imageUrl?.image) {
      formIsValid = false;
      errors["image"] = "Thumbnail is required";
    }
    if (!form?.location) {
      formIsValid = false;
      errors["location"] = "location is required";
    }
    setAddError(errors);
    return formIsValid;
  };
  useEffect(() => {
    if (image) {
      axios.interceptors.response.use(
        (response) => {
          // Modify the response data if needed
          const dycryptedData = dycryptAES(response.data);
          return dycryptedData; // By default, return data directly
        },
        (error) => {}
      );

      const formData = new FormData();
      formData.append("image", image);
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token.access}`,
        },
      };
      axios
        .post(`${baseURL}/survey/survey_thumbnail/`, formData, axiosConfig)
        .then((response) => {
          setImageUrl({ id: response.data.id, image: response.data.image });
          console.log(response, "setImageUrl");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [image]);
  console.log(imageUrl, "imageUrl");

  const handleChange = (name, data) => {
    setForm((prev) => ({
      ...prev,
      [name]:
        name === "start_date" || name === "end_date"
          ? stringToYYYYMMDD(data)
          : data,
    }));
  };
  const handleValuesCity = (e, value) => {
    console.log(value, "check");

    setForm((prevState) => ({
      ...prevState,
      location: value,
    }));
  };
  console.log(form, "form");

  const getDuplicate = async (id) => {
    // const SurveyName = duplicateName;
    try {
      const data = await PostApi(`survey/${id}/duplicate/`);
      setContext((prev) => ({
        ...prev,
        snackbar: {
          open: true,
          message: "Survey Duplicated Successfully",
          severity: "success",
        },
      }));
      getData();
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //   if (open?.status === false) {
  //     setDuplicateName({ survey_name: "" });
  //   }
  // }, [open?.status]);

  const handleDelete = (id) => {
    setdeleteData(true);
    setDeleteId(id);
  };
  const handlepopUpclose = () => {
    setdeleteData(false);
    setSelectedIds([]);
  };

  const getPdf = async () => {
    const ids = { survey_ids: selectDataId };

    try {
      const getResponse = await PostApi(`/dashboard/admin/survey_excel/`, ids);
      handleDownloadeExcel(getResponse?.data?.excel_url);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDownloadeExcel = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = "survey_report.xlsx";
    link.click(); // click function
  };
  const clearImage = () => {
    setSelectedImage(null);
  };

  const CreateSurvey = () => {
    return (
      <div>
        <div>
          <p style={{ marginTop: "0px" }}>
            <b>Survey Name</b>
          </p>
          <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Survey Name"
            onChange={(e) => handleChange("title", e.target.value)}
            name="title"
            value={form?.title}
          />
        </div>
        <div>
          <p>
            <b>Company Name</b>
          </p>
          {/* <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Search Company"
            value={"G10 consultancy"}
            disabled={true}
          /> */}
          <BasicSelect
            list={org}
            name="list"
            value="title"
            orgSelect={orgSelect}
            onChange={(value, name) => {
              setForm((prev) => ({ ...prev, organisation_id: value }));
            }}
          />
        </div>
        <div>
          <p>
            <b>Location</b>
          </p>
          <CustomSerachDropDown
            handleValues={handleValuesCity}
            helperText={addError?.location}
            error={!!addError.location}
            // label="Search Block"
            apiHitPoint="/profile/location/"
            variant="filled"
            // userId={userId}
            dataField={form}
            FieldName={"Location"}
          />
        </div>
        {/* <div>
          <p>
            <b>Location</b>
          </p>
          <TextField
            style={{ width: "100%" }}
            size="small"
            placeholder="Location"
            disabled={true}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: "1 1 0", marginRight: "10px" }}>
            <p>
              <b>Created by</b>
            </p>
            <TextField
              style={{ width: "100%" }}
              size="small"
              placeholder="Search Company"
              disabled={true}
              value={`${contextState?.user?.first_name} ${contextState?.user?.last_name}`}
            />
          </div>
          <div style={{ flex: "1 1 0" }}>
            <p>
              <b>Language</b>
            </p>
            <TextField
              style={{ width: "100%" }}
              size="small"
              placeholder="Search Company"
              disabled={true}
              value="English"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div style={{ width: "80%" }}>
            <p>
              <b>Survey Creation</b>
            </p>
            <DateField disabled={true} />
          </div>
          <div style={{ width: "80%" }}>
            <p>
              <b>Start Date</b>
            </p>
            <DateField
              onChange={(date) => handleChange("start_date", date)}
              name="start_date"
              startDate={form.start_date}
            />
          </div>
          <div style={{ width: "80%", marginLeft: "auto" }}>
            <p>
              <b>Close Date</b>
            </p>
            <DateField
              onChange={(date) => handleChange("end_date", date)}
              name="end_date"
              startDate={form.end_date}
            />
          </div>
        </div>
        <div>
          <p>
            <b>Select Survey Thumbnail</b>
          </p>
          <div style={{ width: "200px", width: "200px" }}>
            {/* <img src={addImage} onClick={() => setView(2)} /> */}
            <UploadImage
              getImage={setImage}
              img={imageUrl?.image}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
            {addError && <p style={{ color: "red" }}>{addError?.image}</p>}
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
            disabled={apires}
          >
            {apires
              ? "Processing..."
              : duplicateId
              ? "Duplicate Survey"
              : "Add Survey"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setContext((prev) => ({
                ...prev,
                open: !prev.open,
              }));
              setDuplicateId(null);
              setForm({
                title: "",
                language: 1,
                location_id: 1,
                organisation_id: 1,
                start_date: formattedDate,
                thumbnail_id: imageUrl,
                end_date: formattedDate,
              });
              setAddError({});
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`/survey/${id}/`);
      getData();
    } catch (error) {
      console.error(error);
    }
  };
  const ThumbNailListings = () => {
    return (
      <div>
        <div className={styles.create}>
          <div className={styles.headcreate}>
            <h3 className={styles.headingcreate}>Select Survey Thumbnail</h3>
          </div>
          <div className={styles.search}>
            <input type="text" placeholder="Search Survey"></input>
            <div>{/* <img src={search}></img> */}</div>
          </div>
          <div className={styles.imgInfo}>
            {Create?.map((item) => {
              return (
                <>
                  <div className={styles.createImg}>
                    <img src={item.img}></img>
                    <p>{item.desc}</p>
                  </div>
                </>
              );
            })}
          </div>
          <div className={styles.buttons}>
            <button>Add Survey</button>
            <button>Close</button>
          </div>
        </div>
      </div>
    );
  };
  const handleSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
    // const data = { ...form, thumbnail_id: imageUrl.id, };
    const data = {
      title: form?.title,
      language: 1,
      location_new_id: form?.location,
      organisation_id: form?.organisation_id,
      start_date: formattedDate,
      thumbnail_id: imageUrl.id,
      end_date: formattedDate,
    };
    console.log(data, "datacheck");
    setApiRes(true);
    try {
      if (duplicateId) {
        const postResponse = await PostApi(
          `survey/${duplicateId}/duplicate/`,
          data
        );
        setApiRes(false);
        setContext((prev) => ({
          ...prev,
          snackbar: {
            open: true,
            message: "Survey Duplicated Successfully",
            severity: "success",
          },
        }));
        setContext((prev) => ({
          ...prev,
          open: !prev.open,
        }));
        setAddError({});

        getData();
      } else {
        const postResponse = await PostApi(`/survey/`, data);

        getData();
        initiateSection(postResponse.data.id, postResponse.data.title);
        setApiRes(false);
        setContext((prev) => ({
          ...prev,
          open: !prev.open,
        }));
        setAddError({});
      }
    } catch (error) {
      console.log(error, "helo data form");
    }
    setForm({
      title: "",
      language: 1,
      location: null,
      organisation_id: 1,
      start_date: formattedDate,
      thumbnail_id: imageUrl,
      end_date: formattedDate,
    });
    setImageUrl({ id: null, image: "" });
    setImage();
    setOrgSelect(false);
    clearImage();
    setDuplicateId(null);
  };

  const initiateSection = async (id, title) => {
    const data = {
      survey_id: id,
      survey_name: "",
      title: "",
      questions: [],
      index: 1,
    };
    if (!data?.survey_id) {
      setContext((prev) => ({
        ...prev,
        snackbar: {
          open: true,
          message: "unable to create survey ",
          severity: "warning",
        },
      }));
    }
    try {
      const responseData = await PostApi(`/survey/section/`, data);
    } catch (error) {}
  };
  const exportNatigate = (id) => {
    navigate(`/publish-summary/${id}`);
  };
  const handleSUeveyNav = (id) => {
    navigate(`/survey/${id}`);
  };

  // const filteredItems = data.filter((item) =>
  //   item.title.toLowerCase().includes(searchString)
  // );

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>Survey Management</h2>
        {selectDataId?.length > 0 && (
          <div>
            <DownloadIconButton onClick={getPdf}>Download</DownloadIconButton>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // width: "30%",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <CustomTextField
            options={status}
            value={urlParam.status || "All"}
            onChange={handleParams}
            name="status"
            style={{ width: "150px" }}
          />

          <CustomTextField
            onChange={handleParams}
            options={org}
            value={urlParam.org}
            name="org"
            style={{ width: "200px" }}
          />
          <div>
            <AddButton
              style={{ marginBottom: "15px" }}
              variant="contained"
              disabled={false}
              onClick={() => {
                setContext((prev) => ({
                  ...prev,
                  open: !prev.open,
                }));
                setOrgSelect(true);
              }}
            >
              Build New Survey
            </AddButton>
          </div>
        </div>
      </div>

      <CustomTable
        tableHeads={tableHeads}
        tableBody={survey1}
        surveyIcons={true}
        headerCheck={true}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setSelectDatatId={setSelectDatatId}
        handleEdit={handleNavigate}
        handleView={exportNatigate}
        handleCopy={copyToClipboard}
        handleDelete={handleDelete}
        handleSUeveyNav={handleSUeveyNav}
        loading={loading}
        max_pages={max_pages}
        setPageState={setPageState}
      />

      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          // handleBulkDelete={handleBulkDelete}
          // selectDataId={selectDataId}
        />
      )}
      {/* <div
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        {survey.map((item, index) => {
          const { id, title } = item;
          return (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                padding: "10px",
                cursor: "pointer",
                background: `${index % 2 === 0 ? "#F9FAFB" : "white"}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <p style={{ margin: "10px 0px" }}>{title}</p>
              <div style={{ display: "flex", gap: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #4DD0E1",
                    padding: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={() => setOpen({ status: true, id: item?.id })}
                >
                  <img src={duplicate} />
                  <p style={{ margin: "0px", color: "#4DD0E1" }}>Duplicate</p>
                </div>
                <div>
                  <img
                    src={copy}
                    onClick={() => copyToClipboard(id)}
                    style={{ marginRight: "10px" }}
                  />
                  <img
                    src={exports}
                    onClick={() => navigate(`/publish-summary/${id}`)}
                    style={{ marginRight: "10px" }}
                  />
                  <img
                    src={edit}
                    style={{ marginRight: "20px" }}
                    onClick={() => handleNavigate(item)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
      {/* <ConformationPopUp
        bgColor={"#4DD0E1"}
        image={confirmDup}
        open={open?.status}
        confirmText={
          "Are you sure you want to Duplicate this Survey?. If yes then provide name for Survey"
        }
        handleClose={() => setOpen({ status: false })}
        getDuplicate={getDuplicate}
        id={open?.id}
        snack={true}
        setDuplicateName={setDuplicateName}
        duplicateName={duplicateName}
      /> */}

      {/* <SurveyManage /> */}
      <AlertDialogSlide
        header={duplicateId ? "Duplicate Survey" : "Build New Survey"}
        setDuplicateId={setDuplicateId}
        setAddError={setAddError}
      >
        {view === 1 && CreateSurvey()}
        {/* {view === 2 && ThumbNailListings()} */}
      </AlertDialogSlide>
    </div>
  );
}

export default SurveyManagement;

const Create = [
  {
    id: 1,
    img: Thumbnail1,
    name: "Thumbnail 1",
    desc: "Diagnostic_Baseline_Infosys_17.6.2024",
  },
  {
    id: 2,
    img: Thumbnail2,
    name: "Thumbnail 2",
    desc: "Diagnostic_Endline_Infosys_30.9.2023",
  },
  {
    id: 3,
    img: Thumbnail3,
    name: "Thumbnail 3",
    desc: "Diagnostic_Baseline_Infosys_14.1.2023",
  },
];
const tableHeads = [
  { id: 1, label: "Survey Name", key: "surveyName" },
  {
    id: 2,
    label: "Company Name",
    key: "CompanyName",
  },
  { id: 4, label: "Created by", key: "createdby" },

  { id: 3, label: "Status", key: "status" },
];
