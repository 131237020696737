import React from "react";
import UploadImage from "../../../../Components/UploadImage/UploadImage";
import useInput from "../../../../hooks/useInput";
import { TEXTField } from "../utility";

function ImageType({ data, ...props }) {
  const field = useInput("", null, {
    ...TEXTField,
    placeholder: data?.label,
  });
  return (
    <>
      <p style={{ marginBottom: "5px", marginTop: "0px" }}>
        {data?.label}
        {data?.is_mandatory && "*"}
      </p>
      <UploadImage />
    </>
  );
}

export default ImageType;
