import React, { useEffect, useState } from "react";
import DrawerTable from "../DrawerTable/DrawerTable";
import styles from "./OrgManagementTable.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteApi, getApi, PatchApi } from "../../Utility/network";
import SelectStatus from "../SelectStatus/SelectStatus";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function OrgManagementTable() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState();
  const [tableId, setTableId] = useState();
  const [innerData, setInnerData] = useState();
  const [statusValue, setStatusValue] = useState({ rowid: "", status: "" });
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [score, setScore] = useState();
  const [selectDataId, setSelectDatatId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (statusValue?.rowid !== null) {
      getStatus(statusValue?.rowid);
    }
  }, [statusValue]);
  const getStatus = async (id) => {
    let data = { status: statusValue?.status };

    try {
      const getResponse = await PatchApi(`/survey/${id}/`, data);
      // console.log(getResponse?.data?.data, "dataresponse");
      getTableData(id);
    } catch (error) {
      console.error(error);
    }
  };
  const getTableData = async (id) => {
    try {
      const getResponse = await getApi(
        `dashboard/admin/organisation/${id}/survey_list`
      );

      const modifyArray = getResponse?.data?.map((item) => {
        return {
          ...item,
          title: <p className={styles.head}>{item?.title}</p>,
          respondents: <p>{item?.total_invited_respondents}</p>,
          completion: <p>{item?.completion_rate}%</p>,
          open: <p>{item?.open_rate}%</p>,
          turnover: <p>{item?.turnover_intention}%</p>,

          statusDrop: (
            <SelectStatus
              setStatusValue={setStatusValue}
              statusValue={item?.status}
              id={item?.id}
            />
          ),
        };
      });
      setTableData(modifyArray);
      // setOrg(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getTableData(id);
    }
  }, []);
  const getId = (id) => {
    setTableId(id);
  };
  const getInnerData = async (id) => {
    try {
      const getResponse = await getApi(`survey/dashboard/scores/${id}`);
      setInnerData(getResponse?.data);
      // setTableData(getResponse?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getSoreData = async (id) => {
    try {
      const getResponse = await getApi(
        `/survey/dashboard/top_level_scores/${id}`
      );
      setScore(getResponse?.data);
      // setTableData(getResponse?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tableId) {
      getInnerData(tableId);
      getSoreData(tableId);
    }
  }, [tableId]);
  const scoreData = [
    {
      id: 1,
      color: "#F67D7D",
      value: score?.recognition_score,
      name: "Recognition Score",
      size: "100px",
    },
    {
      id: 2,
      color: "#F67D7D",
      value: score?.belongingness_score,
      name: "Belongingness Score ",
      size: "100px",
    },
    {
      id: 3,
      color: "#F67D7D",
      value: score?.allyship_score,
      name: " Allyship Score",
      size: "100px",
    },
  ];
  const chartData = [
    {
      id: 1,
      color: "#F67D7D",
      value: innerData?.gender_inclusion_score?.score,
      name: "Gender Inclusion Score",
      size: "100px",
    },
    {
      id: 2,
      color: "#FBD26A",
      value: innerData?.career_developement_score?.score,
      name: " Career Development Score ",
      size: "100px",
    },
    {
      id: 3,
      color: "#92F9C9",
      value: innerData?.safety_score?.score,
      name: " Safety Score ",
      size: "100px",
    },
    {
      id: 4,
      color: "#FBD26A",
      value: innerData?.flexibility_score?.score,
      name: " Flexibility Score ",
      size: "100px",
    },
    {
      id: 5,
      color: "#F67D7D",
      value: innerData?.productivity_score?.score,
      name: "Productivity Score",
      size: "100px",
    },
    {
      id: 6,
      color: "#FBD26A",
      value: innerData?.health_and_wellness_score?.score,
      name: " Health & Wellness ",
      size: "100px",
    },
    {
      id: 7,
      color: "#92F9C9",
      value: innerData?.ambition_score?.score,
      name: " Ambition Score ",
      size: "100px",
    },
    {
      id: 8,
      color: "#FBD26A",
      value: innerData?.financial_independence_score?.score,
      name: " Financial Independence Score  ",
      size: "100px",
    },
  ];
  const handleSurveyShare = (id) => {
    navigate(`/survey/${id}`);
  };
  const handleShare = (id) => {
    navigate(`/publish-summary/${id}`);
  };
  const handleDelete = (data) => {
    setdeleteData(true);
    setDeleteId(data);
    // getDelete(id);
  };
  const handlepopUpclose = () => {
    setdeleteData(false);
    setSelectedIds([]);
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`/survey/${id}/`);
      getTableData(id);
    } catch (error) {
      console.error(error);
    }
  };
  const handleNavigate = () => {
    navigate("/organization-management");
  };
  return (
    <div>
      <div className={styles.top}>
        <div>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
          />
        </div>
        <h2 className={styles.head1}>Organization Management</h2>
      </div>
      <div>
        <DrawerTable
          tableHeads={tableHeads2}
          tableBodys={tableData}
          ThreeActions={true}
          innerTable={true}
          getId={getId}
          handleDelete={handleDelete}
          // chartData={data}
          innerData={chartData}
          scoreData={scoreData}
          onHandleView={handleSurveyShare}
          handleShare={handleShare}
        />
      </div>
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteId={deleteId}
          getDelete={getDelete}
          handlepopUpclose={handlepopUpclose}
          // handleBulkDelete={handleBulkDelete}
          selectDataId={selectDataId}
        />
      )}
    </div>
  );
}

export default OrgManagementTable;
const tableHeads2 = [
  { id: 1, label: "Survey Name", key: "title" },
  { id: 2, label: "Total Respondents", key: "respondents" },
  { id: 3, label: "Completion Rate", key: "completion" },
  { id: 4, label: "Open Rate", key: "open" },
  { id: 4, label: "Turnover Rate", key: "turnover" },
  { id: 4, label: "Status", key: "statusDrop" },
];

// const tableBody2 = [
//   {
//     id: 1,
//     SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
//     TotalRespondents: 541,
//     CompletionRate: "60%",
//     OpenRate: "40%",
//     TurnoverRate: "80%",
//     Status: "Ongoing",
//     allData: [
//       {
//         id: 1,
//         chartName: "Scorecard",
//         chartData: [
//           {
//             id: 1,
//             color: "#F67D7D",
//             value: 29,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 2,
//             color: "#FBD26A",
//             value: 55,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 3,
//             color: "#92F9C9",
//             value: 88,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 4,
//             color: "#FBD26A",
//             value: 55,
//             name: "Recognition Score",
//             size: "100px",
//           },
//         ],
//       },
//       {
//         id: 2,
//         chartName: "Organizational Culture & Environment",
//         chartData: [
//           {
//             id: 1,
//             color: "#F67D7D",
//             value: 29,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 2,
//             color: "#FBD26A",
//             value: 55,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 3,
//             color: "#92F9C9",
//             value: 88,
//             name: "Recognition Score",
//             size: "100px",
//           },
//         ],
//       },
//       {
//         id: 3,
//         chartName: "Organizational Culture & Environment",
//         chartData: [
//           {
//             id: 1,
//             color: "#F67D7D",
//             value: 29,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 2,
//             color: "#FBD26A",
//             value: 55,
//             name: "Recognition Score",
//             size: "100px",
//           },
//           {
//             id: 3,
//             color: "#92F9C9",
//             value: 88,
//             name: "Recognition Score",
//             size: "100px",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 1,
//     SurveyName: <p className={styles.head}>Dorem ipsum dolor sit amet</p>,
//     TotalRespondents: 541,
//     CompletionRate: "60%",
//     OpenRate: "40%",
//     TurnoverRate: "80%",
//     Status: "Ongoing",
//   },
// ];
