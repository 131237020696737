import React from "react";
import useInput from "../../../../hooks/useInput";
import { TEXTField } from "../utility";
import ControlledCheckbox from "../../../../Components/ControlledCheckBox/ControlledCheckBox";
import styles from "../CreateSurvey.module.css";
import { WHITE } from "../../../../Utility/constants";

function MultiChoiceType({ data, ...props }) {
  const placeholder = useInput(data?.placeholder, null, {
    ...TEXTField,
    placeholder: data?.placeholder,
  });
  const instructions = useInput(data?.help_text, null, {
    ...TEXTField,
    placeholder: "Sub heading",
  });
  return (
    <>
      <p style={{ marginBottom: "5px", marginTop: "0px" }}>
        {data?.placeholder}
        {data?.is_mandatory && "*"}
      </p>
      <p style={{ marginBottom: "5px", marginTop: "0px", color: "grey" }}>
        {" "}
        {data.help_text}
      </p>
      <div className={styles.controlledCon}>
        {data.choices.map((item, index) => {
          return (
            <div
              className={styles.controlledIncon}
              style={{ background: WHITE }}
              key={index}
            >
              <ControlledCheckbox />
              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MultiChoiceType;
