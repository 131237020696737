import React, { useEffect, useState } from "react";
import { DeleteApi, getApi, PostApi } from "../../Utility/network";
import { useNavigate, useParams } from "react-router-dom";
import SummaryCard from "../../Components/SummaryView/SummaryCard/SummaryCard";
import styles from "./SurveySummary.module.css";
import { Button } from "@mui/material";
import PublishSurveyHelpus from "../../Components/PublishSurveyHelpus/PublishSurveyHelpus";
import Summary from "./Summary/Summary";
import Subbmission from "../../Components/Subbmission/Subbmission";
import trashblack from "../../assets/componentAssest/trashblack.svg";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReleasedData from "../../Components/ReleasedData/ReleasedData";

function SurveySummary(props) {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState({});
  const [details, setDetails] = useState({});
  const [count, setCount] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const { pageable, page, previous_page, pageNumber, max_pages, pageSize } =
    paginationData;
  const [pageState, setPageState] = useState(1);
  const [loading, setLoading] = useState(true);
  const [deletedata, setdeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDatatId, setSelectedDatatId] = useState([]);

  const [openRelease, setOpenRelease] = useState(false);
  const [realeaseIds, setRealeaseIds] = useState(null);
  const [released, setReleased] = useState(false);
  const [preview, setPreview] = useState(true);


  const getData = async (filter) => {
    // setLoading(true);
    try {
      const responseData = await getApi(
        `/survey/${surveyId}/submissions/`,
        filter
      );
      setPaginationData(responseData?.data);
      const responseSummary = await getApi(`/survey/${surveyId}/summary/`);
      setData(responseSummary.data);
      setOtherData(responseData?.data?.data);
      setLoading(false);
      setCount(responseData?.data?.total_count);

      // console.log(responseData, responseSummary, "responseData");
    } catch (error) {}
  };
  const getDetails = async () => {
    const responseData = await getApi(`survey/${surveyId}/basic_details`);
    setReleased(responseData?.data?.released);
    setDetails(responseData?.data);
  };
  useEffect(() => {
    getDetails();
    // getData();
  }, []);
  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      finalFilters.page = pageState;

      // getTableData(finalFilters);
      // console.log(finalFilters, "filters");
      getData(finalFilters);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [pageState, released]);
  const handlepopUpclose = () => {
    setdeleteData(false);
  };
  const handlepopUpcloseReleased = () => {
    setOpenRelease(false);
  };

  const handleSingleDelete = (id) => {
    setDeleteId(id);
    setdeleteData(true);
  };
  const handleNavigate = () => {
    navigate("/survey-management");
  };
  const getDelete = async (id) => {
    try {
      const getResponse = await DeleteApi(`/survey/response/${id}/`);
      getData();
    } catch (error) {
      console.error(error);
    }
  };
  const handleBulkDelete = async () => {
    const dataArray = {
      response_ids: selectedDatatId,
    };

    try {
      const getResponse = await PostApi(
        `/survey/response/bulk_delete/`,
        dataArray
      );
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const getDeleteReleased = (id) => {
    try {
      const getResponse = PostApi(`/survey/${id}/release/`);
      setTimeout(() => {
        getDetails();
        getData();
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className={styles.top}>
        <div>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
          />
        </div>
        <h2 className={styles.head}> Survey Management</h2>
      </div>

      <SummaryCard
        details={details}
        count={count}
        respondent={details?.total_invited_respondents}
        surveyId={surveyId}
        getDetails={getDetails}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ margin: "20px 0px" }}>
          <Button
            onClick={() => setType(1)}
            variant={`${type === 1 ? "contained" : "outlined"}`}
            style={{ marginRight: "15px" }}
          >
            Preview
          </Button>
          <Button
            onClick={() => setType(2)}
            variant={`${type === 2 ? "contained" : "outlined"}`}
            style={{ marginRight: "15px" }}
          >
            Summary
          </Button>
          <Button
            onClick={() => setType(3)}
            variant={`${type === 3 ? "contained" : "outlined"}`}
            style={{ marginRight: "15px" }}
          >
            Submissions
          </Button>
        </div>
        {type === 3 && (
          <div
            style={{
              margin: "20px 0px",
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!released && (
              <Button
                startIcon={<img src={trashblack} />}
                onClick={() => setdeleteData(true)}
                variant={`${type === 3 ? "contained" : "outlined"}`}
                style={{ marginRight: "15px" }}
              >
                Delete
              </Button>
            )}

            {released === true ? (
              <div className={styles.end}>
                <Button
                  // onClick={() => setOpenRelease(true)}
                  // className={released ? styles.green : ""}
                  // onClick={() => setType(3)}
                  variant={"contained"}
                  style={{ marginRight: "15px", alignItems: "flex-end" }}
                  className={styles.green}
                >
                  Survey Released
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => setOpenRelease(true)}
                // onClick={() => setType(3)}
                variant={"contained"}
                style={{ marginRight: "15px" }}
              >
                Release Data
              </Button>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        {type === 1 && <PublishSurveyHelpus preview={preview} />}
        {type === 2 && <Summary data={data} loading={loading} />}
        {type === 3 && (
          <Subbmission
            data={otherData}
            setPageState={setPageState}
            max_pages={max_pages}
            loading={loading}
            handleSingleDelete={handleSingleDelete}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setSelectedDatatId={setSelectedDatatId}
            released={released}
          />
        )}
      </div>
      {deletedata && (
        <ConfirmDelete
          open={deletedata}
          deleteSol={deleteId}
          delete1={getDelete}
          handlepopUpclose={handlepopUpclose}
          handleBulkDelete={handleBulkDelete}
          selectDataId={selectedDatatId}
        />
      )}
      {openRelease && (
        <ReleasedData
          open={openRelease}
          deleteId={surveyId}
          getDelete={getDeleteReleased}
          handlepopUpclose={handlepopUpcloseReleased}
          // handleBulkDelete={handleBulkDelete}
          // selectDataId={selectDataId}
        />
      )}
    </div>
  );
}

export default SurveySummary;
