import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import styles from "./CustomTable.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect, useState } from "react";
import yes from "../../assets/componentAssest/yes.png";
import no from "../../assets/componentAssest/no.png";
import ConformationPopUp from "../ConformationPopUp/ConformationPopUp";
import trash from "../../assets/componentAssest/trash.svg";
import copy from "../../assets/componentAssest/copy1.svg";
import send from "../../assets/componentAssest/send.svg";

import edit from "../../assets/componentAssest/edit.svg";

import share from "../../assets/componentAssest/share.svg";
import { styled } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import add from "../../assets/componentAssest/add.png";
import editCat from "../../assets/componentAssest/editCat.svg";
import addPOC from "../../assets/componentAssest/addPOC.svg";
import catlog from "../../assets/componentAssest/catlog.png";
import CatlogHeader from "../CatlogHeader/CatlogHeader";
import CustomPagination from "../CustomPagination/CustomPagination";

const StyledTableRow = styled(TableRow)(({ theme, dashBoard }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: dashBoard ? "none" : "#F9FAFB",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomTable = ({
  soluctionInnner = false,
  tableHeads,
  setSelectedIds,
  selectedIds,
  tableBody,
  check = false,
  action,
  tableVisible = true,
  heading,
  ApproveReject = false,
  headerCheck = false,
  ThreeActions = false,
  FourActions = false,
  surveyIcons = false,
  catlogData = false,
  dashBoard = false,
  dropDown,
  cat,
  handleOpen = false,
  handleEdit = () => {},
  handleOpenReject = () => {},
  videoData = () => {},
  HandleOpen = () => {},
  handleView = () => {},
  handleDelete = () => {},
  addOPen = () => {},
  formatDate = () => {},
  handleReject = () => {},
  setSelectDatatId = () => {},
  handleChange = () => {},
  handleCopy = () => {},
  handleSUeveyNav = () => {},
  loading,
  // setSelectedDrop,
  selectedDrop,
  max_pages,
  setPageState,
  // handleApprove=()=>{},
  handleNavigation,
  ...otherProps
}) => {
  const [tableData, setTableData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedId, setSelectedId] = useState([]);
  useEffect(() => {
    setTableData(tableBody);
  }, [tableBody]);
  const handleSelectAll = () => {
    const visibleIds = tableData.map((row) => row.id);
    if (allSelected) {
      const newSelectedIds = selectedIds.filter(
        (id) => !visibleIds?.includes(id)
      );
      setSelectedIds(newSelectedIds);
      setSelectDatatId(newSelectedIds);
    } else {
      const newSelectedIds = [
        ...selectedIds,
        ...visibleIds.filter((id) => !selectedIds.includes(id)),
      ];
      setSelectedIds(newSelectedIds);
      setSelectDatatId(newSelectedIds);
    }
    setAllSelected(!allSelected);
  };

  const handleSelect = (row) => {
    setSelectedIds((prevSelected) => {
      const newSelected = [...prevSelected];
      const selectedIndex = newSelected.indexOf(row.id);

      if (selectedIndex === -1) {
        newSelected.push(row.id);
      } else {
        newSelected.splice(selectedIndex, 1);
      }

      setSelectDatatId(newSelected);
      return newSelected;
    });
  };
  useEffect(() => {
    const visibleIds = tableData?.map((row) => row.id);
    const allRowsSelected = visibleIds?.every((id) =>
      selectedIds?.includes(id)
    );
    setAllSelected(allRowsSelected);
  }, [tableData, selectedIds]);
  const renderCellContent = (column, displayData, row) => {
    if (column?.key == "solutionType") {
      return <p>{row?.solution_type?.title || ""}</p>;
    }
    if (column?.key == "SoluctionProvider") {
      return <p>{row?.provider?.title || ""}</p>;
    }
    if (column?.key == "Name") {
      return (
        <div>
          <p className={styles.desc}>name</p>

          <p className={styles.head}>{row?.name}</p>
        </div>
      );
    }
    if (column?.key == "email") {
      return (
        <div>
          <p className={styles.desc}>Email</p>
          <p className={styles.head}>{row?.email}</p>
        </div>
      );
    }
    if (column?.key == "created") {
      return (
        <div>
          <p className={styles.desc}>Date</p>
          <p className={styles.head}>{formatDate(row?.created)}</p>
        </div>
      );
    }

    return displayData;
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: columnKey, direction });
    const sortedData = [...tableData].sort((a, b) => {
      const aValue = extractTextContent(a[columnKey]);
      const bValue = extractTextContent(b[columnKey]);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setTableData(sortedData);
  };

  const extractTextContent = (element) => {
    if (typeof element === "string") return element.toLowerCase();
    if (typeof element === "number") return element;
    if (typeof element === "object" && element.props) {
      if (typeof element.props.children === "string") {
        return element.props.children.toLowerCase();
      } else if (Array.isArray(element.props.children)) {
        return element.props.children
          .map((child) =>
            typeof child === "string" ? child.toLowerCase() : ""
          )
          .join("");
      } else if (typeof element.props.children === "object") {
        return element.props.children.title.toLowerCase();
      }
    }
    return "";
  };

  useEffect(() => {
    if (sortConfig.key) {
      handleSort(sortConfig.key);
    }
  }, [tableBody, sortConfig.key]);

  return (
    <TableContainer component={Paper} {...otherProps}>
      {heading && (
        <div className={styles.headCon}>
          <div className={styles.left}>
            <h3 className={styles.head}> {heading.headName}</h3>
            {heading.secPara && (
              <p className={styles.desc}>{heading.secPara}</p>
            )}
          </div>
          {heading?.url ? (
            <div
              onClick={() => {
                if (heading?.url) {
                  handleNavigation(heading.url);
                }
              }}
              style={{ cursor: "pointer" }}
              className={heading.status === 1 ? styles.filter1 : styles.filter}
            >
              <p>{heading.filterName} </p>
              <div>{heading.icon && <heading.icon />}</div>
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className={heading.status === 1 ? styles.filter1 : styles.filter}
            >
              <Select
                size="small"
                style={{ width: "100%" }}
                onChange={handleChange}
                name="selectedDays"
                value={selectedDrop?.selectedDays || 4}
              >
                {dropDown?.days?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      )}
      {catlogData && (
        <div>
          <CatlogHeader data={cat} />
          <div className={styles.headCon}>
            <div className={styles.left}>
              <h3 className={styles.head}>Point of Contact (POC)</h3>
            </div>
            <div className={styles.right}>
              <div className={styles.iconCon} onClick={() => addOPen(true)}>
                <div className={styles.icons}>
                  <img src={addPOC} />
                </div>
                <p className={styles.iconName}>Add POC</p>
              </div>
              <div className={styles.iconCon} onClick={() => HandleOpen(true)}>
                <div className={styles.icons}>
                  <img src={editCat} />
                </div>
                <p className={styles.iconName}>Update</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headerCheck && (
              <TableCell>
                <Checkbox
                  checked={allSelected}
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < tableData.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            {tableHeads?.map((column, index) => {
              const isSorted = sortConfig.key === column.key;
              return (
                tableVisible && (
                  <TableCell
                    key={index}
                    style={{ cursor: "pointer", minWidth: column.width }}
                  >
                    <div
                      className={styles.tableFontHead}
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                      onClick={
                        column.showIcon ? () => handleSort(column.key) : null
                      }
                    >
                      {column.label}{" "}
                      {column.showIcon &&
                        (isSorted && sortConfig.direction === "asc" ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        ))}
                    </div>
                  </TableCell>
                )
              );
            })}

            {(ApproveReject ||
              ThreeActions ||
              FourActions ||
              surveyIcons ||
              soluctionInnner) && (
              <TableCell>
                <div className={styles.tableFontHead}>Quick Actions</div>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex} dashBoard={dashBoard}>
              {headerCheck && (
                <TableCell>
                  <Checkbox
                    checked={selectedIds.includes(row.id)} // Check by row.id, not row
                    onClick={() => handleSelect(row)}
                  />
                </TableCell>
              )}
              {tableHeads.map((column, index) => {
                const displayData = row[column.key];
                return (
                  <>
                    {loading ? (
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.key}
                        className={styles.dataStyle}
                        onClick={() => column?.edit && handleView(row?.id, row)}
                        style={{
                          minWidth: column.width,
                          cursor: column?.edit ? "pointer" : "default",
                        }}
                      >
                        <div className={styles.tableFontData}>
                          {renderCellContent(column, displayData, row)}
                        </div>
                      </TableCell>
                    )}
                  </>
                );
              })}
              {action && (
                <TableCell>
                  <MoreHorizIcon color="action" />
                </TableCell>
              )}
              {ApproveReject && (
                <TableCell>
                  {row?.approval_status == 0 && (
                    <div className={styles.ApproveReject}>
                      <div
                        className={styles.approve}
                        onClick={() => handleOpen(1, row?.id)}
                      >
                        <div>
                          <img src={yes} />
                        </div>
                        <span>Approve</span>
                      </div>
                      <div
                        className={styles.reject}
                        onClick={() => handleOpenReject(2, row?.id)}
                      >
                        <div>
                          <img src={no} />
                        </div>
                        <span>Reject</span>
                      </div>
                    </div>
                  )}
                  {row?.approval_status == 1 && (
                    <div className={styles.ApproveReject}>
                      <div className={styles.approve}>
                        <div>
                          <img src={yes} />
                        </div>
                        <span>Approved</span>
                      </div>
                    </div>
                  )}
                  {row?.approval_status == 2 && (
                    <div className={styles.ApproveReject}>
                      <div className={styles.reject}>
                        <div>
                          <img src={no} />
                        </div>
                        <span>Rejected</span>
                      </div>
                    </div>
                  )}
                </TableCell>
              )}
              {ThreeActions && (
                <TableCell>
                  <div className={styles.ThreeActions}>
                    <div
                      className={styles.actionsCon}
                      onClick={() => videoData(row)}
                    >
                      <img src={share} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleEdit(row)}
                    >
                      <img src={edit} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleDelete(row?.id)}
                    >
                      <img src={trash} />
                    </div>
                  </div>
                </TableCell>
              )}
              {FourActions && (
                <TableCell>
                  <div className={styles.ThreeActions}>
                    {row?.approval_status == 0 && (
                      <div className={styles?.flex}>
                        <div
                          className={styles.approve}
                          onClick={() => handleOpen(1, row?.id)}
                        >
                          <img src={yes} />
                        </div>
                        <div
                          className={styles.reject}
                          onClick={() => handleOpenReject(2, row?.id)}
                        >
                          <img src={no} />
                        </div>
                      </div>
                    )}
                    {row?.approval_status == 1 && (
                      <div className={styles.approve}>
                        <img src={yes} />
                      </div>
                    )}
                    {row?.approval_status == 2 && (
                      <div className={styles.reject}>
                        <img src={no} />
                      </div>
                    )}
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleEdit(row)}
                    >
                      <img src={edit} />
                    </div>
                    <div className={styles.actionsCon}>
                      <img src={trash} onClick={() => handleDelete(row?.id)} />
                    </div>
                  </div>
                </TableCell>
              )}

              {surveyIcons && (
                <TableCell>
                  <div className={styles.ThreeActions}>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleSUeveyNav(row?.id)}
                    >
                      <img src={share} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleCopy(row?.id)}
                    >
                      <img src={copy} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleDelete(row?.id)}
                    >
                      <img src={trash} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleView(row?.id)}
                    >
                      <img src={send} />
                    </div>
                    <div
                      className={styles.actionsCon}
                      onClick={() => handleEdit(row)}
                    >
                      <img src={edit} />
                    </div>
                  </div>
                </TableCell>
              )}
              {soluctionInnner && (
                <TableCell>
                  <div
                    className={styles.actionsCon}
                    onClick={() => videoData(row)}
                  >
                    <img src={share} />
                  </div>
                </TableCell>
              )}

              {catlogData && (
                <TableCell>
                  <div className={styles.actionsConDelete}>
                    <img src={trash} onClick={() => handleDelete(row?.id)} />
                  </div>
                </TableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {dashBoard ? null : (
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      )}
    </TableContainer>
  );
};

export default CustomTable;
