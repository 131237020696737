import React from "react";
import AddCatlog from "../../../Components/AddCatlog/AddCatlog";
import Breadcrumbs from "../../../Components/BreadCrumbs/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../OrgManagement.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function OrgManagementDetails(props) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/organization-management");
  };
  return (
    <div>
      {/* <Breadcrumbs /> */}
      <div className={styles.top}>
        <div>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
          />
        </div>
        <h2 className={styles.head1}>Organization Management</h2>
      </div>
      <div style={{ marginTop: "20px" }}>
        <AddCatlog />
      </div>
    </div>
  );
}

export default OrgManagementDetails;
