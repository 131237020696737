import React, { useEffect, useState } from "react";
import styles from "./Subbmission.module.css";
import CustomTable from "../CustomTable/CustomTable";
import { fireEvent } from "@testing-library/react";
import SubbmisionTable from "../SubbmisionTable/SubbmisionTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import { CircularProgress } from "@mui/material";
function Subbmission({
  data,
  setPageState,
  max_pages,
  loading,
  selectedIds,
  setSelectedIds,
  setSelectedDatatId,
  released,
  handleSingleDelete = () => {},
}) {
  const [tHead, setTHead] = useState([]);
  const [firstItem, setFirstItem] = useState();
  const [body, setBody] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const item = data[0]; // Access the 0th index
      setFirstItem(item);
    } else {
      console.log("Data is not an array or it's empty.");
    }
  }, [data]);

  useEffect(() => {
    if (firstItem) {
      const modifyArray = firstItem.question_responses.map((item) => {
        return {
          label: item?.question?.placeholder || "",
        };
      });
      setTHead(modifyArray);
    }
  }, [firstItem]);
  useEffect(() => {
    if (data && data.length > 0) {
      const modifyArray = data.map((survey) => {
        return {
          response_date: survey.response_date || "",
          userId: survey?.id,
          survey_id: survey?.survey_id,
          answers: survey.question_responses.map((item) => {
            let answerValue;
            switch (item?.question?.question_type) {
              case 2:
              case 3:
              case 7:
              case 8:
                answerValue = item.answer_text || "";
                break;
              case 4:
              case 5:
              case 9:
                answerValue = item?.answer_choice?.title || "";
                break;
              case 6:
                answerValue = item?.answer_choices
                  ? item.answer_choices.map((choice) => choice.title).join(", ")
                  : "";
                break;
              default:
                answerValue = "";
            }

            return {
              question_type: item.question.question_type || "",
              answer_text: answerValue || "",
            };
          }),
        };
      });

      setBody(modifyArray);
    }
  }, [data]);

  useEffect(() => {
    if (body && body.length > 0) {
      const modifyArray = body.map((survey) => {
        return {
          response_date: survey?.response_date,
          userId: survey?.userId,
          survey_id: survey?.survey_id,

          answers_text: survey.answers.map((item) => {
            return item?.answer_text;
          }),
        };
      });
      setTableBody(modifyArray);
    }
  }, [body]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      {data?.length > 0 && loading == false ? (
        <div>
          <SubbmisionTable
            tableHeads={tHead}
            tableBody={tableBody}
            FormatDate={formatDate}
            headerCheck={released === true ? false : true}
            loading={loading}
            truncateText={truncateText}
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
            setSelectDatatId={setSelectedDatatId}
            handleSingleDelete={handleSingleDelete}
            released={released}
          />
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      ) : data?.length == 0 ? (
        <h1 style={{ width: "100%", textAlign: "center" }}>No Data Found</h1>
      ) : (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default Subbmission;
const tableHeads = [
  {
    id: 1,
    label: "Submitted at",
    key: "response_date",
  },
  {
    id: 2,
    label: "User ID",
    key: "id",
  },
  {
    id: 3,
    label: "I have understood informed consent above and wish to proceed",
    key: "understood",
  },
  {
    id: 4,
    label: "Which gender identity do you most identify with?",
    key: "identity",
  },
  {
    id: 5,
    label: "Educational Qualification (your highest degree)",
    key: "educatiom",
  },
  {
    id: 6,
    label: "Field of Study (Undergraduate)",
    key: "undergraduate",
  },
  {
    id: 7,
    label: "Field of Study (Postgraduate)",
    key: "postGraduate",
  },
  {
    id: 8,
    label: "What is your current role?",
    key: "role",
  },
  {
    id: 9,
    label: "Monthly household Income",
    key: "income",
  },
  {
    id: 10,
    label: "Current Marital Status",
    key: "status",
  },
];
