import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./AddOrgSoluction.module.css";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import addEmptyImg from "../../assets/componentAssest/addEmptyImg.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import createOrg from "../../assets/componentAssest/createOrg.png";
import axios from "axios";

import deleteIcon from "../../assets/componentAssest/delete.png";
import edit from "../../assets/componentAssest/edit.png";
import { getApi, PatchApi, PostApi } from "../../Utility/network";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import DateField from "../DateField/DateField";
function AddOrgSoluction({
  handleClickClose,
  open,
  update,
  _id,
  soluctionId,
  getOrgData = () => {},
}) {

  const { token } = useContext(AppContext);

  const fieldStyle = { width: "100%" };

  const [dataField, setDataField] = useState({
    start_date: "",
    end_date: "",
    status: "",
  });
  const [dropDown, setDropDown] = useState({
    status: [
      { id: 1, title: "Active" },
      { id: 2, title: "Inactive" },
      { id: 3, title: "Paused" },
      { id: 4, title: "Withdrawn" },
    ],
  });
  const UpdateSoluction = async (id) => {
    const data = {
      start_date: dataField?.start_date,
      end_date: dataField?.end_date,
      status: dataField?.status,
    };
    try {
      const getResponse = await PatchApi(
        `/survey/recommended_solutions/assigned/${id}/`,
        data
      );

      // setUpdate(getResponse?.data);
      clearState();
      handleClickClose();
      setTimeout(() => {
        getOrgData(_id);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (name, data) => {
    setDataField((prev) => ({
      ...prev,
      [name]:
        name === "start_date" || name === "end_date"
          ? stringToYYYYMMDD(data)
          : data,
    }));
  };
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
  };
  const stringToYYYYMMDD = (dateString) => {
    const startDate = new Date(dateString);
    const formattedDate = startDate.toISOString().slice(0, 10);
    return formattedDate;
  };
  const clearState = () => {
    setDataField({
      start_date: "",
      end_date: "",
      status: "",
    });
  };

  //   useEffect(() => {
  //     if (soluctionId) {
  //       getUpData(soluctionId);
  //     }
  //   }, [soluctionId]);

  useEffect(() => {
    if (update) {
      const statusMapping = {
        Active: 1,
        Inactive: 2,
        Paused: 3,
        Withdrawn: 4,
      };
      setDataField({
        start_date: update?.start_date,
        end_date: update?.end_date,
        status: statusMapping[update?.status_str] || null,
      });
    }
  }, [update]);

  return (
    <Dialog
      onClose={handleClickClose}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={styles.con}>
        <div className={styles.head}>
          <h3 className={styles.heading}>Update Solution</h3>
          <CloseIcon onClick={handleClickClose} />
        </div>

        <div className={styles.fieldbox}>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Start Date</p>
            <DateField
              onChange={(date) => handleChange("start_date", date)}
              name="start_date"
              startDate={dataField.start_date}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> End Date</p>
            <DateField
              onChange={(date) => handleChange("end_date", date)}
              name="end_date"
              startDate={dataField.end_date}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Status</p>
            <Select
              value={dataField?.status}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="status"
              // error={!!addError.industry}
            >
              {dropDown?.status?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            variant="contained"
            onClick={() => {
              UpdateSoluction(soluctionId);
            }}
          >
            Update Solution
          </Button>
          <Button variant="outlined" onClick={handleClickClose}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddOrgSoluction;
