import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./AddSolution.module.css";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import addEmptyImg from "../../assets/componentAssest/addEmptyImg.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import createOrg from "../../assets/componentAssest/createOrg.png";
import axios from "axios";

import deleteIcon from "../../assets/componentAssest/delete.png";
import edit from "../../assets/componentAssest/edit.png";
import { baseURL, getApi } from "../../Utility/network";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
function AddSolution({
  handleClickClose,
  open,
  getProfileDataprev = () => {},
  getImage = () => {},
  img = "",
  // update,
  soluctionId,
  getOrgData = () => {},
  _id,
}) {
  const { token } = useContext(AppContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadeImgFile, setUploadImgFile] = useState("");
  const [image, setImage] = useState("");
  const [addError, setAddError] = useState({});

  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState({ id: null, image: "" });
  const fieldStyle = { width: "100%" };
  const [dropCatagory, setDropCatagory] = useState();
  const [dropProvider, setDropProvider] = useState();
  const [update, setUpdate] = useState();
  const [dropDown, setDropDown] = useState({
    format: [
      { id: 1, title: "Online" },
      { id: 2, title: "Offline" },
    ],
  });

  const [dataField, setDataField] = useState({
    name: "",
    category: "",
    format: "",
    link: "",
    location: "",
    provider: "",
    desc: "",
  });

  const getUpData = async (id) => {
    try {
      const getResponse = await getApi(`survey/recommended_solutions/${id}`);

      setUpdate(getResponse?.data);
      // setOrgData(getResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      getImage(event.target.files[0]);
      setUploadImgFile(event.target.files[0]);
    }
    setAddError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };
  const handleClick = () => {
    fileInputRef.current.click();
    setImage("");
  };
  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setDataField({
      ...dataField,
      [name]: value,
    });
    setAddError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleValidation = async () => {
    let errors = {};
    let formIsValid = true;

    const linkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

    if (!dataField?.link) {
      formIsValid = false;
      errors["link"] = "URL is required";
    } else if (!linkRegex.test(dataField?.link)) {
      formIsValid = false;
      errors["link"] = "Enter a valid URL";
    }

    const fileType = /\.?(jpg|jpeg|png|webp)$/i;

    if (!uploadeImgFile) {
      formIsValid = false;
      errors["image"] = "soluction logo is required";
    }
    //  else if (!(uploadeImgFile instanceof File)) {
    //   formIsValid = false;
    //   errors["image"] = "Uploaded file is not valid";
    // }
    else if (
      !fileType.test(
        soluctionId
          ? typeof uploadeImgFile === "object" && uploadeImgFile.name
            ? uploadeImgFile.name
            : uploadeImgFile
          : typeof uploadeImgFile === "object" && uploadeImgFile.name
          ? uploadeImgFile.name
          : uploadeImgFile
      )
    ) {
      formIsValid = false;
      errors["image"] = "Enter a valid image format (jpg, jpeg, png, webp)";
    } else if (uploadeImgFile.size > 2 * 1024 * 1024) {
      formIsValid = false;
      errors["image"] = "Image max size should be 2MB";
    }
    const isDimensionValid = await validateImageDimensions(
      soluctionId
        ? typeof uploadeImgFile === "object"
          ? uploadeImgFile
          : ""
        : uploadeImgFile,
      formIsValid,
      errors
    );
    if (!isDimensionValid) {
      formIsValid = false;
      errors["image"] = "Image must be 500px by 500px";
    }

    setAddError(errors);
    return formIsValid;
  };

  const validateImageDimensions = (file) => {
    if (!file) {
      return Promise.resolve(true); // No file, skip dimension validation
    }

    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
        img.onload = function () {
          if (!(img.width <= 500 && img.height <= 500)) {
            resolve(false); // Invalid dimensions
          } else {
            resolve(true); // Valid dimensions
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const CreateSoluction = async () => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }

    const formData = new FormData();
    formData.append("thumbnail", uploadeImgFile);
    formData.append("title", dataField?.name);
    formData.append("format", dataField?.format);
    formData.append("location", dataField?.location);
    formData.append("video_url", dataField?.link);
    formData.append("provider_id", dataField?.provider);
    formData.append("solution_type_id", dataField?.category);
    formData.append("description", dataField?.desc);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .post(`${baseURL}/survey/recommended_solutions/`, formData, axiosConfig)
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          setImageUrl({ id: response.data.id, image: response.data.image });
          getProfileDataprev();
          clearState();
          handleClickClose();
          getOrgData(_id);
        } else if (response.status == 400) {
          // console.log(response?.data?.error, "error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const clearState = () => {
    setDataField({
      name: "",
      category: "",
      format: "",
      link: "",
      location: "",
      provider: "",
      desc: "",
    });
  };

  const UpdateSoluction = async () => {
    const isValid = await handleValidation();
    if (!isValid) {
      return; // Exit if validation fails
    }

    const formData = new FormData();

    formData.append("title", dataField?.name);
    formData.append("format", dataField?.format);
    formData.append("location", dataField?.location);
    formData.append("video_url", dataField?.link);
    formData.append("provider_id", dataField?.provider);
    formData.append("solution_type_id", dataField?.category);
    formData.append("description", dataField?.desc);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .patch(
        `${baseURL}/survey/recommended_solutions/${soluctionId}/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          setImageUrl({ id: response.data.id, image: response.data.image });
          getProfileDataprev();
          clearState();
          handleClickClose();
          getOrgData(_id);
        } else if (response.status == 400) {
          // console.log(response?.data?.error, "error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getImageFile = async () => {
    const formData = new FormData();

    formData.append("thumbnail", uploadeImgFile);

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.access}`,
      },
    };
    axios
      .patch(
        `${baseURL}/survey/recommended_solutions/${soluctionId}/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
        } else if (response.status == 400) {
          // console.log(response?.data?.error, "error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedImage) {
      getImageFile();
    }
  }, [selectedImage]);
  const getSoluctionData = async () => {
    try {
      const getResponse = await getApi(`survey/solution_type/`);
      setDropCatagory(getResponse.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const getProvidedData = async () => {
    try {
      const getResponse = await getApi(`survey/solution_provider/`);
      setDropProvider(getResponse.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    getSoluctionData();
    getProvidedData();
    // getUpData();
  }, []);
  useEffect(() => {
    if (soluctionId) {
      getUpData(soluctionId);
    }
  }, [soluctionId]);

  useEffect(() => {
    if (update) {
      setDataField({
        name: update?.title,
        category: update?.solution_type?.id,
        format: update?.format,
        link: update?.video_url,
        location: update?.location,
        provider: update?.provider?.id,
        desc: update?.description,
      });
      setUploadImgFile(update?.thumbnail);
    }
  }, [update]);

  return (
    <Dialog
      onClose={handleClickClose}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={styles.con}>
        <div className={styles.head}>
          <h3 className={styles.heading}>
            {soluctionId ? "Update Solution" : "Add New Solution"}
          </h3>
          <CloseIcon onClick={handleClickClose} />
        </div>

        <div className={styles.profile}>
          <div className={styles.img}>
            {soluctionId ? (
              soluctionId && selectedImage ? (
                <img src={selectedImage} />
              ) : selectedImage == null && uploadeImgFile == null ? (
                <img src={createOrg} />
              ) : (
                <img src={uploadeImgFile} />
              )
            ) : uploadeImgFile ? (
              <img src={selectedImage} onClick={handleClick} />
            ) : (
              <img src={createOrg} onClick={handleClick} />
            )}
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(e) => handleImageChange(e, "userFile")}
            />
          </div>

          <div className={styles.profileInfo}>
            {soluctionId && (
              <div className={styles.editIcon}>
                <div className={styles.edit}>
                  <img
                    src={edit}
                    onClick={() => {
                      handleClick();
                    }}
                  />
                </div>

                {/* <div className={styles.delete}>
                  <img src={deleteIcon} />
                </div> */}
              </div>
            )}
            <p className={styles.info}>
              Image Size should be max - 2 mb
              <br />
              Image Dimensions - 500px by 500px
              <br />
              File Type - Jpg, Png, WebP
            </p>
            {addError.image && (
              <p style={{ color: "red", fontSize: "12px" }}>{addError.image}</p>
            )}
            {/* {selectedImage && (
              <p style={{ color: "green", fontSize: "12px" }}>
                Upload image successfully
              </p>
            )} */}
          </div>
        </div>

        <div className={styles.fieldbox}>
          <div className={styles.otherField}>
            <p className={styles.name}> Solution Name</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="name"
              value={dataField?.name}
            />
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}> Solution Category</p>
            <Select
              value={dataField?.category}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="category"
              // error={!!addError.industry}
            >
              {dropCatagory?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Format</p>
            <Select
              value={dataField?.format}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="format"
              // error={!!addError.industry}
            >
              {dropDown?.format?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={styles.otherField}>
            <p className={styles.name}> YouTube Link </p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              value={dataField?.link}
              name="link"
              error={!!addError.link}
              helperText={addError.link}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <ContentPasteIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className={styles.otherField}>
            <p className={styles.name}> Location</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="location"
              value={dataField?.location}
            />
          </div>
          <div className={styles.otherField}>
            <p className={styles.name}>Solution Provider</p>
            <Select
              value={dataField?.provider}
              onChange={handleChangeINput}
              sx={{ width: "100%" }}
              size="small"
              name="provider"
              // error={!!addError.industry}
            >
              {dropProvider?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.otherField}>
            <p className={styles.name}> Description</p>
            <TextField
              size="small"
              style={fieldStyle}
              onChange={handleChangeINput}
              name="desc"
              value={dataField?.desc}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            variant="contained"
            onClick={() => {
              soluctionId ? UpdateSoluction() : CreateSoluction();
            }}
          >
            {soluctionId ? "Update Solution" : "Add New Solution"}
          </Button>
          <Button variant="outlined" onClick={handleClickClose}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddSolution;
