import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  header = "",
  children,
  setDuplicateId,
  setAddError,
  ...props
}) {
  const { contextState, setContext } = React.useContext(AppContext);
  const { open } = contextState;
  const handleClose = () => {
    setContext((prev) => ({ ...prev, open: !prev.open }));
    setDuplicateId(null);
    setAddError({});
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md" // Change the maxWidth to "lg" or any other desired value
      >
        <DialogTitle>
          <b>{header}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
